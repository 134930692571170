import { SVG_GOTO_BLACK_YELLOW, SVG_GOTO_WHITE_YELLOW } from '@getgo/chameleon-icons';
import { html, ref } from '@microsoft/fast-element';
import { ChameleonRebrandingObserver } from '../Observers';
import { SvgComponent } from '../Svg';
export const gotoLogoTemplate = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    return html `
  <${svgTag} class="rebranding-logo-container" ${ref('rebrandingLogoContainer')}></${svgTag}>
  <div class="old-logo-container">
    <slot></slot>
  </div>`;
};
export class GotoLogoComponent extends ChameleonRebrandingObserver {
    constructor() {
        super(...arguments);
        this.handleOsThemeChange = () => {
            this.updateIconBasedOnTheme();
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.updateVisibility();
        this.observer.observe(this.getThemeProvider(), { attributes: true });
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.handleOsThemeChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.observer.disconnect();
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.handleOsThemeChange);
    }
    rebrandingObserverHandler() {
        this.updateVisibility();
    }
    updateVisibility() {
        const themeProvider = this.getThemeProvider();
        if (!themeProvider) {
            console.error(`[@getgo/chameleon-web] - Couldn't find chameleon-theme-provider in the DOM for ${this}`);
            return;
        }
        if (themeProvider.hasAttribute('rebranding2021')) {
            this.updateIconBasedOnTheme();
            this.classList.add('rebranding');
        }
        else {
            this.classList.remove('rebranding');
        }
    }
    isDarkMode() {
        const themeProvider = this.getThemeProvider();
        const automaticAttr = themeProvider.getAttribute('automatic');
        const isAutomatic = automaticAttr === 'true' || automaticAttr === '';
        return isAutomatic
            ? window.matchMedia('(prefers-color-scheme: dark)').matches
            : themeProvider.getAttribute('theme') === 'dark';
    }
    isHighContrast() {
        const themeProvider = this.getThemeProvider();
        return themeProvider.getAttribute('theme') === 'high-contrast';
    }
    updateIconBasedOnTheme() {
        if (this.isDarkMode() || this.isHighContrast()) {
            this.rebrandingLogoContainer.innerHTML = SVG_GOTO_WHITE_YELLOW;
        }
        else {
            this.rebrandingLogoContainer.innerHTML = SVG_GOTO_BLACK_YELLOW;
        }
    }
}
