import { __decorate } from "tslib";
import { attr, html, ref, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { SVG_PERSON_OUTLINED } from '@getgo/chameleon-icons';
export const avatarTemplate = html `<template
  style="${(x) => x.primaryBackgroundColor ?? ''}"
  tabindex="${(x) => (x.isActiveButton() ? '0' : null)}"
>
  <slot ${ref('content')} @slotchange="${(x) => x.handleContentChange()}"></slot>
  ${when((x) => x.canHostABadge(), html `<slot name="badge"></slot>`)}
  ${when((x) => x.size !== 'xxxlarge', html `<slot name="presence"></slot>`)}
</template>`;
export class AvatarComponent extends ChameleonPrefixedElementMixin(FoundationElement, 'avatar') {
    constructor() {
        super(...arguments);
        this.variant = 'neutral';
        this.disabled = false;
        this.hidden = false;
    }
    handleContentChange() {
        const nodes = this.content.assignedNodes().reduce((acc, el) => {
            if (el.nodeType === Node.TEXT_NODE && el.textContent?.trim().length === 0) {
                return acc;
            }
            acc.push(el);
            return acc;
        }, []);
        if (nodes.length > 1) {
            this.removeInactiveIcon();
        }
        if (nodes.length === 0) {
            this.renderInactiveIcon();
        }
        this.verifyImageChild();
    }
    variantChanged(_oldValue, newValue) {
        if (newValue === 'primary') {
            this.primaryBackgroundColor = '--goto-avatar-bg-color: var(--goto-avatar-color-00)';
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.render();
        this.renderInactiveIcon();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        this.render();
    }
    canHostABadge() {
        const allowedSizes = ['large', 'xlarge', 'xxlarge'];
        return allowedSizes.find((size) => this.size === size);
    }
    isActiveButton() {
        return this.type === 'button' && !this.disabled;
    }
    hasChildImage() {
        return !!this.querySelector('img');
    }
    render() {
        const slot = this.shadowRoot?.querySelector('slot');
        if (!slot) {
            return;
        }
        this.renderButtonOverlay();
    }
    verifyImageChild() {
        this.classList.toggle('with-image', this.hasChildImage());
    }
    renderInactiveIcon() {
        const svgTag = this.tagFor('svg');
        const svg = document.createElement(svgTag);
        svg.classList.add('inactive-icon');
        svg.innerHTML = SVG_PERSON_OUTLINED;
        this.appendChild(svg);
    }
    removeInactiveIcon() {
        this.querySelector('.inactive-icon')?.remove();
    }
    isTypeButton() {
        return this.type === 'button';
    }
    isButtonOvelayRendered() {
        return this.querySelector('.button-overlay');
    }
    renderButtonOverlay() {
        if (this.isTypeButton() && !this.isButtonOvelayRendered()) {
            const overlay = document.createElement('span');
            overlay.classList.add('button-overlay');
            this.appendChild(overlay);
        }
    }
}
__decorate([
    attr
], AvatarComponent.prototype, "size", void 0);
__decorate([
    attr
], AvatarComponent.prototype, "variant", void 0);
__decorate([
    attr
], AvatarComponent.prototype, "type", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AvatarComponent.prototype, "disabled", void 0);
__decorate([
    attr
], AvatarComponent.prototype, "primaryBackgroundColor", void 0);
