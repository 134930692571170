import { html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { TypographyComponent } from '../../Typography';
export const navigationDrawerHeaderTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    return html `<template tabindex="-1">
    <${typographyTag} tag="h1" variant="heading-small"><slot></slot></${typographyTag}>
  </template>`;
};
export class NavigationDrawerHeaderComponent extends ChameleonElementMixin(FoundationElement) {
}
