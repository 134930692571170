import { __decorate } from "tslib";
import { SVG_LINK_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, observable, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { TooltipV3Component } from '../TooltipV3';
import { TypographyComponent } from '../Typography';
export const linkThumbnailTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    const tooltipV3Tag = context.tagFor(TooltipV3Component);
    return html `<a
  href="${(x) => x.link}"
  target="${(x) => x.target}"
  class="container"
>
  <div class="preview">
    <slot ${slotted('imageSlot')} name="image"></slot>
  </div>
  <div class="description">
    <${typographyTag}
      id="trigger"
      class="name"
      variant="body-large-strong"
      color="type-color-default"
      nowrap="{true}"
    >
      <slot ${slotted('titleSlot')} name="name"></slot>
    </${typographyTag}>
    <${tooltipV3Tag} position="top-center" trigger-id="trigger" hidden="${(x) => x.hideTooltip}">
      <${typographyTag} variant="caption-medium">${(x) => x.text}</${typographyTag}>
    </${tooltipV3Tag}>
    <${typographyTag} class="link-container" variant="caption-medium" color="type-color-secondary" nowrap="{true}">
      <div class="link">${(x) => x.hostname}</div>
    </${typographyTag}>
  </div>
</a>`;
};
export class LinkThumbnailComponent extends ChameleonPrefixedElementMixin(FoundationElement, 'link-thumbnail') {
    constructor() {
        super(...arguments);
        this.link = '';
        this.target = '_blank';
        this.text = '';
        this.hideTooltip = true;
        this.hostname = '';
        this.icon = document.createElement(this.tagFor('svg'));
    }
    connectedCallback() {
        super.connectedCallback();
        this.setNoImageState();
    }
    imageSlotChanged(_, newValue) {
        if (newValue) {
            this.shadowRoot?.querySelector('.link-icon')?.remove();
        }
        else {
            this.setNoImageState();
        }
    }
    linkChanged() {
        if (this.link) {
            try {
                this.hostname = new URL(this.link).hostname;
            }
            catch (e) {
                console.warn('The link provided is wrong');
            }
        }
    }
    titleSlotChanged() {
        if (this.titleSlot && this.titleSlot.length > 0) {
            const titleNodes = this.titleSlot[0];
            if (titleNodes && titleNodes instanceof Element && titleNodes.textContent) {
                this.text = titleNodes.textContent;
                if (this.text && titleNodes.scrollWidth > titleNodes.clientWidth) {
                    this.hideTooltip = false;
                }
                else {
                    this.hideTooltip = true;
                }
            }
        }
    }
    setNoImageState() {
        this.icon.innerHTML = SVG_LINK_OUTLINED;
        this.icon.setAttribute('class', 'link-icon');
        this.shadowRoot?.querySelector('.preview')?.appendChild(this.icon);
    }
}
__decorate([
    attr()
], LinkThumbnailComponent.prototype, "link", void 0);
__decorate([
    attr()
], LinkThumbnailComponent.prototype, "target", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "text", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "hideTooltip", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "imageSlot", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "titleSlot", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "hostname", void 0);
