import { withStylePrefix } from '../common/withStylePrefix';
import { DateInputComponent, dateInputTemplate } from './DateInput';
import styles from './DateInput.styles.scss';
export const chameleonDateInput = DateInputComponent.compose({
    baseName: 'date-input',
    template: dateInputTemplate,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
});
