import config from 'config';
import fetchWithToken from './provider/fetch';

const getRenewalEndPoint = (accountKey: string): string =>
  `${config.COG_URL}/rest/v1/pendingRenewals/account/${accountKey}`;

export const daoProcessRenewalPost = (accountKey: string, payload: object): Promise<object> =>
  fetchWithToken({
    reqUrl: `${getRenewalEndPoint(accountKey)}/pay`,
    method: 'POST',
    body: JSON.stringify(payload),
  });

export default {
  daoProcessRenewalPost,
};
