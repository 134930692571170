import { RangeCalendarComponent, rangeCalendarTemplate } from './RangeCalendar';
import { withStylePrefix } from '../common/withStylePrefix';
import styles from './RangeCalendar.styles.scss';
export const chameleonRangeCalendar = RangeCalendarComponent.compose({
    baseName: 'range-calendar',
    template: rangeCalendarTemplate,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
});
