import { __decorate } from "tslib";
import { attr, DOM, html, observable, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const progressStepperTemplate = html `<template>
  <div class="steps">
    <slot name="step" part="step" ${slotted('steps')}></slot>
  </div>
  <div class="steppanels" part="steppanels">
    <slot name="steppanel" part="panel" ${slotted('panels')}></slot>
  </div>
</template>`;
export class ProgressStepperComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.orientation = 'horizontal';
        this.type = 'non-editable';
        this.activeStep = 0;
        this.onStepChange = () => {
            this.$emit('step-change', null, { bubbles: false });
        };
        this.handleStepChange = () => {
            this.onStepChange();
            DOM.nextUpdate().then(() => this.updateSelectedPanel());
            this.updateStepsBasedOnType();
        };
        this.handleStepClick = (e) => {
            this.steps[this.activeStep].selected = false;
            this.activeStep = this.steps.findIndex((step) => step === e.target);
            this.steps[this.activeStep].selected = true;
            this.updateSelectedStep();
            this.updateSelectedPanel();
        };
    }
    activeStepChanged(oldValue, _) {
        if (this.steps && this.steps.length)
            this.steps[oldValue].selected = false;
        this.updateSelectedStep();
        this.updateSelectedPanel();
    }
    stepsChanged() {
        this.removeListenerOnStepChange();
        this.listenOnStepChange();
        this.listenOnStepClick();
        this.updateSelectedStep();
    }
    panelsChanged() {
        this.updateSelectedPanel();
    }
    connectedCallback() {
        super.connectedCallback();
        this.updateSelectedStep();
        this.updateSelectedPanel();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeListenerOnStepClick();
    }
    listenOnStepClick() {
        this.steps.forEach((step) => {
            step.addEventListener('step-clicked', this.handleStepClick);
        });
    }
    removeListenerOnStepClick() {
        this.steps.forEach((step) => {
            step.removeEventListener('step-clicked', this.handleStepClick);
        });
    }
    listenOnStepChange() {
        this.steps.forEach((step) => {
            step.addEventListener('change', this.handleStepChange);
        });
    }
    removeListenerOnStepChange() {
        this.steps.forEach((step) => step.removeEventListener('change', this.handleStepChange));
    }
    updateSelectedStep() {
        if (this.steps && this.steps.length) {
            this.steps.forEach((step, index) => {
                // There is an issue with the type here so we need to use parseInt to make it comparable
                if (Number.parseInt(this.activeStep.toString()) === Number.parseInt(index.toString())) {
                    step.selected = true;
                    step.editable = true;
                }
            });
            this.updateStepsBasedOnType();
        }
    }
    updateSelectedPanel() {
        if (this.panels && this.panels.length) {
            this.activeStep = this.getSelectedIndex();
            this.panels.forEach((panel, index) => {
                panel.hidden = this.activeStep !== index;
            });
        }
    }
    updateStepsBasedOnType() {
        this.activeStep = this.getSelectedIndex();
        this.steps.forEach((step, index) => {
            if (!step.visited) {
                step.visited = index <= this.activeStep;
            }
            step.editable =
                this.type === 'editable' && (step.visited || step.variant === 'success' || step.variant === 'danger');
            step.variant = step.visited && step.optional ? 'success' : step.variant;
        });
    }
    getSelectedIndex() {
        return this.steps.reduce((value, step, index) => {
            return step.selected ? index : value;
        }, 0);
    }
}
__decorate([
    attr
], ProgressStepperComponent.prototype, "orientation", void 0);
__decorate([
    attr
], ProgressStepperComponent.prototype, "type", void 0);
__decorate([
    attr({ attribute: 'active-step' })
], ProgressStepperComponent.prototype, "activeStep", void 0);
__decorate([
    observable
], ProgressStepperComponent.prototype, "steps", void 0);
__decorate([
    observable
], ProgressStepperComponent.prototype, "panels", void 0);
