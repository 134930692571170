import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonMenu } from './Menu.definition';
import { chameleonMenuItem } from './MenuItem/MenuItem.definition';
import { chameleonMenuSection } from './MenuSection/MenuSection.definition';
import { chameleonMenuSeparator } from './MenuSeparator/MenuSeparator.definition';
import { chameleonAnchorRegion } from '../AnchoredRegion';
import { chameleonTypography } from '../Typography/Typography.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonTypography({ prefix }), chameleonMenu({ prefix }), chameleonMenuItem({ prefix }), chameleonMenuSeparator({ prefix }), chameleonMenuSection({ prefix }), chameleonAnchorRegion({ prefix }));
}
