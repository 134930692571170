function isBoolean(value) {
    return value === 'true' || value === 'false';
}
export function BooleanAttributeChangedMixin(Base) {
    return class extends Base {
        attributeChangedCallback(propertyName, oldValue, newValue) {
            super.attributeChangedCallback(propertyName, oldValue, newValue);
            if (propertyName.startsWith('aria-')) {
                return;
            }
            if (isBoolean(newValue)) {
                if (newValue === 'true') {
                    this.setAttribute(propertyName, '');
                }
                else {
                    this.removeAttribute(propertyName);
                }
            }
        }
    };
}
export function ChameleonVersionMixin(version) {
    return (Base) => class extends Base {
        get chameleonVersion() {
            return version;
        }
    };
}
export function ChameleonPrefixedMixin(baseName) {
    return (Base) => class extends Base {
        constructor() {
            super(...arguments);
            this.baseName = baseName.toUpperCase();
        }
        tagFor(element) {
            return this.tagName.replace(this.baseName.toUpperCase(), element.toUpperCase());
        }
    };
}
