import { __decorate } from "tslib";
import { attr, DOM, Observable } from '@microsoft/fast-element';
import { Select, SelectPosition } from '@microsoft/fast-foundation';
import { SVG_CHEVRON_DOWN_OUTLINED, SVG_CHECKMARK_OUTLINED, SVG_ALERT_OUTLINED } from '@getgo/chameleon-icons';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
import { findChild, findParent } from '../common/utils/DomUtil';
const helperTextDefaultColor = 'type-color-secondary';
export class SelectComponent extends ChameleonPrefixedElementMixin(Select, 'select') {
    get displayValue() {
        Observable.track(this, 'displayValue');
        return this.firstSelectedOption?.label ?? '';
    }
    get positionIsBelow() {
        return !!this.position && this.position === SelectPosition.below;
    }
    get positionIsAbove() {
        return !!this.position && this.position === SelectPosition.above;
    }
    get isInlineOrFitContent() {
        return !!(this.inline || this.fitContent);
    }
    get controlBoundingRect() {
        return this.control.getBoundingClientRect();
    }
    resizeListboxWhenFullWidth() {
        if (this.fullwidth) {
            if (this.controlBoundingRect.width > 460) {
                // checking 460px because boundingRect excludes the padding
                this.listbox.style.width = 'auto';
                this.listbox.style.maxWidth = 'fit-content';
            }
            else {
                this.listbox.style.width = '100%';
                this.listbox.style.maxWidth = '100%';
            }
        }
    }
    resizeListboxWhenLabelAndHelperText() {
        const selectHeight = this.controlBoundingRect.height + (this.noBorder ? 0 : 2); // +2px because of the borders
        // Adjust listbox when there is a label on the left
        if (this.isInlineOrFitContent && this.label && this.labelWidth) {
            this.listbox.style.left = this.labelWidth + 4 + 'px';
        }
        // Adjust listbox when opens above and has helperText
        if (this.positionIsAbove && this.helperText && !this.isInlineOrFitContent) {
            this.listbox.style.bottom = `${selectHeight + 20}px`;
        }
        // Adjust listbox when opens below and has label
        if (this.positionIsBelow && this.labelHeight && !this.inDialog) {
            const extraHeight = this.isInlineOrFitContent && this.label ? 0 : this.labelHeight;
            this.listbox.style.top = `${selectHeight + extraHeight}px`;
        }
    }
    resizeListboxWhenNoScreenSpace() {
        const distanceRight = window.innerWidth - this.controlBoundingRect.left;
        const distanceToScreenBottom = window.innerHeight - this.controlBoundingRect.bottom;
        const distanceToScreenTop = this.controlBoundingRect.top;
        const listboxComputedMaxHeight = Number.parseInt(getComputedStyle(this.listbox).maxHeight);
        const listboxIsBiggerThenBottom = listboxComputedMaxHeight > distanceToScreenBottom;
        const listboxIsBiggerThenTop = listboxComputedMaxHeight > distanceToScreenTop;
        // Adjust listbox position based on the distance to the top and bottom
        if (this.positionIsBelow) {
            this.listbox.style.maxHeight = listboxIsBiggerThenBottom
                ? `${distanceToScreenBottom - 2}px`
                : `${listboxComputedMaxHeight}px`;
        }
        if (this.positionIsAbove) {
            this.listbox.style.top = listboxIsBiggerThenTop ? `2px` : 'unset';
            this.listbox.style.maxHeight = listboxIsBiggerThenTop
                ? `${distanceToScreenTop - 2}px`
                : `${listboxComputedMaxHeight}px`;
        }
        // Adjust listbox position based on the distance to the right
        if (distanceRight < 480 || (this.inline && distanceRight < 260)) {
            this.listbox.style.left = 'unset';
            this.listbox.style.right = '0px';
        }
    }
    resizeListboxWhenLongTextOptions() {
        // Set max-width only when there are long text options
        if (this.optionList) {
            for (const option of this.optionList) {
                const text = option.shadowRoot?.querySelector('.content');
                const controlBoundingRectWidth = this.controlBoundingRect.width;
                const hasLongTextOption = text && !this.inline && controlBoundingRectWidth && text.scrollWidth > controlBoundingRectWidth;
                if (hasLongTextOption) {
                    this.listbox.style.width = 'max-content';
                    if (this.fullwidth && controlBoundingRectWidth > 460) {
                        this.listbox.style.maxWidth = '100%';
                    }
                    else {
                        this.listbox.style.maxWidth = '480px';
                    }
                    break; // no need to check other text options
                }
            }
        }
    }
    inlineChanged() {
        if (this.inline) {
            if (this.hasAttribute('size')) {
                this.removeAttribute('size');
                if (DEV) {
                    console.warn('Chameleon Select: Size attribute for Inline variants is deprecated. Inline only has 1 size.');
                }
            }
            this.setStateIcon(false);
        }
        else if (this.error) {
            this.setStateIcon(true, SVG_ALERT_OUTLINED);
        }
        else if (this.success) {
            this.setStateIcon(true, SVG_CHECKMARK_OUTLINED);
        }
    }
    sizeChanged() {
        if (this.getAttribute('size') === 'xsmall') {
            this.setAttribute('size', 'small');
            if (DEV) {
                console.warn('Chameleon Select: The size `xsmall` is deprecated. It will be set to `small` instead.');
            }
        }
    }
    selectedValueChanged(_oldValue, newValue) {
        this.value = newValue;
    }
    labelChanged(_oldValue, newValue) {
        this.labelHtmlElement.textContent = newValue;
    }
    helperTextChanged(_oldValue, newValue) {
        this.helperTextHtmlElement.textContent = newValue;
    }
    successChanged(_oldValue, newValue) {
        if (newValue) {
            this.helperTextHtmlElement.setAttribute('color', 'text-success-01');
            if (!this.inline) {
                this.setStateIcon(true, SVG_CHECKMARK_OUTLINED);
            }
        }
        else {
            this.helperTextHtmlElement.removeAttribute('color');
            this.setStateIcon(false);
        }
    }
    errorChanged(_oldValue, newValue) {
        if (newValue) {
            this.helperTextHtmlElement.setAttribute('color', 'danger-01');
            if (!this.inline) {
                this.setStateIcon(true, SVG_ALERT_OUTLINED);
            }
        }
        else {
            this.helperTextHtmlElement.removeAttribute('color');
            this.setStateIcon(false);
        }
    }
    disabledChanged(_oldValue, newValue) {
        super.disabledChanged(_oldValue, newValue);
        if (newValue) {
            this.helperTextHtmlElement?.setAttribute('color', 'text-03');
            this.labelHtmlElement?.setAttribute('color', 'text-03');
        }
        else {
            this.helperTextHtmlElement?.setAttribute('color', helperTextDefaultColor);
            this.labelHtmlElement?.setAttribute('color', 'text-01');
        }
    }
    constructor() {
        super();
        this.selectedValue = '';
        this.fullwidth = false;
        this.stateIcon = document.createElement(this.tagFor('svg'));
        this.labelSize = new ResizeObserver((entries) => {
            const rect = entries[0].contentRect;
            this.labelWidth = rect.width;
            this.labelHeight = rect.height;
        });
        this.resizeListboxCallback = () => {
            this.resizeListboxWhenFullWidth();
            this.resizeListboxWhenLongTextOptions();
            this.resizeListboxWhenNoScreenSpace();
            this.resizeListboxWhenLabelAndHelperText();
            if (this.inDialog) {
                this.resizeListboxWhenInDialog();
            }
        };
        this.resizeListbox = new ResizeObserver(this.resizeListboxCallback);
        this.resizeListboxWhenInDialog = () => {
            if (this.control && this.listbox) {
                this.listbox.style.position = 'fixed';
                this.listbox.style.width = `${this.controlBoundingRect.width}px`;
                if (this.positionIsAbove) {
                    if (this.listbox.style.top) {
                        this.listbox.style.removeProperty('top');
                    }
                    this.listbox.style.bottom = `${window.innerHeight - this.controlBoundingRect.y}px`;
                    this.listbox.style.left = `${this.controlBoundingRect.x}px`;
                }
                else {
                    if (this.listbox.style.bottom) {
                        this.listbox.style.removeProperty('bottom');
                    }
                    this.listbox.style.top = `${this.controlBoundingRect.y + this.controlBoundingRect.height}px`;
                    this.listbox.style.left = `${this.controlBoundingRect.x}px`;
                }
            }
        };
        this.resizeListboxWhenDialogScrolls = () => {
            this.parentDialogContent = this.findParentDialogContent();
            if (this.parentDialogContent) {
                this.parentDialogContent?.addEventListener('scroll', this.resizeListboxWhenInDialog);
                window.addEventListener('resize', this.resizeListboxWhenInDialog);
                return () => {
                    this.resetListbox();
                    this.parentDialogContent?.removeEventListener('scroll', this.resizeListboxWhenInDialog);
                    window.removeEventListener('resize', this.resizeListboxWhenInDialog);
                };
            }
        };
        const typographyTag = this.tagFor('typography');
        this.labelHtmlElement = document.createElement(typographyTag);
        this.labelHtmlElement.setAttribute('variant', 'button-small');
        this.labelHtmlElement.classList.add('label');
        this.helperTextHtmlElement = document.createElement(typographyTag);
        this.helperTextHtmlElement.setAttribute('variant', 'caption-medium');
        this.helperTextHtmlElement.setAttribute('color', helperTextDefaultColor);
    }
    connectedCallback() {
        super.connectedCallback();
        this.stateIcon.setAttribute('class', 'state-icon');
        this.shadowRoot?.querySelector('[name="button-container"]')?.appendChild(this.stateIcon);
        if (!this.querySelector('[slot="indicator"]')) {
            this.createIndicator();
        }
        this.insertLabel();
        this.insertHelperText();
        this.labelSize.observe(this.labelHtmlElement);
        this.setInitialSelectedValue();
        // for the inline select, there is only 1 size
        if (this.inline) {
            if (this.hasAttribute('size')) {
                this.removeAttribute('size');
            }
            this.setStateIcon(false);
        }
        this.optionList = this.querySelectorAll(`chameleon-option, ${this.tagFor('option')}`);
        this.resizeListbox.observe(this.listbox);
        this.resizeListboxWhenDialogScrolls();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.labelHtmlElement.remove();
        this.helperTextHtmlElement.remove();
    }
    resetListbox() {
        this.listbox.style.removeProperty('position');
        this.listbox.style.removeProperty('width');
        this.listbox.style.removeProperty('top');
        this.listbox.style.removeProperty('left');
    }
    createIndicator() {
        const indicator = document.createElement(this.tagFor('svg'));
        indicator.setAttribute('slot', 'indicator');
        indicator.innerHTML = SVG_CHEVRON_DOWN_OUTLINED;
        this.appendChild(indicator);
    }
    setStateIcon(enabled, icon) {
        if (enabled && icon) {
            this.stateIcon.innerHTML = icon;
            if (this.isConnected) {
                this.shadowRoot?.querySelector('[name="button-container"]')?.appendChild(this.stateIcon);
            }
        }
        else {
            this.stateIcon.remove();
        }
    }
    insertLabel() {
        if (this.label) {
            this.shadowRoot?.prepend(this.labelHtmlElement);
        }
    }
    insertHelperText() {
        if (this.helperText) {
            this.shadowRoot?.append(this.helperTextHtmlElement);
        }
    }
    setInitialSelectedValue() {
        const selectedValue = this.getAttribute('selected-value');
        if (selectedValue !== null && selectedValue !== '') {
            // because fast wait a frame before querying options
            DOM.queueUpdate(() => {
                this.value = selectedValue;
            });
        }
        else {
            this.selectedValue = this.value;
        }
    }
    findParentDialogContent() {
        const dialog = findParent(this, (parent) => parent.tagName === 'CHAMELEON-DIALOG' || parent.tagName === this.tagFor('dialog'));
        const dialogContent = dialog?.shadowRoot && findChild(dialog?.shadowRoot, (child) => child.part.contains('body'));
        return dialogContent;
    }
}
__decorate([
    attr({ attribute: 'selected-value' })
], SelectComponent.prototype, "selectedValue", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "success", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "inline", void 0);
__decorate([
    attr({ attribute: 'size' })
], SelectComponent.prototype, "sizeAttribute", void 0);
__decorate([
    attr
], SelectComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'helper-text' })
], SelectComponent.prototype, "helperText", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ attribute: 'no-border', mode: 'boolean' })
], SelectComponent.prototype, "noBorder", void 0);
__decorate([
    attr({ attribute: 'in-dialog', mode: 'boolean' })
], SelectComponent.prototype, "inDialog", void 0);
__decorate([
    attr({ attribute: 'fit-content', mode: 'boolean' })
], SelectComponent.prototype, "fitContent", void 0);
