import { __decorate } from "tslib";
import { html, attr, slotted, observable } from '@microsoft/fast-element';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
export const cardTemplate = html `
  <template alignment=${(x) => x.alignment} id=${uniqueId('card-')} orientation=${(x) => x.orientation}>
    <slot name="media" ${slotted('media')}></slot>
    <div class="card-content">
      <slot></slot>
    </div>
  </template>
`;
export class CardComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.indicator = false;
        this.selected = false;
        this.orientation = 'vertical';
        this.alignment = 'left';
    }
    mediaChanged(_, newValue) {
        if (newValue.length > 0 && !this.classList.contains('with-media')) {
            this.classList.add('with-media');
        }
        else {
            this.classList.remove('with-media');
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], CardComponent.prototype, "indicator", void 0);
__decorate([
    attr({ mode: 'boolean' })
], CardComponent.prototype, "selected", void 0);
__decorate([
    attr
], CardComponent.prototype, "orientation", void 0);
__decorate([
    attr
], CardComponent.prototype, "alignment", void 0);
__decorate([
    observable
], CardComponent.prototype, "media", void 0);
