import { Switch, switchTemplate as template } from '@microsoft/fast-foundation';
import { SwitchComponent } from './Switch';
import styles from './Switch.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const switchDefinition = {
    baseName: 'switch',
    baseClass: Switch,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonSwitch = SwitchComponent.compose(switchDefinition);
