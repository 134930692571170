import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertV2, Button, Checkbox, Link, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppSelector } from 'hooks';
import { sessionBillingAddress } from 'modules/session-details';
import { DD_SEPA_DETAILS } from 'utils/constants';
import st from 'utils/shared-translations';

import './direct-debit-mandate.css';

interface DirectDebitMandateProps {
  handleCancelMandate: any;
  ibanNumber: string;
  isSubmitting: boolean;
  isDefault: boolean;
  setDefault: (isDefault: boolean) => void;
  isValid: boolean;
  dirty: boolean;
}

const DirectDebitMandate: FC<DirectDebitMandateProps> = ({
  handleCancelMandate,
  ibanNumber,
  isSubmitting,
  isDefault,
  setDefault,
  isValid,
  dirty,
}): JSX.Element => {
  const intl = useIntl();

  const ctaRef = useRef<HTMLDivElement>(null);

  const [isChecked, setChecked] = useState(false);

  const selectedBillingAddress = useAppSelector(sessionBillingAddress);

  useEffect(() => {
    setTimeout(() => ctaRef.current?.scrollIntoView({ behavior: 'smooth' }), 1000);
  }, []);

  return (
    <>
      <div className="direct-debit-mandate__info">
        <AlertV2 className="direct-debit-mandate__alert">
          <FormattedMessage
            {...st['dd.sepa.mandate.gocardless.alert']}
            values={{
              link: (msg: ReactNode) => (
                <Link href="https://gocardless.com/legal/privacy/" target="_blank">
                  {msg}
                </Link>
              ),
            }}
          />
        </AlertV2>

        <Typography variant="body-small-strong">{intl.formatMessage(st['dd.sepa.mandate.header'])}</Typography>
        <Typography variant="caption-small" color="type-color-secondary">
          {intl.formatMessage(st['dd.sepa.mandate.header.caption'])}
        </Typography>

        <div className="direct-debit-mandate__user-details">
          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.account.holder.name'])}
          </Typography>
          <Typography variant="body-small">{`${selectedBillingAddress?.firstName} ${selectedBillingAddress?.lastName}`}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.company.name'])}
          </Typography>
          <Typography variant="body-small">{`${selectedBillingAddress?.firstName} ${selectedBillingAddress?.lastName}`}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.iban.account.number'])}
          </Typography>
          <Typography variant="body-small">{ibanNumber}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.billing.address'])}
          </Typography>
          <Typography variant="body-small">
            {selectedBillingAddress.addressLine1},{' '}
            {selectedBillingAddress.addressLine2 ? `${selectedBillingAddress.addressLine2}, ` : ''}
            {selectedBillingAddress.city}, {selectedBillingAddress.state ? `${selectedBillingAddress.state}, ` : ''}
            {selectedBillingAddress.country} {selectedBillingAddress.postalCode}
          </Typography>
        </div>

        <div className="direct-debit-mandate__creditor-details">
          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.creditor.name'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.crditorName}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.creditor.identifier'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.creditorIdentifier}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.payment.type'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.paymentType}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.reference'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.mandateReference}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.submission.date'])}
          </Typography>
          <Typography variant="body-small">
            {intl.formatDate(new Date(), {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })}
          </Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.street.address'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.streetAddress}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.city'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.city}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.postal.code'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.postalCode}</Typography>

          <Typography variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['dd.sepa.mandate.country'])}
          </Typography>
          <Typography variant="body-small">{DD_SEPA_DETAILS.Country}</Typography>
        </div>
      </div>

      <Checkbox className="direct-debit-mandate__check-box" onChange={() => setChecked(!isChecked)}>
        {intl.formatMessage(st['dd.sepa.mandate.tnc'])}
      </Checkbox>

      <div className="direct-debit-mandate__cta" ref={ctaRef}>
        <Checkbox
          className="direct-debit-mandate__tnc-checkbox"
          disabled={isSubmitting}
          onClick={() => setDefault(!isDefault)}
          checked={isDefault}
        >
          {intl.formatMessage(st['payment.method.set.as.default'])}
        </Checkbox>
        <div className="direct-debit-mandate__cta-buttons">
          <Button
            className="direct-debit-mandate__cancel-cta"
            variant="tertiary"
            disabled={isSubmitting}
            onClick={() => handleCancelMandate(false)}
          >
            {intl.formatMessage(st['dd.mandate.cta.cancel'])}
          </Button>
          <Button
            disabled={!isChecked || !isValid || isSubmitting || !dirty}
            isLoading={isSubmitting}
            variant="primary"
            type="submit"
          >
            {intl.formatMessage(st['dd.mandate.cta.save'])}
          </Button>
        </div>
      </div>
    </>
  );
};

export default DirectDebitMandate;
