import { __decorate } from "tslib";
import { html, ref, repeat, slotted, when, DOM, Observable, attr, nullableNumberConverter, observable, volatile, } from '@microsoft/fast-element';
import { SVG_CHEVRON_DOWN_FILLED, SVG_CHEVRON_UP_FILLED, SVG_CLOSE_CIRCLE_FILLED, SVG_ALERT_OUTLINED, SVG_CHECKMARK_OUTLINED, } from '@getgo/chameleon-icons';
import { FoundationElement } from '@microsoft/fast-foundation';
import { OptionComponent } from '../Select';
import { SvgComponent } from '../Svg';
import { autoUpdate, computePosition, flip } from '@floating-ui/dom';
import { IconButtonComponent } from '../IconButton';
import { ChipV2Component } from '../ChipV2';
import { SkeletonComponent } from '../Skeleton';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { uniqueId } from '@microsoft/fast-web-utilities';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
export const autocompleteTemplate = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const chipV2Tag = context.tagFor(ChipV2Component);
    const optionTag = context.tagFor(OptionComponent);
    const skeletonTag = context.tagFor(SkeletonComponent);
    const optionFilter = (node) => node.nodeName === optionTag.toUpperCase();
    return html `
  <template @click="${(el, ctx) => el.handleClick(ctx.event)}">
    <label class="label" part="label" for="${(x) => x.controlId}">
      <slot name="label"></slot>
    </label>
    <div
      ${ref('controlBox')}
      part="control"
      class="control"
      @ch-chip-close="${(el, ctx) => el.handleChipClose(ctx.event)}"
    >
        <slot name="start"></slot>
        ${when((x) => x.multiple, html `<slot name="chips" ${slotted('slottedChips')}
            >${repeat((x) => x.chips, html `<${chipV2Tag}
                size="${(_, y) => y.parent.chipSize()}"
                data-option-id="${(chip) => chip.option.id}"
                close-label="${(chip) => chip.option.getAttribute('close-label') ?? `Remove option ${chip.option.label}`}"
                closable>
                  ${when((chip) => chip.avatar, (chip) => html `<${chip.avatar.tagName}
                        slot="avatar"
                        size="${(_, y) => y.parent.chipSize()}"
                        label="${(chip) => chip.avatar.label}"
                        :innerHTML="${(chip) => chip.avatar.innerHTML}">
                      </${chip.avatar.tagName}>`)}
                  ${(chip) => chip.option.label}
                </${chipV2Tag}>`)}
          </slot>`)}
        <slot name="control">
          <input
            part="input-text"
            id="${(x) => x.controlId}"
            type="text"
            value="${(x) => x.value}"
            placeholder="${(x) => x.displayedPlaceholder}"
            class="selected-value"
            ?disabled="${(x) => x.disabled}"
            ${ref('control')}
            @input="${(element, context) => element.handleInput(context.event)}"
            @blur="${(el, ctx) => el.handleBlur(ctx.event)}"
            @keydown="${(x, ctx) => x.keydownHandler(ctx.event)}"
            role="combobox"
            autocomplete="${(x) => x.autocomplete}"
            aria-autocomplete="list"
            aria-controls="${(el) => el.listboxId}"
            aria-expanded="${(el) => el.open}"
            aria-haspopup="listbox"
            aria-activedescendant="${(el) => el.ariaActiveDescandant}"
          />
        </slot>
      <div class="buttons" part="buttons">
      ${html `<${svgTag} class="state-icon">
          ${when((x) => x.error, html `${SVG_ALERT_OUTLINED}`)}
          ${when((x) => x.success, html `${SVG_CHECKMARK_OUTLINED}`)}
        </${svgTag}>`}
        ${when((x) => !x.hasDefaultOptions, html `<${iconButtonTag}
          tabindex="-1"
          size="small"
          class="clear-btn"
          label="${(el) => el.clearButtonLabel}"
          has-selected-option="${(el) => el.hasSelectedOption}"
          @click="${(el, ctx) => el.handleClearButtonClick(ctx.event)}"
        >
          <${svgTag} ${ref('closeButton')}>${SVG_CLOSE_CIRCLE_FILLED}</${svgTag}>
        </${iconButtonTag}>
        `)}
        ${when((x) => x.variant === 'select', html `<${iconButtonTag}
            tabindex="-1"
            size="small"
            class="chevron"
            ?disabled="${(x) => x.disabled}"
            @click="${(el, ctx) => el.handleChevronClick(ctx.event)}"
            aria-controls="${(el) => el.listboxId}"
            aria-expanded="${(el) => el.open}"
            label="${(el) => el.toggleButtonLabel}"
          >
            <${svgTag} ${ref('chevron')}
              >${(x) => (x.open ? SVG_CHEVRON_UP_FILLED : SVG_CHEVRON_DOWN_FILLED)}</${svgTag}
            >
          </${iconButtonTag}>`)}
      </div>
    </div>
    <div id="${(el) => el.listboxId}" ${ref('listbox')} part="listbox" class="listbox ${(x) => (x.open ? 'open' : '')}">
    <${optionTag} class="listbox-start-option" ?hidden="${(x) => x.listboxStart.length === 0}" @click="${(el, ctx) => el.handleListboxStartOptionClick(ctx.event)}" @change="${(el, ctx) => {
        ctx.event.preventDefault();
        ctx.event.stopPropagation();
    }}">
      <slot name="listbox-start" ${slotted('listboxStart')}></slot>
    </${optionTag}>
    <slot ${slotted({ property: 'slottedOptions', filter: optionFilter })}></slot>
      <${optionTag} ${ref('noOptionsElement')} ?hidden=${(x) => !x.shouldShowNoOption} disabled
        >${(x) => x.noOptionsText}</${optionTag}
      >
      <${optionTag} class="skeleton-option" ${ref('loaderOption')} hidden
        ><${skeletonTag} variant="rectangle"></${skeletonTag}
      ></${optionTag}>
    </div>
    <slot name="helper-text"></slot>
  </template>
`;
};
export class AutocompleteComponent extends ChameleonPrefixedElementMixin(FoundationElement, 'autocomplete') {
    constructor() {
        super(...arguments);
        this.variant = 'select';
        this.size = 'medium';
        this.disabled = false;
        this.multiple = false;
        this.error = false;
        this.success = false;
        this.fullWidth = false;
        this.noOptionsText = 'No Options';
        this.clearButtonLabel = '';
        this.toggleButtonLabel = '';
        this.placeholder = undefined;
        this.loading = false;
        this.maxSelectableItems = undefined;
        this.highlightInnerText = false;
        this.open = false;
        // This is an autocomplete component, we should not allow the browsers to interfere with it.
        this.autocomplete = 'off';
        /** Users can set this to true to disable the built-in filtering of options.
         * Useful when the options are rendered as a result of an async call.
         * */
        this.disableFiltering = false;
        this.position = 'bottom';
        this._value = '';
        this._options = [];
        this.optionFilter = (node) => {
            return node.nodeName === this.tagFor('option');
        };
        /** The current value of the filter */
        this.filter = '';
        this.listboxId = uniqueId('listbox');
        /** Marks which option the user is on when moving with keyboard */
        this.currentIndex = -1;
        this.controlId = uniqueId('autocomplete-control-');
        this.selectedOptions = [];
        this.slottedOptions = [];
        this.listboxStart = [];
        this.filteredOptions = [];
        this.needToRemoveHighlightingBeforeApplying = false;
        this.highlightNode = (element, filter) => {
            if (!filter) {
                return;
            }
            Array.from(element.childNodes).forEach((node) => {
                if (node.nodeType === Node.TEXT_NODE) {
                    this.highlightTextNode(node, filter);
                }
                else if (node.nodeType === Node.ELEMENT_NODE) {
                    if (this.shouldSkipHighlighting(node))
                        return;
                    this.highlightNode(node, filter);
                }
            });
        };
    }
    openChanged(_, next) {
        if (next) {
            this.setPositioning();
            this.$emit('open', null, { bubbles: false });
        }
        else {
            this.currentIndex = -1;
            this.$emit('close', null, { bubbles: false });
            if (this.options) {
                for (const option of this.options) {
                    if (!this.isOptionSelected(option)) {
                        option.selected = false;
                    }
                }
            }
        }
    }
    loadingChanged(_, next) {
        const showNoOptions = !next || (this.options?.length ?? 0) > 0;
        this.noOptionsElement?.toggleAttribute('hidden', !showNoOptions);
        this.loaderOption?.toggleAttribute('hidden', !next);
        if (this.options) {
            for (const option of this.options) {
                if (option.classList.contains('listbox-start-option')) {
                    continue;
                }
                option.toggleAttribute('hidden', next);
            }
        }
    }
    positionChanged() {
        this.setPositioning();
    }
    chipSize() {
        switch (this.size) {
            case 'small':
                return 'xsmall';
            case 'medium':
                return 'small';
            case 'large':
            case 'xlarge':
                return 'medium';
            default:
                return 'small';
        }
    }
    get chips() {
        return this.selectedOptions.map((o) => {
            const avatarNode = o.querySelector('[slot="avatar"]');
            return {
                avatar: avatarNode,
                option: o,
            };
        });
    }
    slottedOptionsChanged(_prev, next) {
        const startOption = this.shadowRoot?.querySelector('.listbox-start-option');
        if (startOption && !next.includes(startOption)) {
            this.options = [startOption, ...next.filter((o) => this.optionFilter(o))];
        }
        else {
            this.options = next.filter((o) => this.optionFilter(o));
        }
        const setSize = this.options.length;
        this.options.forEach((option, index) => {
            if (!option.id) {
                option.id = uniqueId('option-');
            }
            option.ariaPosInSet = `${index + 1}`;
            option.ariaSetSize = setSize.toString();
            if (option.default || option.selected) {
                this.selectOption(option, 'attribute');
            }
            const selectedOptionIndex = this.selectedOptions.findIndex((o) => option.value === o.value);
            if (selectedOptionIndex > -1 && !this.selectedOptions[selectedOptionIndex].selected) {
                this.selectedOptions[selectedOptionIndex].selected = true;
            }
        });
        if (this.options.length === 0) {
            this.noOptionsElement?.setAttribute('hidden', 'true');
        }
        this.filterOptions(this.filter);
    }
    slottedChipsChanged(_prev, next) {
        if (next && next.length > 0) {
            for (const chip of next) {
                if (chip.getAttribute('size') !== 'small') {
                    chip.setAttribute('size', 'small');
                }
            }
        }
    }
    get shouldShowNoOption() {
        return (this.loading !== true &&
            ((!this.disableFiltering && this.filteredOptions?.length === 0) ||
                (this.disableFiltering && this.slottedOptions?.length === 0)));
    }
    get displayedPlaceholder() {
        return this.selectedOptions.length === 0 ? this.placeholder : undefined;
    }
    get firstOption() {
        return this.disableFiltering ? this.slottedOptions[0] : this.filteredOptions[0];
    }
    get isSearchType() {
        return this.variant === 'search';
    }
    get hasSelectedOption() {
        return this.selectedOptions && this.selectedOptions.length > 0;
    }
    get options() {
        Observable.track(this, 'value');
        return this.filteredOptions?.length > 0 ? this.filteredOptions : this._options;
    }
    set options(next) {
        this._options = next;
        Observable.notify(this, 'options');
    }
    get value() {
        Observable.track(this, 'value');
        return this._value;
    }
    set value(next) {
        this._value = next;
        Observable.notify(this, 'value');
    }
    get ariaActiveDescandant() {
        return this.open && this.currentIndex >= 0 && this.currentIndex < this.options.length
            ? this.options[this.currentIndex].id
            : null;
    }
    get isMaxSelectableItemsReached() {
        return this.multiple && this.maxSelectableItems && this.selectedOptions.length >= (this.maxSelectableItems ?? -1);
    }
    get hasDefaultOptions() {
        return this.selectedOptions.length > 0 && this.selectedOptions.some((o) => o.default);
    }
    filterFunction(inputValue, option) {
        return this.getOptionText(option).toLowerCase().startsWith(inputValue.toLowerCase()) ?? false;
    }
    disconnectedCallback() {
        this.autoPositioningCleanup?.();
    }
    handleClick(e) {
        if (this.disabled || e.defaultPrevented) {
            return;
        }
        if (this.open) {
            const capturedOption = e.target.closest('option, [role=option]');
            if (!capturedOption || capturedOption.disabled) {
                return;
            }
            const isAlreadySelected = this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(capturedOption)) > -1;
            if (isAlreadySelected && this.multiple) {
                this.deselectOption(capturedOption, 'click');
            }
            else if (!this.isMaxSelectableItemsReached) {
                this.selectOption(capturedOption, 'click');
            }
            this.control.focus({ preventScroll: true });
        }
        if (!this.open && !this.isSearchType) {
            this.open = true;
        }
        else {
            this.open = false;
        }
        return true;
    }
    handleChipClose(event) {
        event.preventDefault();
        const optionId = event.target.getAttribute('data-option-id');
        if (!optionId) {
            console.warn('Chip element inside Autocomplete must have a data-option-id attribute set to the id of the option element it refers to for the close functionality to work.');
            return;
        }
        const optionToRemove = this.selectedOptions.find((option) => option.id === optionId);
        if (!optionToRemove) {
            return;
        }
        this.deselectOption(optionToRemove, 'chip-close');
        this.control.focus();
    }
    handleInput(e) {
        e.stopPropagation();
        if (this.currentIndex > 0 && !this.isOptionSelected(this.options[this.currentIndex])) {
            this.options[this.currentIndex].selected = false;
        }
        this.currentIndex = -1;
        if (this.isSearchType && e.target.value === '') {
            this.open = false;
        }
        else {
            this.open = true;
        }
        this.filter = e.target.value;
        this.filterOptions(this.filter);
        this.$emit('input', { value: e.target.value }, { bubbles: false });
    }
    handleBlur(e) {
        const chevron = this.shadowRoot?.querySelector('.chevron');
        // This branch is for handling the case where there's something written in the input
        // but there's no selected option. In this case we just set the input back to an empty string.
        if (this.control.value && !this.hasSelectedOption) {
            this.filter = '';
            this.control.value = '';
            this.filterOptions(this.filter);
            this.$emit('input', { value: '' });
        }
        // Handles the scenario where the user deletes the selected value from the input completely,
        // in this case we should remove the selection.
        if (!this.multiple && this.control.value === '') {
            this.deselectOptions('blur');
        }
        // This branch handles when there's a selected option, but the text in the input does not
        // match the label of the selected option
        if (!this.multiple &&
            this.hasSelectedOption &&
            this.control.value !== '' &&
            this.control.value !== this.getOptionText(this.selectedOptions[0])) {
            this.control.value = this.getOptionText(this.selectedOptions[0]);
            this.filter = this.getOptionText(this.selectedOptions[0]);
            this.filterOptions(this.filter);
            this.$emit('input', { value: this.getOptionText(this.selectedOptions[0]) });
        }
        // This branch handles the case where the user clicks on the chevron to close the listbox
        // The chevron is only rendered when the variant is *not* 'search'
        if (!this.isSearchType && e.relatedTarget === chevron) {
            return;
        }
        // If the listbox-start slot is clicked we do not want to close the listbox
        if (this.listboxStart.length !== 0 && e.relatedTarget === this.listboxStart[0]) {
            return;
        }
        this.open = false;
    }
    handleListboxStartOptionClick(event) {
        if (event.target.slot === 'listbox-start') {
            return;
        }
        const slot = this.shadowRoot?.querySelector('slot[name="listbox-start"]');
        const assignedElements = slot?.assignedElements();
        if (assignedElements.length > 0) {
            const button = assignedElements[0];
            button.dispatchEvent(new Event('click', { bubbles: true }));
        }
    }
    keydownHandler(e) {
        const key = e.key;
        e.stopPropagation();
        if (e.ctrlKey || e.shiftKey) {
            return true;
        }
        switch (key) {
            case 'Enter': {
                // if we cannot find the option based on the currentIndex, for example if the user has not moved in the list with the arrow keys
                // then we select the first matching option from the either filteredOptions or options is disableFiltering is true
                // if no option is selected, index is -1
                if (this.currentIndex === -1) {
                    // we want the search type to select the first option if filtering is disabled
                    if (this.isSearchType && this.filteredOptions.length === 0) {
                        this.selectOption(this.firstOption, 'enter');
                    }
                    if (this.filteredOptions.length > 0) {
                        const element = this.filteredOptions[0];
                        if (element instanceof OptionComponent && !element.classList.contains('listbox-start-option')) {
                            const isAlreadySelected = this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(element)) > -1;
                            if (isAlreadySelected) {
                                const inputElement = this.shadowRoot?.querySelector('input#autocomplete-control');
                                if (inputElement) {
                                    inputElement.value = this.getOptionText(element);
                                }
                                this.filter = this.getOptionText(element);
                                this.$emit('input', { value: this.filter }, { bubbles: false });
                                if (!this.multiple || this.isSearchType) {
                                    this.open = false;
                                }
                            }
                            else if (this.open && !this.isMaxSelectableItemsReached) {
                                this.selectOption(element, 'enter');
                            }
                        }
                    }
                    break;
                }
                const element = this.getElementAtIndex(this.currentIndex);
                if (element instanceof OptionComponent && !element.classList.contains('listbox-start-option')) {
                    const isAlreadySelected = this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(element)) > -1;
                    if (this.open && element && !isAlreadySelected && !this.isMaxSelectableItemsReached) {
                        this.selectOption(element, 'enter');
                    }
                }
                else {
                    element.click();
                    return true;
                }
                if (!this.multiple || this.isSearchType) {
                    this.open = false;
                }
                break;
            }
            case 'Escape': {
                if (this.open) {
                    this.open = false;
                    break;
                }
                this.value = '';
                this.control.value = '';
                this.filter = '';
                this.filterOptions(this.filter);
                this.control.focus();
                break;
            }
            case 'Tab': {
                const optionToSelect = this.options[this.currentIndex];
                const isAlreadySelected = this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(optionToSelect)) > -1;
                if (optionToSelect && !isAlreadySelected) {
                    this.selectOption(optionToSelect, 'tab');
                }
                if (!this.open) {
                    return true;
                }
                this.open = false;
                return true;
            }
            case 'ArrowUp':
            case 'ArrowDown': {
                if (!this.open && !this.isSearchType) {
                    this.open = true;
                }
                if (e.altKey) {
                    break;
                }
                if (this.filteredOptions.length > 0 || (this.disableFiltering && this.slottedOptions.length > 0)) {
                    if (e.key === 'ArrowUp') {
                        this.moveToPreviousOption();
                    }
                    if (e.key === 'ArrowDown') {
                        this.moveToNextOption();
                    }
                }
                break;
            }
            case 'Home': {
                this.control.selectionStart = 0;
                this.control.selectionEnd = 0;
                e.preventDefault();
                break;
            }
            case 'End': {
                this.control.selectionStart = this.control.value.length;
                this.control.selectionEnd = this.control.value.length;
                e.preventDefault();
                break;
            }
            default: {
                return true;
            }
        }
    }
    handleChevronClick(e) {
        if (this.disabled) {
            return;
        }
        if (this.open) {
            this.open = false;
        }
        else {
            this.open = true;
        }
        this.control.focus();
        e.stopPropagation();
    }
    handleClearButtonClick(e) {
        this.deselectOptions('clear-click');
        this.$emit('clear-click');
        this.control.focus();
        e.stopPropagation();
    }
    clear() {
        this.deselectOptions('clear-function');
    }
    setPositioning() {
        if (!(this instanceof HTMLElement) || !this.isConnected) {
            return;
        }
        this.autoPositioningCleanup?.();
        DOM.queueUpdate(() => {
            this.autoPositioningCleanup = autoUpdate(this.controlBox, this.listbox, () => {
                computePosition(this.controlBox, this.listbox, { placement: this.position, middleware: [flip()] }).then(({ y, placement }) => {
                    if (this.position !== placement) {
                        this.position = placement;
                    }
                    // we have to offset the y value by 2 pixels to account for the border and the focus ring of the control box
                    const offsetPosition = placement === 'bottom' ? y + 2 : y - 2;
                    this.style.setProperty('--autocomplete-listbox-position', `${offsetPosition}px`);
                });
            });
        });
    }
    filterOptions(filterValue) {
        if (!this.disableFiltering) {
            this.filteredOptions = this.slottedOptions.filter((option) => this.filterFunction(filterValue, option));
        }
        this.slottedOptions?.forEach((option) => {
            const showOption = this.disableFiltering || this.filterFunction(filterValue ?? '', option);
            option.toggleAttribute('hidden', !showOption);
            option.classList.toggle('hidden', !showOption);
            this.renderHighlighting(option, filterValue);
        });
        if (this.filteredOptions.length === 0) {
            this.noOptionsElement?.removeAttribute('hidden');
        }
        else {
            this.noOptionsElement?.setAttribute('hidden', 'true');
        }
        if (!this.filteredOptions && !this.filter) {
            this.filteredOptions = this.slottedOptions;
        }
    }
    renderHighlighting(option, filter) {
        if (this.needToRemoveHighlightingBeforeApplying) {
            const highlightNodes = option.querySelectorAll('.highlight');
            highlightNodes.forEach((element) => {
                element.replaceWith(...element.childNodes);
            });
            option.normalize();
        }
        if (!filter || !this.highlightInnerText) {
            return;
        }
        this.highlightNode(option, filter);
        this.needToRemoveHighlightingBeforeApplying = true;
    }
    highlightTextNode(node, filter) {
        const textContent = node?.textContent ?? '';
        const matches = match(textContent, filter, { insideWords: true });
        const parts = parse(textContent, matches);
        let existingNode = node;
        parts.forEach((part) => {
            const remainderNode = existingNode.splitText(part.text.length);
            if (part.highlight) {
                const spanHighlight = document.createElement('span');
                spanHighlight.className = 'highlight';
                spanHighlight.style.backgroundColor = 'var(--goto-highlight-background-color)';
                spanHighlight.style.color = 'var(--goto-highlight-type-color)';
                spanHighlight.style.font = 'var(--goto-body-medium-strong)';
                const copiedNode = existingNode.cloneNode();
                spanHighlight.appendChild(copiedNode);
                existingNode.replaceWith(spanHighlight);
            }
            existingNode = remainderNode;
        });
    }
    shouldSkipHighlighting(node) {
        // To skip the avatar letters from being highlighted
        const skipTags = ['CHAMELEON-AVATAR', 'CHAMELEON-AVATAR-V2'];
        return skipTags.includes(node.tagName.toUpperCase()) || node.classList.contains('highlight');
    }
    moveToNextOption() {
        const totalElements = this.totalNavigableElements;
        if (totalElements === 0)
            return;
        const nextIndex = this.currentIndex;
        for (let i = 1; i <= totalElements; i++) {
            const index = (nextIndex + i) % totalElements;
            const element = this.getElementAtIndex(index);
            if (!element)
                continue;
            if (!this.isElementDisabled(element)) {
                this.deselectCurrentElement();
                this.currentIndex = index;
                this.selectCurrentElement();
                this.focusAndScrollElementIntoView(element);
                break;
            }
        }
    }
    moveToPreviousOption() {
        const totalElements = this.totalNavigableElements;
        if (totalElements === 0)
            return;
        const prevIndex = this.currentIndex === -1 ? totalElements : this.currentIndex;
        for (let i = 1; i <= totalElements; i++) {
            const index = (prevIndex - i + totalElements) % totalElements;
            const element = this.getElementAtIndex(index);
            if (!element)
                continue;
            if (!this.isElementDisabled(element)) {
                this.deselectCurrentElement();
                this.currentIndex = index;
                this.selectCurrentElement();
                this.focusAndScrollElementIntoView(element);
                break;
            }
        }
    }
    selectOption(option, source) {
        if (this.selectedOptions.includes(option)) {
            if (this.multiple === false) {
                // Refresh the input value
                this.control.value = this.getOptionText(option);
            }
            return;
        }
        option.selected = true;
        if (this.multiple) {
            this.selectedOptions = [...this.selectedOptions, option];
            this.control.value = '';
            this.filter = '';
            this.filterOptions(this.filter);
            this.$emit('input', { value: '', source });
        }
        else {
            // Only add default if has already any other option with the default value
            if (this.hasDefaultOptions) {
                this.selectedOptions[0].setAttribute('default', 'false');
                option.default = true;
            }
            if (this.hasSelectedOption) {
                this.selectedOptions[0].selected = false;
            }
            this.selectedOptions = [option];
            this.value = this.getOptionText(option);
            this.filter = '';
            this.control.value = this.getOptionText(option);
            this.filterOptions(this.filter);
            this.$emit('input', { value: this.getOptionText(this.selectedOptions[0]), source });
        }
        this.$emit('change', { selectedOptions: this.selectedOptions });
    }
    deselectOption(option, source) {
        if (option.default)
            return;
        option.selected = false;
        if (this.multiple) {
            this.selectedOptions = this.selectedOptions.filter((o) => this.getOptionText(o) !== this.getOptionText(option));
            this.value = '';
        }
        else {
            this.selectedOptions = [];
            this.value = '';
            this.filter = '';
            this.filterOptions(this.filter);
            this.control.value = '';
        }
        this.$emit('input', { value: '', source });
        this.$emit('change', { selectedOptions: this.selectedOptions }, { bubbles: true });
    }
    deselectOptions(source) {
        // In case the user clears the input, fallback to the current default option
        if (this.hasDefaultOptions) {
            const currentDefaultOption = this.selectedOptions.find((o) => o.default);
            this.selectOption(currentDefaultOption, source);
            return;
        }
        for (const option of this.options) {
            option.selected = false;
        }
        this.selectedOptions = [];
        this.value = '';
        this.filter = '';
        this.control.value = '';
        this.filterOptions(this.filter);
        this.$emit('input', { value: '', source });
        this.$emit('change', { selectedOptions: this.selectedOptions }, { bubbles: true });
    }
    isOptionSelected(option) {
        return this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(option)) > -1;
    }
    getOptionText(option) {
        return option?.label;
    }
    get totalNavigableElements() {
        return (this.listboxStart?.length ?? 0) + (this.options?.length ?? 0);
    }
    getElementAtIndex(index) {
        if (index < 0)
            return null;
        if (this.listboxStart && index < this.listboxStart.length) {
            const startOption = this.shadowRoot?.querySelector('.listbox-start-option');
            return startOption;
        }
        if (this.options && index - this.listboxStart.length < this.options.length) {
            return this.options[index - this.listboxStart.length];
        }
        return null;
    }
    isElementDisabled(element) {
        if (element instanceof OptionComponent) {
            return element.disabled;
        }
        return element.hasAttribute('disabled');
    }
    deselectCurrentElement() {
        if (this.currentIndex === -1)
            return;
        const element = this.getElementAtIndex(this.currentIndex);
        if (element) {
            this.deselectElement(element);
        }
    }
    selectCurrentElement() {
        const element = this.getElementAtIndex(this.currentIndex);
        if (element) {
            this.selectElement(element);
        }
    }
    deselectElement(element) {
        if (element instanceof OptionComponent) {
            if (!this.isOptionSelected(element)) {
                element.selected = false;
            }
        }
        else {
            this.shadowRoot?.querySelector('.listbox-start-option')?.setAttribute('aria-selected', 'false');
        }
    }
    selectElement(element) {
        if (element instanceof OptionComponent) {
            element.selected = true;
        }
        else {
            this.shadowRoot?.querySelector('.listbox-start-option')?.setAttribute('aria-selected', 'false');
        }
    }
    focusAndScrollElementIntoView(element) {
        requestAnimationFrame(() => {
            element.scrollIntoView({ block: 'nearest' });
        });
    }
}
__decorate([
    attr()
], AutocompleteComponent.prototype, "variant", void 0);
__decorate([
    attr()
], AutocompleteComponent.prototype, "size", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "multiple", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "success", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'full-width' })
], AutocompleteComponent.prototype, "fullWidth", void 0);
__decorate([
    attr({ mode: 'fromView', attribute: 'no-options-text' })
], AutocompleteComponent.prototype, "noOptionsText", void 0);
__decorate([
    attr({ attribute: 'clear-button-label' })
], AutocompleteComponent.prototype, "clearButtonLabel", void 0);
__decorate([
    attr({ attribute: 'toggle-button-label' })
], AutocompleteComponent.prototype, "toggleButtonLabel", void 0);
__decorate([
    attr()
], AutocompleteComponent.prototype, "placeholder", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "loading", void 0);
__decorate([
    attr({ attribute: 'max-selectable-items', converter: nullableNumberConverter })
], AutocompleteComponent.prototype, "maxSelectableItems", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'highlight-inner-text' })
], AutocompleteComponent.prototype, "highlightInnerText", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "open", void 0);
__decorate([
    attr
], AutocompleteComponent.prototype, "autocomplete", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'disable-filtering' })
], AutocompleteComponent.prototype, "disableFiltering", void 0);
__decorate([
    attr
], AutocompleteComponent.prototype, "position", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "filter", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "currentIndex", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "controlId", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "selectedOptions", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "slottedOptions", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "listboxStart", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "chipSize", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "chips", null);
__decorate([
    observable
], AutocompleteComponent.prototype, "slottedChips", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "filteredOptions", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "shouldShowNoOption", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "displayedPlaceholder", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "hasSelectedOption", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "ariaActiveDescandant", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "isMaxSelectableItemsReached", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "hasDefaultOptions", null);
