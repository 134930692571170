import { ListboxElement } from '@microsoft/fast-foundation';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { forwardKeyboardEvent } from './helpers';
export class ListboxComponent extends ChameleonPrefixedElementMixin(ListboxElement, 'listbox') {
    constructor() {
        super(...arguments);
        this.defaultBaseName = 'listbox';
        this.handleKeyDown = (event) => {
            const listboxItems = this.querySelectorAll(this.tagFor('listbox-item'));
            const selectedListboxItem = Array.from(listboxItems)?.find((listboxItem) => listboxItem.getAttribute('aria-selected') === 'true');
            const { key } = event;
            if (selectedListboxItem &&
                (key === 'ArrowUp' ||
                    key === 'ArrowDown' ||
                    key === 'ArrowRight' ||
                    key === 'ArrowLeft' ||
                    key === 'Enter' ||
                    key === ' ')) {
                forwardKeyboardEvent(event, selectedListboxItem);
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
    }
    disconnectedCallback() {
        this.removeEventListener('keydown', this.handleKeyDown);
    }
}
