import { __decorate } from "tslib";
import { attr, DOM, html, nullableNumberConverter, observable, ref, when, } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { traverseShadowRootsForElementWithId } from '../common/utils/DomUtil';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { calculatePopoverPosition } from '../common/positionFloatingElement';
import { forwardKeyboardEvent } from '../Listbox/helpers';
import { createFocusTrap } from 'focus-trap';
import { uniqueId } from '@microsoft/fast-web-utilities';
const DEFAULT_POSITION = `bottom-center`;
const NATIVE_TRIGGER_LIST = [
    'chameleon-button',
    'chameleon-round-button',
    'chameleon-icon-button',
    'chameleon-internal-navigation-rail-item',
    'button',
];
const MIN_WIDTH = 56;
export const popoverV2Template = (context, definition) => {
    return html `
  <template>
    ${when((c) => !c.disableFocusTrap, html `
      <dialog ${ref('dialog')}>
      <div ${ref('content')} class="content" tabindex="-1">
        <div class="inner">
          <slot></slot>
        </div>
        <div class="arrow"></div>
      </div>
      </dialog>
      `)}
      ${when((c) => c.disableFocusTrap, html `<div popover ${ref('dialog')}  tabindex="-1">
      <div ${ref('content')} class="content" role="tooltip">
        <div class="inner">
          <slot></slot>
        </div>
        <div class="arrow"></div>
      </div>
      </div>`)}
  </template>
`;
};
export class PopoverV2Component extends ChameleonPrefixedElementMixin(FoundationElement, 'popover-v2') {
    isOpenChanged(_, next) {
        if (!this.$fastController.isConnected || this.hidden || !this.triggerEl) {
            return;
        }
        if (next) {
            this.openPopover();
        }
        else {
            this.closePopover();
        }
    }
    triggerIdChanged(_, next) {
        if (!next || next === '') {
            return;
        }
        // The first time this function is run, the elements might not be actually rendered into the DOM.
        // For this reason, we need to use the queueUpdate here to wait until the element and its triggers
        // are actually rendered into the DOM so we can find it and attach the listeners.
        DOM.queueUpdate(() => {
            this.triggerEl = traverseShadowRootsForElementWithId(next, this);
            if (!this.triggerEl) {
                return;
            }
            this.triggerEl.addEventListener('click', this.triggerClickHandler);
            this.triggerEl.addEventListener('keydown', this.handleKeyDown);
            if (!this.triggerEl.getAttribute('role')) {
                this.triggerEl.setAttribute('role', 'button');
            }
            this.triggerEl.setAttribute('aria-haspopup', this.ariaHasPopupValue);
            this.triggerEl.setAttribute('aria-expanded', `${this.isOpen}`);
            this.triggerEl.setAttribute('aria-controls', this.contentElId);
            if (this.triggerEl.parentElement) {
                this.triggerObserver.observe(this.triggerEl.parentElement, { childList: true });
            }
        });
    }
    widthChanged(_, next) {
        if (next > MIN_WIDTH) {
            this.style.setProperty('--popover-width', next.toString() + 'px');
            if (this.isConnected) {
                this.positionPopover();
            }
        }
    }
    maxWidthChanged(_, next) {
        if (next) {
            this.style.setProperty('--popover-max-width', next.toString() + 'px');
            if (this.isConnected) {
                this.positionPopover();
            }
        }
    }
    minWidthChanged(_, next) {
        if (next) {
            this.style.setProperty('--popover-min-width', next.toString() + 'px');
            if (this.isConnected) {
                this.positionPopover();
            }
        }
    }
    get anchor() {
        return this.anchorElement ?? this.triggerEl;
    }
    get ariaHasPopupValue() {
        return this.menu ? 'menu' : 'dialog';
    }
    constructor() {
        super();
        this.position = DEFAULT_POSITION;
        this.compact = false;
        this.inverted = false;
        this.hidden = false;
        this.arrow = false;
        this.menu = false;
        /**
         * We have to keep this for backward compatibility, but it doesn't have any effect anymore
         * */
        this.triggerOn = 'click';
        this.isOpen = false;
        /**
         * @description The `id` of the element that triggers the popover
         * */
        this.triggerId = '';
        /**
         * This is used for backward compatibility reasons in react wrappers. There we need to know if we're rendering
         * the version the uses the `dialog` element internally or the older version in which case we render the old wrapper component.
         * */
        this.isDialogVersion = true;
        this.isTooltip = false;
        this.delay = undefined;
        this.triggerEl = null;
        this.contentElId = uniqueId('popover-content-');
        this.handleMenuItemClick = (e) => {
            if (!e.defaultPrevented && this.menu) {
                this.close();
            }
        };
        this.triggerClickHandler = () => {
            this.toggle();
        };
        this.documentClickHandler = (event) => {
            if (!this.isOpen) {
                document.removeEventListener('click', this.documentClickHandler);
                return;
            }
            if (this.triggerEl?.contains(event.target) || event.composedPath().includes(this.content)) {
                return;
            }
            this.close();
            this.dialog.removeEventListener('click', this.documentClickHandler);
        };
        this.isCustomTrigger = () => {
            const tagName = this.triggerEl?.tagName.toLowerCase();
            return (tagName &&
                (NATIVE_TRIGGER_LIST.indexOf(tagName) === -1 ||
                    // Custom Prefix hack
                    (this.triggerEl &&
                        'chameleonVersion' in this.triggerEl &&
                        NATIVE_TRIGGER_LIST.some((c) => tagName.endsWith(c.replace('chameleon-', ''))))));
        };
        this.handleKeyDown = (event) => {
            if (event.code === 'Space') {
                if (this.isCustomTrigger()) {
                    // Prevents scrolling the page down
                    event.preventDefault();
                }
            }
            if (event.key === 'Enter' || event.code === 'Space') {
                // Chameleon buttons already have a keyboard event hooked up, no need to override them
                if (this.isCustomTrigger()) {
                    this.toggle();
                    document.addEventListener('click', this.documentClickHandler);
                }
            }
        };
        this.handleContentKeydownEvent = (e) => {
            const innerContentElement = this.querySelector(`chameleon-listbox, ${this.tagFor('listbox')}`);
            if (innerContentElement &&
                (e.key === 'ArrowUp' ||
                    e.key === 'ArrowDown' ||
                    e.key === 'ArrowRight' ||
                    e.key === 'ArrowLeft' ||
                    e.key === 'Enter' ||
                    e.key === ' ')) {
                forwardKeyboardEvent(e, innerContentElement);
            }
            if (e.key === 'Escape') {
                this.close();
            }
        };
        this.handleTriggerMutation = (mutationsList) => {
            for (const mutation of mutationsList) {
                const isTriggerElRemoved = Array.from(mutation.removedNodes).some((node) => node === this.triggerEl);
                if (this.dialog.hasAttribute('open') && mutation.type === 'childList' && isTriggerElRemoved) {
                    this.closePopover();
                }
            }
        };
        this.triggerObserver = new MutationObserver(this.handleTriggerMutation);
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('menuitemclick', this.handleMenuItemClick);
        /**
         * When the trigger element get previously removed from the dom and reappear with the same id.
         * Worst case, this could attempt to add the same listener twice, which the browser will ignore.
         * (https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener)
         * */
        if (this.triggerEl) {
            this.triggerEl.addEventListener('click', this.triggerClickHandler);
            this.triggerEl.addEventListener('keydown', this.handleKeyDown);
        }
        this.focusTrap = createFocusTrap(this.dialog, {
            onDeactivate: () => {
                this.close();
            },
            allowOutsideClick: true,
            setReturnFocus: () => this.triggerEl,
            fallbackFocus: this.content,
            tabbableOptions: {
                getShadowRoot: true,
            },
        });
        if (this.isOpen) {
            DOM.queueUpdate(() => {
                this.openPopover();
            });
        }
    }
    disconnectedCallback() {
        if (!this.triggerEl) {
            return;
        }
        this.cleanupFn?.();
        this.triggerEl.removeEventListener('click', this.triggerClickHandler);
        this.triggerEl.removeEventListener('keydown', this.handleKeyDown);
        this.dialog.removeEventListener('click', this.documentClickHandler);
        this.removeEventListener('menuitemclick', this.handleMenuItemClick);
        this.closePopover();
    }
    close() {
        this.isOpen = false;
    }
    open() {
        this.isOpen = true;
    }
    toggle() {
        this.isOpen = !this.isOpen;
    }
    positionPopover() {
        this.cleanupFn?.();
        calculatePopoverPosition(this, this.dialog, this.anchor).then((cleanup) => {
            this.cleanupFn = cleanup;
        });
    }
    openPopover() {
        if (this.disableFocusTrap) {
            this.dialog.showPopover();
        }
        else {
            this.dialog.showModal();
        }
        this.positionPopover();
        if (!this.disableFocusTrap) {
            this.focusTrap.activate();
        }
        this.addEventListener('keydown', this.handleContentKeydownEvent);
        this.dialog.addEventListener('click', this.documentClickHandler);
        this.$emit('popoverchange', { isOpen: true });
    }
    closePopover() {
        if (this.disableFocusTrap) {
            this.dialog.hidePopover();
        }
        else {
            this.dialog.close();
        }
        this.focusTrap.deactivate();
        this.removeEventListener('keydown', this.handleContentKeydownEvent);
        this.cleanupFn?.();
        this.$emit('popoverchange', { isOpen: false });
    }
}
__decorate([
    attr()
], PopoverV2Component.prototype, "position", void 0);
__decorate([
    attr({ converter: nullableNumberConverter })
], PopoverV2Component.prototype, "width", void 0);
__decorate([
    attr({ attribute: 'min-width', converter: nullableNumberConverter })
], PopoverV2Component.prototype, "minWidth", void 0);
__decorate([
    attr({ attribute: 'max-width', converter: nullableNumberConverter })
], PopoverV2Component.prototype, "maxWidth", void 0);
__decorate([
    attr({ attribute: 'z-index', converter: nullableNumberConverter })
], PopoverV2Component.prototype, "zIndex", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverV2Component.prototype, "compact", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverV2Component.prototype, "inverted", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverV2Component.prototype, "hidden", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverV2Component.prototype, "arrow", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverV2Component.prototype, "menu", void 0);
__decorate([
    attr({ attribute: 'trigger-on' })
], PopoverV2Component.prototype, "triggerOn", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'is-open' })
], PopoverV2Component.prototype, "isOpen", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], PopoverV2Component.prototype, "labelId", void 0);
__decorate([
    attr
], PopoverV2Component.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'disable-focus-trap', mode: 'boolean' })
], PopoverV2Component.prototype, "disableFocusTrap", void 0);
__decorate([
    attr({ attribute: 'trigger-id' })
], PopoverV2Component.prototype, "triggerId", void 0);
__decorate([
    observable
], PopoverV2Component.prototype, "dialog", void 0);
__decorate([
    observable
], PopoverV2Component.prototype, "content", void 0);
