import { css, html } from '@microsoft/fast-element';
import { accordionItemStyles } from '@microsoft/fast-components';
import { AccordionItem, accordionItemTemplate as template, } from '@microsoft/fast-foundation';
import { AccordionItemComponent } from './AccordionItem';
import styles from './AccordionItem.styles.scss';
import { SVG_CHEVRON_DOWN_OUTLINED, SVG_CHEVRON_RIGHT_OUTLINED, SVG_CHEVRON_UP_OUTLINED } from '@getgo/chameleon-icons';
import { withStylePrefix } from '../../common/withStylePrefix';
export const chameleonAccordionItem = AccordionItemComponent.compose({
    baseName: 'accordion-item',
    baseClass: AccordionItem,
    template: template,
    styles: (ctx, def) => [
        css `
      ${accordionItemStyles(ctx, def)}
    `,
        withStylePrefix(styles)(ctx, def),
    ],
    collapsedIcon: html `${(x) => x.chevronPosition === 'left'
        ? html `<${x.tagFor('svg')}>${SVG_CHEVRON_RIGHT_OUTLINED}</${x.tagFor('svg')}>`
        : html `<${x.tagFor('svg')}>${SVG_CHEVRON_DOWN_OUTLINED}</${x.tagFor('svg')}>`}`,
    expandedIcon: html `${(x) => x.chevronPosition === 'left'
        ? html `<${x.tagFor('svg')}>${SVG_CHEVRON_DOWN_OUTLINED}</${x.tagFor('svg')}>`
        : html `<${x.tagFor('svg')}>${SVG_CHEVRON_UP_OUTLINED}</${x.tagFor('svg')}>`}`,
});
