import { MenuSectionComponent } from './MenuSection';
import { html } from '@microsoft/fast-element';
import styles from './MenuSection.styles.scss';
import { withStylePrefix } from '../../common/withStylePrefix';
import { TypographyComponent } from '../../Typography';
const template = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    return html `<${typographyTag}
  variant="caption-medium-strong"
  color="type-color-secondary"
>
  <slot></slot>
</${typographyTag}>`;
};
export const menuSectionDefinition = {
    baseName: 'menu-section',
    template,
    styles: withStylePrefix(styles),
};
export const chameleonMenuSection = MenuSectionComponent.compose(menuSectionDefinition);
