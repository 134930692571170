import { __decorate } from "tslib";
import { SVG_COPY_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { IconButtonComponent } from '../IconButton';
import { SvgComponent } from '../Svg';
import { TooltipComponent } from '../Tooltip';
const copyButton = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    return html `<${iconButtonTag}
    id="copy-button"
    size="small"
    variant="primary"
    @click=${(x) => x.copyToClipboard()}
    ><${svgTag}>${SVG_COPY_OUTLINED}</${svgTag}></${iconButtonTag}
  >`;
};
export const codeTemplate = (context, definition) => {
    const tooltipTag = context.tagFor(TooltipComponent);
    return html `<code><slot></slot></code>${when((x) => !x.inline, () => html `${(x) => !!x.copystring
        ? html `<${tooltipTag} position="top" label="${x.copystring}"
                >${copyButton(context, definition)}</${tooltipTag}
              >`
        : copyButton(context, definition)}`)}`;
};
export class CodeComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.inline = false;
    }
    getContentToCopy() {
        const slot = this.shadowRoot?.querySelector('slot');
        const nodes = slot?.assignedNodes();
        return nodes
            ?.map((node) => node.textContent ?? '')
            .reverse()
            .reduce((accumulator, currentValue) => (currentValue = accumulator !== '' ? `${currentValue}${accumulator}` : currentValue), '');
    }
    copyToClipboard() {
        const content = this.getContentToCopy();
        if (content) {
            navigator.clipboard.writeText(content).then(() => {
                this.$emit('copied', content, { bubbles: false });
            });
        }
    }
}
__decorate([
    attr
], CodeComponent.prototype, "copystring", void 0);
__decorate([
    attr({ mode: 'boolean' })
], CodeComponent.prototype, "inline", void 0);
