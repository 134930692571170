import { AutocompleteComponent, autocompleteTemplate } from './Autocomplete';
import { comboboxStyles } from '@microsoft/fast-components';
import styles from './Autocomplete.styles.scss';
import selectStyles from '../Select/Select.styles.scss';
import { css } from '@microsoft/fast-element';
export const autocompleteDefinition = {
    baseName: 'autocomplete',
    template: autocompleteTemplate,
    styles: (context, definition) => css `
    ${comboboxStyles(context, definition)}
    ${selectStyles}
    ${styles}
  `,
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonAutocomplete = AutocompleteComponent.compose(autocompleteDefinition);
