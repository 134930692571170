import { __decorate } from "tslib";
import { attr, html, observable, ref, slotted, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { debounce } from 'lodash-es';
import { ButtonComponent } from '../../Button';
export const chatBubbleTemplate = (context, _definition) => {
    const buttonTag = context.tagFor(ButtonComponent);
    return html `<template tabindex="0">
    <div class="container">
      <slot name="contextual-actions"></slot>
      <div class="contents" ${ref('contents')}>
        <slot name="header"></slot>
        <slot name="attachments"></slot>
        <span class="content-text"><slot ${slotted('defaultSlot')}></slot></span>
      </div>

      ${when((x) => x.unclamp === false && x.showTruncateAction, html `<${buttonTag} @click=${(x) => x.clickTruncateHandler()} class="truncate-action" variant="tertiary">
        ${(x) => (x.expanded ? x.seeLess : x.seeMore)}
      </${buttonTag}>`)}
      <slot name="footer"></slot>
    </div>
    <div class="reactions" ${ref('reactionsContainer')}>
      <slot name="reactions" ${slotted('reactions')}></slot>
    </div>
  </template>`;
};
export class ChatBubbleComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.variant = 'default';
        this.type = 'inbound';
        this.first = false;
        this.clickable = false;
        this.shortMessage = false;
        this.expanded = false;
        this.unclamp = false;
        this.showTruncateAction = false;
        this.seeMore = 'See more';
        this.seeLess = 'See less';
        this.lineClamp = 15;
        this.observerCallback = debounce((entries) => {
            entries.forEach((entry) => {
                this.shortMessage = entry.contentRect.width < 100;
                this.handleShowTruncateAction(this.allTextNodes);
                this.reactionsContainer.style.minWidth = `min(100%, calc(${Math.ceil(entry.contentRect.width)}px + 2*var(--goto-spacing-03)))`;
            });
        }, 100);
        this.defaultSlot = [];
        this.handleKeyDown = (e) => {
            const key = e.key;
            const activeElements = document.activeElement;
            switch (key) {
                case 'Enter': {
                    if (this === activeElements) {
                        this.click();
                    }
                    break;
                }
                default: {
                    return true;
                }
            }
            e.stopPropagation();
        };
        this.handleMouseUp = (e) => {
            this.removeAttribute('data-pressed');
            e.stopPropagation();
        };
        this.observer = new ResizeObserver(this.observerCallback);
    }
    reactionsChanged() {
        if (!this.isConnected) {
            return;
        }
        if (this.reactions?.length === 0) {
            this.setAttribute('data-no-reactions', 'true');
        }
        else {
            this.removeAttribute('data-no-reactions');
        }
    }
    clickableChanged(_, newValue) {
        newValue ? this.bindClickableEvents() : this.unbindClickableEvents();
    }
    unclampChanged(_, newValue) {
        if (newValue) {
            this.handleShowTruncateAction(this.allTextNodes);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.observer.observe(this.contents);
    }
    disconnectedCallback() {
        this.observer.disconnect();
    }
    clickTruncateHandler() {
        this.expanded = !this.expanded;
    }
    get allTextNodes() {
        return this.defaultSlot.filter((node) => node.textContent?.trim().length) ?? [this.defaultSlot[0]];
    }
    handleShowTruncateAction(textNodes) {
        if (this.unclamp) {
            return;
        }
        const rectList = [];
        const range = document?.createRange();
        textNodes.map((node) => {
            range.selectNodeContents(node);
            const rects = range?.getClientRects();
            for (let i = 0; i < rects.length; i++) {
                rectList.indexOf(rects[i].top) === -1 && rectList.push(rects[i].top);
            }
        });
        this.showTruncateAction = rectList.length > this.lineClamp;
    }
    defaultSlotChanged(_, changed) {
        changed.forEach((entry) => {
            this.shortMessage = entry.parentElement?.offsetWidth && entry.parentElement?.offsetWidth < 100 ? true : false;
            this.handleShowTruncateAction(this.allTextNodes);
        });
    }
    bindClickableEvents() {
        this.addEventListener('mousedown', this.handleMouseDown);
        this.addEventListener('keydown', this.handleKeyDown);
    }
    unbindClickableEvents() {
        this.removeEventListener('mousedown', this.handleMouseDown);
        document.removeEventListener('mouseup', this.handleMouseUp);
        this.removeEventListener('keydown', this.handleKeyDown);
    }
    handleMouseDown(e) {
        if (e.target === this) {
            this.setAttribute('data-pressed', 'true');
            document.addEventListener('mouseup', this.handleMouseUp, { once: true });
        }
        e.stopPropagation();
    }
}
__decorate([
    observable
], ChatBubbleComponent.prototype, "reactions", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "variant", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "type", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatBubbleComponent.prototype, "first", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatBubbleComponent.prototype, "clickable", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'short-message' })
], ChatBubbleComponent.prototype, "shortMessage", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'expanded' })
], ChatBubbleComponent.prototype, "expanded", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'unclamp' })
], ChatBubbleComponent.prototype, "unclamp", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatBubbleComponent.prototype, "showTruncateAction", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "seeMore", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "seeLess", void 0);
__decorate([
    observable
], ChatBubbleComponent.prototype, "defaultSlot", void 0);
