import { Button, buttonTemplate as template } from '@microsoft/fast-foundation';
import { RoundButtonComponent } from './RoundButton';
import styles from './RoundButton.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const roundButtonDefinition = {
    baseName: 'round-button',
    baseClass: Button,
    template: template,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonRoundButton = RoundButtonComponent.compose(roundButtonDefinition);
