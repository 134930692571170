import { createReactWrapper } from '../common/PrefixUtils';
import { useRef, forwardRef, useEffect, useImperativeHandle, } from 'react';
export const PopoverV2 = forwardRef((props, ref) => {
    const popoverV2Ref = useRef();
    useImperativeHandle(ref, () => popoverV2Ref.current, [popoverV2Ref.current]);
    const handleChange = (event) => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, event);
    };
    useEffect(() => {
        var _a;
        (_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener('popoverchange', handleChange);
        return () => {
            var _a;
            (_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('popoverchange', handleChange);
        };
    }, [popoverV2Ref.current, props.onChange]);
    return createReactWrapper({
        tagName: 'chameleon-popover-v2',
        props: Object.assign(Object.assign({}, props), { 'trigger-id': props.triggerId, 'is-open': props.isOpen, 'trigger-on': props.triggerOn, 'min-width': props.minWidth, 'max-width': props.maxWidth, 'z-index': props.zIndex, 'label-id': props.labelId, ref: popoverV2Ref }),
        children: props.children,
    });
});
