import { __rest } from "tslib";
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
export const ButtonLink = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return createReactWrapper({
        tagName: 'chameleon-button-link',
        props: Object.assign(Object.assign({}, props), { ref }),
        children: _jsx(_Fragment, { children: children }),
    });
});
