import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import st from 'utils/shared-translations';

interface QuoteInfoProps {
  headerString: string;
  value: string;
}

const QuoteInfo: FC<QuoteInfoProps> = ({ headerString, value }): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <Typography tag="p" variant="caption-medium" color="type-color-secondary">
        {intl.formatMessage(st[headerString])}
      </Typography>
      <Typography tag="p" variant="body-small">
        {value}
      </Typography>
    </>
  );
};

export default QuoteInfo;
