import { __rest } from "tslib";
import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const ChipV2 = forwardRef((_a, ref) => {
    var { avatar, icon, colorToken, size, labelId, closeLabel, onClose, onAction, children } = _a, props = __rest(_a, ["avatar", "icon", "colorToken", "size", "labelId", "closeLabel", "onClose", "onAction", "children"]);
    const chipRef = useRef();
    const handleCloseHandler = () => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const handleActionHandler = (event) => {
        onAction === null || onAction === void 0 ? void 0 : onAction(event);
    };
    useEffect(() => {
        var _a;
        (_a = chipRef === null || chipRef === void 0 ? void 0 : chipRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('close', handleCloseHandler);
        return () => {
            var _a;
            (_a = chipRef === null || chipRef === void 0 ? void 0 : chipRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('close', handleCloseHandler);
        };
    }, [ref, onClose]);
    useEffect(() => {
        var _a;
        (_a = chipRef === null || chipRef === void 0 ? void 0 : chipRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('chip-action', handleActionHandler);
        return () => {
            var _a;
            (_a = chipRef === null || chipRef === void 0 ? void 0 : chipRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('chip-action', handleActionHandler);
        };
    }, [ref, onAction]);
    return createReactWrapper({
        tagName: 'chameleon-chip-v2',
        children: (_jsxs(_Fragment, { children: [avatar && addExtraProps(avatar, { slot: 'avatar' }), icon && addExtraProps(icon, { slot: 'icon' }), children] })),
        props: Object.assign(Object.assign({}, props), { size, 'color-token': colorToken, 'label-id': labelId, 'close-label': closeLabel, ref: chipRef }),
    });
});
