import { NumberField, numberFieldTemplate as template } from '@microsoft/fast-foundation';
import { inputFieldDefinition } from '../InputField/InputFieldBase';
import { NumberFieldComponent } from './NumberField';
export const numberFieldDefinition = {
    ...inputFieldDefinition('number-field', template),
    baseClass: NumberField,
    shadowOptions: {
        delegatesFocus: true,
    },
    // If we were to add default stepUp and stepDown Glyphs
    // stepDownGlyph: html`<chameleon-svg>${SVG_CHEVRON_DOWN}</chameleon-svg>`,
    // stepUpGlyph: html`<chameleon-svg>${SVG_CHEVRON_UP}</chameleon-svg>`,
};
export const chameleonNumberField = NumberFieldComponent.compose(numberFieldDefinition);
