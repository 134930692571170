import { chameleonCalendar } from '../Calendar/Calendar.definition';
import { chameleonIconButton } from '../IconButton/IconButton.definition';
import { chameleonSvg } from '../Svg';
import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonDatePicker } from './DatePicker.definition';
import { chameleonTextField } from '../TextField/TextField.definition';
import { chameleonButton } from '../Button/Button.definition';
import { chameleonTypography } from '../Typography/Typography.definition';
import { chameleonDateInput } from '../DateInput/DateInput.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonTypography({ prefix }), chameleonDatePicker({ prefix }), chameleonDateInput({ prefix }), chameleonCalendar({ prefix }), chameleonIconButton({ prefix }), chameleonSvg({ prefix }), chameleonTextField({ prefix }), chameleonButton({ prefix }));
}
