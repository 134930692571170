import { useRef, useEffect } from 'react';
export function useEventListener(eventName, handler, elementRef) {
    const savedHandler = useRef();
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);
    useEffect(() => {
        const element = elementRef.current;
        const isSupported = element === null || element === void 0 ? void 0 : element.addEventListener;
        if (!isSupported)
            return;
        if (!savedHandler)
            return;
        const eventListener = (event) => { var _a; return (_a = savedHandler.current) === null || _a === void 0 ? void 0 : _a.call(savedHandler, event); };
        element.addEventListener(eventName, eventListener);
        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, elementRef]);
}
