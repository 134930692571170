import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { LoadingButton } from '../common/LoadingButton';
import { DEV } from 'esm-env';
export class IconButtonComponent extends LoadingButton {
    constructor() {
        super(...arguments);
        this.baseName = 'icon-button';
        this.size = 'medium';
        this.variant = 'primary';
        this.badge = false;
    }
    badgeChanged(_oldValue, newValue) {
        if (newValue) {
            requestAnimationFrame(() => {
                this.attachBadge();
            });
        }
        else
            this.detachBadge();
    }
    badgeLabelChanged() {
        if (this.badge) {
            requestAnimationFrame(() => {
                const badgeElement = this.shadowRoot
                    ?.querySelector('button')
                    ?.querySelector(`chameleon-badge-counter, ${this.tagFor('badge-counter')}`);
                this.setAccessibleLabelForBadge(badgeElement);
            });
        }
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    attachBadge() {
        const badgeElement = document.createElement(this.tagFor('badge-counter'));
        badgeElement.setAttribute('label', 'CHAMELEON_BYPASS_LABEL');
        // TODO: The badge sizes do not match the specifications in Figma. Refer to CHAMELEON-3174 and CHAMELEON-3175 to resolve this issue before applying the correct size here.
        badgeElement.setAttribute('size', 'small');
        this.setAccessibleLabelForBadge(badgeElement);
        this.shadowRoot?.querySelector('button')?.appendChild(badgeElement);
    }
    detachBadge() {
        this.shadowRoot
            ?.querySelector('button')
            ?.querySelector(`chameleon-badge-counter, ${this.tagFor('badge-counter')}`)
            ?.remove();
    }
    getInternalButtonElement() {
        return this.shadowRoot?.querySelector('button');
    }
    disabledChanged(oldValue, newValue) {
        super.disabledChanged(oldValue, newValue);
        requestAnimationFrame(() => {
            this.getInternalButtonElement()?.removeAttribute('disabled');
            this.getInternalButtonElement()?.setAttribute('aria-disabled', `${newValue}`);
        });
    }
    setAccessibleLabelForBadge(badge) {
        if (this.badgeLabel) {
            badge.setAttribute('aria-label', this.badgeLabel);
            return;
        }
        if (DEV) {
            this.validateAccessibleBadgeLabel(badge);
        }
    }
    setAccessibleLabel() {
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.getInternalButtonElement()?.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleBadgeLabel(badge) {
        if (!badge.getAttribute('aria-label')?.length || badge.getAttribute('aria-label') === 'CHAMELEON_BYPASS_LABEL') {
            console.error('CHAMELEON-A11Y: Icon Button component must have an accessible label for the badge indicator provided by a non-empty badgeLabel/badge-label attribute to be used as aria-label.');
        }
    }
    validateAccessibleLabel() {
        if (!this.getInternalButtonElement()?.getAttribute('aria-label')?.length &&
            !this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: Icon Button component must have an accessible label provided by either 
  1) a visible label whose id is given as the attribute labelId 
  2) a non-empty label attribute to be used as aria-label.
`);
        }
    }
    _keypressHandler(event) {
        if (this.getInternalButtonElement()?.getAttribute('aria-disabled') === 'true') {
            event.preventDefault();
        }
    }
}
__decorate([
    attr
], IconButtonComponent.prototype, "size", void 0);
__decorate([
    attr
], IconButtonComponent.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], IconButtonComponent.prototype, "badge", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], IconButtonComponent.prototype, "labelId", void 0);
__decorate([
    attr
], IconButtonComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'badge-label' })
], IconButtonComponent.prototype, "badgeLabel", void 0);
