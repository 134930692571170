import { __decorate } from "tslib";
import { ListboxOption } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { attr, volatile } from '@microsoft/fast-element';
export class OptionComponent extends ChameleonElementMixin(ListboxOption) {
    constructor() {
        super(...arguments);
        this.default = false;
    }
    get label() {
        // The highlight feature of the autocomplete adds span tags in
        // the `content` slot so we also handle elements here.
        return (this.getAttribute('label') ??
            (this.content
                .map((c) => {
                if (c.nodeType === c.ELEMENT_NODE) {
                    return c.innerText;
                }
                return c.textContent;
            })
                .join('') ||
                this.value ||
                this.text));
    }
}
__decorate([
    attr({ mode: 'boolean' })
], OptionComponent.prototype, "default", void 0);
__decorate([
    volatile
], OptionComponent.prototype, "label", null);
