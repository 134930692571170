import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BankAccountOutlinedIcon, BankCardOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Button, RadioButton, RadioGroup, Skeleton, Typography } from '@getgo/chameleon-web-react-wrapper';

import { ReactComponent as Paypal } from 'assets/Paypal.svg';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  paymentMethodsAdditionCount,
  paymentMethodsIsLoading,
  paymentMethodTypes,
  setPayNowIntent,
} from 'modules/payment-methods';
import Track, { PaynowContinueAddNewMethod } from 'modules/tracking';
import {
  PARTNER_TYPES,
  PAYMENT_METHOD_TYPES,
  PAYMENT_METHODS_TYPES,
  PAYMENT_METHODS_TYPES_URL,
  SEARCH_PARAMS,
} from 'utils/constants';
import st from 'utils/shared-translations';

import './payment-method-options.css';

const PaymentMethodOptions: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pathName = window.location.pathname;

  const isPaymentMethodAdd = pathName.includes('paymentmethod/add');

  const selectedPaymentMethodsIsLoading = useAppSelector(paymentMethodsIsLoading);

  const partnerType = localStorage.getItem(SEARCH_PARAMS.partnerType);

  const [paymentMethod, setPaymentMethod] = useState('');

  const selectedPaymentTypes = useAppSelector(paymentMethodTypes);
  const selectedPaymentMethodsAdditionCount = useAppSelector(paymentMethodsAdditionCount);

  const isAddPayment = (paymentMethodType: keyof typeof PAYMENT_METHOD_TYPES) =>
    selectedPaymentTypes.some((type) => PAYMENT_METHOD_TYPES[paymentMethodType].includes(type)) &&
    !!selectedPaymentMethodsAdditionCount[paymentMethodType];

  const handleContinue = () => {
    Track(PaynowContinueAddNewMethod, { paymentType: paymentMethod });
    if (isPaymentMethodAdd) {
      const newPath = pathName.replace('/add', `/${PAYMENT_METHODS_TYPES_URL[paymentMethod]}`);
      navigate(newPath);
    } else dispatch(setPayNowIntent(paymentMethod));
  };

  const handlePaymentType = (event: any) => {
    const { value } = event.target;
    setPaymentMethod(value);
  };

  return (
    <>
      {selectedPaymentMethodsIsLoading ? (
        <Skeleton
          size="xxxlarge"
          className="payment-method-options__skeleton"
          data-testid="payment-method-options__skeleton"
        />
      ) : (
        <div data-testid="payment-method-options">
          <Typography tag="h1" variant="heading-medium">
            {intl.formatMessage(st['payment.methods.header'])}
          </Typography>
          <RadioGroup
            className="payment-method-options"
            aria-label="Payment method options"
            value={paymentMethod}
            onChange={handlePaymentType}
            data-testid="payment-method-options__radio-group"
          >
            {isAddPayment(PAYMENT_METHODS_TYPES.cc) && partnerType !== PARTNER_TYPES.parent && (
              <RadioButton
                className="payment-method-options__radio"
                value={PAYMENT_METHODS_TYPES.cc}
                aria-label="Credit card"
                data-testid="payment-method-options__cc"
              >
                <div className="payment-method-options__type">
                  <BankCardOutlinedIcon size="24px" />
                  <Typography tag="h3" variant="body-small-strong">
                    {intl.formatMessage(st['payment.credit.debit.card'])}
                  </Typography>
                </div>
              </RadioButton>
            )}
            {isAddPayment(PAYMENT_METHODS_TYPES.dd) && partnerType !== PARTNER_TYPES.parent && (
              <RadioButton
                className="payment-method-options__radio"
                value={PAYMENT_METHODS_TYPES.dd}
                aria-label="Direct debit"
                data-testid="payment-method-options__dd"
              >
                <div className="payment-method-options__type">
                  <BankAccountOutlinedIcon size="24px" />
                  <Typography tag="h3" variant="body-small-strong">
                    {intl.formatMessage(st['payment.direct.debit.sepa'])}
                  </Typography>
                </div>
              </RadioButton>
            )}
            {isAddPayment(PAYMENT_METHODS_TYPES.ach) && partnerType !== PARTNER_TYPES.parent && (
              <RadioButton
                className="payment-method-options__radio"
                value={PAYMENT_METHODS_TYPES.ach}
                aria-label="ACH"
                data-testid="payment-method-options__ach"
              >
                <div className="payment-method-options__type">
                  <BankAccountOutlinedIcon size="24px" />
                  <Typography tag="h3" variant="body-small-strong">
                    {intl.formatMessage(st['payment.direct.debit.ach'])}
                  </Typography>
                </div>
              </RadioButton>
            )}
            {isAddPayment(PAYMENT_METHODS_TYPES.paypal) && partnerType !== PARTNER_TYPES.parent && (
              <RadioButton
                className="payment-method-options__radio"
                value={PAYMENT_METHODS_TYPES.paypal}
                aria-label="PayPal"
                data-testid="payment-method-options__paypal"
              >
                <div className="payment-method-options__type">
                  <Paypal />
                  <Typography tag="h3" variant="body-small-strong">
                    {intl.formatMessage(st['payment.paypal'])}
                  </Typography>
                </div>
              </RadioButton>
            )}
          </RadioGroup>

          <Button
            data-testid="payment-method-options__continue"
            disabled={!paymentMethod}
            size="large"
            fullWidth
            onClick={handleContinue}
            aria-label="Continue"
          >
            {intl.formatMessage(st['payment.methods.continue'])}
          </Button>
        </div>
      )}
    </>
  );
};

export default PaymentMethodOptions;
