import { __decorate } from "tslib";
import { SVG_CLOSE_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { AlertBase } from '../common/AlertBase';
import { ButtonComponent, IconButtonComponent, SvgComponent, TypographyComponent } from '..';
const ACTION_BUTTON_DEFAULT_LABEL = 'Action';
const BRIGHT_BANNER_VARIANTS = ['marketing'];
export const bannerTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    const buttonTag = context.tagFor(ButtonComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    return html `<template role="alert" has-action="${(x) => x.hasAction}" variant="${(x) => x.variant}">
    <div class="banner-main-content" data-closeable="${(x) => x.closable}" data-multiline="${(x) => x.multiline}">
      <${typographyTag}
        color="${(x) => x.variant && BRIGHT_BANNER_VARIANTS.includes(x.variant) ? 'surface-color-persistent' : 'type-color-inverted'}"
      >
        <slot name="icon" ${slotted('iconSlot')}></slot>
      </${typographyTag}>
      <${typographyTag}
        color="${(x) => x.variant && BRIGHT_BANNER_VARIANTS.includes(x.variant) ? 'surface-color-persistent' : 'type-color-inverted'}"
      >
        <slot></slot>
      </${typographyTag}>
      <slot name="action" ${slotted('actionSlot')}></slot>
    </div>
    ${when((x) => x.actionContent && !x.multiline, html `<${buttonTag}
        name="action-button-mobile"
        variant="${(x) => (x.variant === 'marketing' ? 'neutral' : x.variant === 'neutral' ? 'primary' : x.variant)}"
        size="small"
        aria-label="${ACTION_BUTTON_DEFAULT_LABEL}"
        @click=${(x) => x.actionClick()}
      >
        <${typographyTag} color="${(x) => (x.variant === 'marketing' ? 'inherit' : 'type-color-inverted')}"
          >${(x) => (x.actionContent ? x.actionContent : 'Action')}</${typographyTag}
        >
      </${buttonTag}>`)}
    ${when((x) => x.multiline, html `<div class="banner-actions" data-display-dismiss="${(x) => x.displayDismiss}">
        <slot name="dismiss" part="dismiss" ${slotted('dismissSlot')}>
          <${buttonTag}
            class="dismiss-button"
            variant="${(x) => (x.variant === 'marketing' ? 'neutral' : x.variant === 'neutral' ? 'primary' : x.variant)}"
            size="small"
            aria-label="${(x) => x.closeLabel}"
            @click=${(x) => x.close()}
          >
            <${typographyTag}
              variant="button-small"
              color="${(x) => (x.variant === 'marketing' ? 'inherit' : 'type-color-inverted')}"
            >
              <slot name="dismisstext" ${slotted('dismissText')}></slot>
            </${typographyTag}>
          </${buttonTag}>
        </slot>
        <${buttonTag}
          variant="${(x) => (x.variant === 'marketing' ? 'neutral' : x.variant === 'neutral' ? 'primary' : x.variant)}"
          size="small"
          aria-label="${ACTION_BUTTON_DEFAULT_LABEL}"
          @click=${(x) => x.actionClick()}
        >
          <${typographyTag}
            variant="button-small"
            color="${(x) => (x.variant === 'marketing' ? 'inherit' : 'type-color-inverted')}"
            >${(x) => (x.actionContent ? x.actionContent : 'Action')}</${typographyTag}
          >
        </${buttonTag}>
      </div>`)}
    ${when((x) => x.closable && !x.multiline, html `
        <${iconButtonTag}
          variant="primary"
          size="small"
          aria-label="${(x) => x.closeLabel}"
          @click=${(x) => x.close()}
        >
          <${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}>
        </${iconButtonTag}>
      `)}
  </template>`;
};
export class BannerComponent extends AlertBase {
    constructor() {
        super(...arguments);
        this.variant = 'neutral';
        this.actionSlot = [];
        this.iconSlot = [];
        this.dismissSlot = [];
        this.dismissText = [];
    }
    actionSlotChanged(_, changed) {
        this.actionContent = changed[0]?.textContent ?? '';
        this.hasAction = !!changed[0]?.textContent;
    }
    iconSlotChanged(_, changed) {
        if (changed.length > 0) {
            this.iconContent = changed[0];
            this.iconContent.style.setProperty('--goto-icon-size', '20px');
        }
    }
    dismissTextChanged(_, changed) {
        this.displayDismiss = changed.length > 0;
    }
    actionClick() {
        const anchorTag = this.actionSlot[0];
        const href = anchorTag?.href;
        const target = anchorTag?.target;
        if (href) {
            window.open(href, target);
        }
        else {
            anchorTag.click();
        }
        this.$emit('action-click', null, { bubbles: false });
    }
}
__decorate([
    attr({ mode: 'boolean' })
], BannerComponent.prototype, "multiline", void 0);
__decorate([
    attr()
], BannerComponent.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'has-action' })
], BannerComponent.prototype, "hasAction", void 0);
__decorate([
    attr({ mode: 'boolean' })
], BannerComponent.prototype, "displayDismiss", void 0);
__decorate([
    observable
], BannerComponent.prototype, "actionContent", void 0);
__decorate([
    observable
], BannerComponent.prototype, "dismissContent", void 0);
__decorate([
    observable
], BannerComponent.prototype, "iconContent", void 0);
__decorate([
    observable
], BannerComponent.prototype, "actionSlot", void 0);
__decorate([
    observable
], BannerComponent.prototype, "iconSlot", void 0);
__decorate([
    observable
], BannerComponent.prototype, "dismissSlot", void 0);
__decorate([
    observable
], BannerComponent.prototype, "dismissText", void 0);
