import { __decorate } from "tslib";
import { FoundationElement } from '@microsoft/fast-foundation';
import { attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { TypographyComponent } from '../Typography';
import { SVG_CHEVRON_DOWN_FILLED, SVG_CHEVRON_UP_FILLED, SVG_DRAG_FREE } from '@getgo/chameleon-icons';
export const widgetTemplate = (context) => {
    const typographyTag = context.tagFor(TypographyComponent);
    return html `
    <template>
      <div class="header" part="header">
        <div class="title-container">
          <slot slot="icon" name="icon"></slot>
          <${typographyTag} variant="body-medium-strong">${(x) => x.headTitle}</${typographyTag}>
        </div>
        <div class="actions">
          <div part="actions">
            <slot name="actions"></slot>
          </div>
          <div class="built-in-actions" part="built-in-actions">
            <slot slot="drag-button" name="drag-button" ${slotted('dragButtonSlottedNode')}></slot>
            <slot slot="collapse-button" name="collapse-button" ${slotted('collapseButtonSlottedNode')}></slot>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="body" part="body"><slot></slot></div>
        ${when((x) => x.state === 'coming-soon', () => html `
            <div class="coming-soon-overlay">
              <div class="coming-soon-overlay-content">
                <slot slot="overlay-content" name="overlay-content" ${slotted('overlayContentSlottedNode')}></slot>
              </div>
              <div class="coming-soon-overlay-background"></div>
            </div>
          `)}
      </div>
    </template>
  `;
};
export class WidgetComponent extends ChameleonPrefixedElementMixin(FoundationElement, 'widget') {
    constructor() {
        super(...arguments);
        this.svgTag = this.tagFor('svg');
        this.headTitle = '';
        this.dragTooltip = '';
        this.resizable = true;
        this.state = 'default';
        this.collapsed = false;
        this.heightObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry) {
                    const header = this.shadowRoot?.querySelector('.header');
                    const content = this.shadowRoot?.querySelector('.content');
                    if (entry.contentRect.height < 50) {
                        header.style.borderBottom = '1px solid var(--goto-border-color-inverted)';
                        header.style.borderRadius = '4px';
                        if (this.resizable && this.style.height) {
                            content.hidden = true;
                        }
                        this.style.overflow = 'hidden';
                    }
                    else {
                        this.style.overflow = 'auto';
                        header.style.borderBottom = 'none';
                        if (this.resizable && this.style.height) {
                            content.hidden = false;
                        }
                        header.style.borderRadius = '4px 4px 0 0';
                    }
                }
            }
        });
    }
    collapsedChanged(_, newValue) {
        const collapseButtonContainer = this.querySelector('[slot="collapse-button"]');
        if (collapseButtonContainer) {
            const iconButton = collapseButtonContainer.childNodes.item(Array.from(collapseButtonContainer.childNodes).findIndex((n) => n.baseName === 'icon-button'));
            if (iconButton) {
                iconButton.innerHTML =
                    `<${this.svgTag}>${newValue ? SVG_CHEVRON_DOWN_FILLED : SVG_CHEVRON_UP_FILLED}</${this.svgTag}>`;
            }
        }
    }
    dragButtonSlottedNodeChanged(_, newValue) {
        if (newValue.length > 0) {
            newValue.forEach((node) => {
                const iconButton = node.childNodes.item(Array.from(node.childNodes).findIndex((n) => n.baseName === 'icon-button'));
                if (iconButton) {
                    iconButton.size = 'small';
                    iconButton.innerHTML = `<${this.svgTag}>${SVG_DRAG_FREE}</${this.svgTag}>`;
                }
            });
        }
    }
    collapseButtonSlottedNodeChanged(_, newValue) {
        if (newValue.length > 0) {
            newValue.forEach((node) => {
                const iconButton = node.childNodes.item(Array.from(node.childNodes).findIndex((n) => n.baseName === 'icon-button'));
                if (iconButton) {
                    iconButton.size = 'small';
                    iconButton.innerHTML =
                        `<${this.svgTag}>${this.collapsed ? SVG_CHEVRON_DOWN_FILLED : SVG_CHEVRON_UP_FILLED}</${this.svgTag}>`;
                    iconButton.classList.add('widget-collapse-button');
                }
            });
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.heightObserver.observe(this);
    }
    disconnectedCallback() {
        this.heightObserver.disconnect();
    }
}
__decorate([
    attr({ attribute: 'head-title' })
], WidgetComponent.prototype, "headTitle", void 0);
__decorate([
    attr({ attribute: 'drag-tooltip' })
], WidgetComponent.prototype, "dragTooltip", void 0);
__decorate([
    attr({ mode: 'boolean' })
], WidgetComponent.prototype, "resizable", void 0);
__decorate([
    attr()
], WidgetComponent.prototype, "state", void 0);
__decorate([
    attr({ mode: 'boolean' })
], WidgetComponent.prototype, "collapsed", void 0);
__decorate([
    observable
], WidgetComponent.prototype, "icon", void 0);
__decorate([
    observable
], WidgetComponent.prototype, "overlayContentSlottedNode", void 0);
__decorate([
    observable
], WidgetComponent.prototype, "dragButtonSlottedNode", void 0);
__decorate([
    observable
], WidgetComponent.prototype, "collapseButtonSlottedNode", void 0);
