import { __decorate } from "tslib";
import { attr, html, when, observable, ref } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { SVG_ALERT_OUTLINED, SVG_CALENDAR_OUTLINED, SVG_CHECKMARK_OUTLINED } from '@getgo/chameleon-icons';
import { DateInputComponent } from '../DateInput';
import { TypographyComponent } from '../Typography';
import { IconButtonComponent } from '../IconButton';
import { SvgComponent } from '../Svg';
import { PopoverV2Component } from '../PopoverV2';
import { getLocalTimeZone, parseDate } from '@internationalized/date';
import { RangeCalendarComponent } from '../RangeCalendar';
export const dateRangePickerTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    const popoverV2Tag = context.tagFor(PopoverV2Component);
    const rangeCalendarTag = context.tagFor(RangeCalendarComponent);
    const dateInputTag = context.tagFor(DateInputComponent);
    return html `<template>
    ${when((x) => x.inputLabel, html `<${typographyTag} class="input-label" variant="caption-medium-strong" color="type-color-default">
      ${(x) => x.inputLabel}
    </${typographyTag}>`)}
    <div class="control" ${ref('control')} tabindex="-1">
      <slot name="start"></slot>
      <div style="flex-grow: 1; place-items: center; gap: 8px;">
        <${dateInputTag} value=${(c) => c.start} @change=${(component, ctx) => {
        ctx.event.stopPropagation();
        component.start = ctx.event.target.value;
    }} locale=${(c) => c.locale} timezone=${(c) => c.timezone} fieldsize=${(c) => c.fieldsize} @blur=${(x) => {
        if ((x.start ?? '') > (x.end ?? '')) {
            x.end = undefined;
        }
    }}></${dateInputTag}>
        <slot name="middle"><${typographyTag} variant="body-medium" color="type-color-secondary" aria-hidden="true">–</${typographyTag}></slot>
        <${dateInputTag} fieldsize=${(c) => c.fieldsize} value=${(c) => c.end} @change=${(component, ctx) => {
        ctx.event.stopPropagation();
        component.end = ctx.event.target.value;
    }} locale=${(c) => c.locale} timezone=${(c) => c.timezone} fieldsize=${(c) => c.fieldsize}></${dateInputTag}>
      </div>
      <slot name="end">
        ${when((x) => x.error || x.rangeError, html `<chameleon-svg>${SVG_ALERT_OUTLINED}</chameleon-svg>`)}
        ${when((x) => x.success, html `<chameleon-svg>${SVG_CHECKMARK_OUTLINED}</chameleon-svg>`)}
      </slot>
      <${iconButtonTag} id="calendar-button" label="${(x) => x.calendarToggleLabel}">
        <${svgTag}>${SVG_CALENDAR_OUTLINED}</${svgTag}>
      </${iconButtonTag}>
    <${popoverV2Tag} trigger-id="calendar-button" ${ref('popoverElement')} compact>
      <${rangeCalendarTag} locale="${(c) => c.locale}" timezone=${(c) => c.timezone} min=${(c) => c.min} max=${(c) => c.max} start="${(c) => c.start}" end="${(c) => c.end}" @ch-range-calendar-change=${(component, context) => {
        const { start, end } = context.event.detail;
        if (start && end) {
            component.start = start;
            component.end = end;
            // @Varazdat Ghararyan
            // https://www.figma.com/design/njWYc0BlpEVcNpoHUD8eRP?node-id=6978-44765#992279035
            // We should keep it always open, especially when it's a range selection.
            // User may make a mistake and will try to update/change the selected range before closing it
        }
    }}>
            <slot slot="header" name="header"></slot></${rangeCalendarTag}>
      </div>
      </${popoverV2Tag}>
    </div>
    ${when((x) => x.helperText, html `<${typographyTag} id="text" class="helpertext" variant="caption-medium" color="type-color-secondary">${(x) => x.helperText}</${typographyTag}>`)}
</template>`;
};
export class DateRangePickerComponent extends ChameleonElementMixin(FoundationElement) {
    startChanged(_prev, _next) {
        this.startEndChangedCallback();
    }
    endChanged(_prev, _next) {
        this.startEndChangedCallback();
    }
    reportValidity() {
        return this.internals.reportValidity();
    }
    get validationMessage() {
        return this.internals.validationMessage;
    }
    checkValidity() {
        return this.internals.checkValidity();
    }
    get validity() {
        return this.internals.validity;
    }
    willValidate() {
        return this.internals.willValidate;
    }
    inputLabelChanged() {
        this.internals.ariaLabel = this.inputLabel ?? null;
    }
    constructor() {
        super();
        this.calendarToggleLabel = '';
        this.timezone = getLocalTimeZone();
        this.locale = navigator.language;
        this.rangeError = false;
        this.start = '';
        this.end = '';
        this.min = '';
        this.max = '';
        // This is used to get the default browser validation messages for certain invalid states.
        this.validationInput = document.createElement('input');
        this.internals = this.attachInternals();
    }
    connectedCallback() {
        super.connectedCallback();
        this.updateRequiredValidity();
    }
    updateRequiredValidity() {
        if (this.isConnected) {
            const isRequired = this.hasAttribute('required');
            this.validationInput.toggleAttribute('required', isRequired);
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ customError: isRequired && (!this.start || !this.end) }, message, this.control);
        }
    }
    startEndChangedCallback() {
        if (!this.isConnected) {
            return;
        }
        if (this.start && this.end) {
            const start = parseDate(this.start);
            const end = parseDate(this.end);
            this.rangeError = end < start;
            this.internals.setFormValue(`${this.start}/${this.end}`);
            this.$emit('change', { start: this.start, end: this.end });
        }
        else {
            this.rangeError = false;
            this.internals.setFormValue('');
        }
        this.updateRequiredValidity();
    }
}
DateRangePickerComponent.formAssociated = true;
__decorate([
    attr({ attribute: 'input-label' })
], DateRangePickerComponent.prototype, "inputLabel", void 0);
__decorate([
    attr({ attribute: 'helper-text' })
], DateRangePickerComponent.prototype, "helperText", void 0);
__decorate([
    attr
], DateRangePickerComponent.prototype, "fieldsize", void 0);
__decorate([
    attr({ attribute: 'calendar-toggle-label' })
], DateRangePickerComponent.prototype, "calendarToggleLabel", void 0);
__decorate([
    attr
], DateRangePickerComponent.prototype, "timezone", void 0);
__decorate([
    attr
], DateRangePickerComponent.prototype, "locale", void 0);
__decorate([
    observable
], DateRangePickerComponent.prototype, "internalCalendarStart", void 0);
__decorate([
    observable
], DateRangePickerComponent.prototype, "internalCalendarEnd", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DateRangePickerComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DateRangePickerComponent.prototype, "success", void 0);
__decorate([
    observable
], DateRangePickerComponent.prototype, "rangeError", void 0);
__decorate([
    observable
], DateRangePickerComponent.prototype, "popoverElement", void 0);
__decorate([
    attr()
], DateRangePickerComponent.prototype, "start", void 0);
__decorate([
    attr()
], DateRangePickerComponent.prototype, "end", void 0);
__decorate([
    attr()
], DateRangePickerComponent.prototype, "min", void 0);
__decorate([
    attr()
], DateRangePickerComponent.prototype, "max", void 0);
__decorate([
    observable
], DateRangePickerComponent.prototype, "control", void 0);
