import { TooltipV3Component } from '../../TooltipV3';
import { withStylePrefix } from '../../common/withStylePrefix';
import { BaseNavigationRailLinkComponent } from '../BaseNavigationRailLink/BaseNavigationRailLink';
import { NavigationRailLinkComponent } from './NavigationRailLink';
import styles from './NavigationRailLink.styles.scss';
import { html, slotted } from '@microsoft/fast-element';
export const chameleonNavigationRailLinkTemplate = (context, _definition) => {
    const tooltipV3Tag = context.tagFor(TooltipV3Component);
    const baseNavigationRailLinkTag = context.tagFor(BaseNavigationRailLinkComponent);
    return html `
    <template
      @keydown="${(x, c) => x.handleMenuKeyDown(c.event)}"
      @click="${(x, c) => x.handleItemClick(c.event)}"
      @mouseover="${(x, c) => x.handleMouseOver(c.event)}"
      @mouseout="${(x, c) => x.handleMouseOut(c.event)}"
      role="menuitem"
    >
      <${tooltipV3Tag}
        trigger-id="${(x) => (x.size === 'small' ? 'tooltip-trigger' : '')}"
        position="right-center"
        z-index="100"
        delay="0"
        tabindex="-1"
        style="--additional-offset-x: 8px;"
      >
      </${tooltipV3Tag}>
      <${baseNavigationRailLinkTag}
        id="tooltip-trigger"
        slot="trigger"
        :selected=${(x) => x.selected}
        :disabled=${(x) => x.disabled}
        :sticky=${(x) => x.sticky}
        :collapse=${(x) => x.collapse}
        :size=${(x) => x.size}
        :href=${(x) => x.href}
        :target=${(x) => x.target}
        :appearance=${(x) => x.appearance}
      >
        <slot ${slotted('defaultSlottedNodes')}></slot>
        <slot slot="start" name="start"></slot>
        <slot slot="end" name="end" ${slotted('endSlottedNodes')}></slot>
      </${baseNavigationRailLinkTag}>
    </template>
  `;
};
export const chameleonNavigationRailLinkDefinition = {
    baseName: 'navigation-rail-link',
    template: chameleonNavigationRailLinkTemplate,
    styles: withStylePrefix(styles),
};
export const chameleonNavigationRailLink = NavigationRailLinkComponent.compose(chameleonNavigationRailLinkDefinition);
