import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
export const avatarGroupTemplate = html `<slot></slot>`;
const DEFAULT_MAX_AVATARS = 4;
const DEFAULT_SIZE = 'medium';
export class AvatarGroupComponent extends ChameleonPrefixedElementMixin(FoundationElement, 'avatar-group') {
    constructor() {
        super(...arguments);
        this.max = DEFAULT_MAX_AVATARS;
        this.size = DEFAULT_SIZE;
    }
    connectedCallback() {
        super.connectedCallback();
        this.render();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        this.render();
    }
    getAvatars() {
        return this.querySelectorAll(`chameleon-avatar-v2, ${this.tagFor('avatar-v2')}`);
    }
    getOldAvatars() {
        return this.querySelectorAll(`chameleon-avatar, ${this.tagFor('avatar')}`);
    }
    /**
     * HOTFIX : Migration path for Admin, use chameleon-tooltip-v3 instead.
     */
    getOldChildren() {
        const oldAvatarTag = this.tagFor('avatar');
        return this.querySelectorAll(`:scope > chameleon-avatar:not([hint]), :scope > ${oldAvatarTag}:not([hint]), :scope > chameleon-tooltip-v2`);
    }
    render() {
        this.querySelector('[hint]')?.remove();
        const max = this.hasAttribute('max') ? +(this.getAttribute('max') ?? DEFAULT_MAX_AVATARS) : this.max;
        const size = this.hasAttribute('size') ? (this.getAttribute('size') ?? DEFAULT_SIZE) : this.size;
        const avatars = this.getAvatars();
        const oldAvatars = this.getOldAvatars();
        avatars.forEach((avatar) => {
            avatar.setAttribute('size', size);
        });
        oldAvatars.forEach((avatar) => {
            avatar.setAttribute('size', size);
        });
        if (avatars?.length > max) {
            avatars.forEach((item) => (item.hidden = false));
            const diff = this.getAvatars()?.length - max + 1;
            const itemsToRemove = [...avatars].slice(max - 1);
            itemsToRemove.forEach((item) => item.setAttribute('hidden', ''));
            if (diff > 0) {
                const avatar = document.createElement(this.tagFor('avatar-v2'));
                avatar.setAttribute('size', size);
                avatar.setAttribute('hint', '');
                avatar.textContent = `+${diff}`;
                avatar.setAttribute('label', `Avatars`);
                this.appendChild(avatar);
            }
        }
        const oldChildren = this.getOldChildren();
        if (oldChildren?.length > max) {
            oldChildren.forEach((item) => item.style.removeProperty('display'));
            const diff = oldChildren?.length - max;
            const itemsToRemove = [...oldChildren].slice(diff > 0 ? max - 1 : max);
            itemsToRemove.forEach((item) => (item.style.display = 'none'));
            if (diff > 0) {
                const avatar = document.createElement(this.tagFor('avatar'));
                avatar.setAttribute('hint', '');
                avatar.setAttribute('size', size);
                avatar.textContent = `+${diff + 1}`;
                this.appendChild(avatar);
            }
        }
    }
}
__decorate([
    attr
], AvatarGroupComponent.prototype, "max", void 0);
__decorate([
    attr
], AvatarGroupComponent.prototype, "size", void 0);
