import { __decorate } from "tslib";
import { attr, observable } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { NavigationRailComponent } from '../NavigationRail';
import { MenuItem } from '@microsoft/fast-foundation';
// TODO PREFIXING
export class NavigationRailItemComponent extends ChameleonElementMixin(MenuItem) {
    constructor() {
        super(...arguments);
        /*
         *   @deprecated
         *   Will be removed in upcoming major update
         * */
        this.size = 'small';
        this.state = 'expanded';
        this.disabled = false;
        this.appearance = 'navrailitem';
        this.wrapperElement = null;
    }
    stateChanged() {
        this.assignText();
    }
    selectedChanged(_, newValue) {
        if (newValue) {
            this.setAttribute('aria-current', 'page');
        }
        else {
            this.removeAttribute('aria-current');
        }
    }
    attributeChangedCallback(propertyName, oldValue, newValue) {
        super.attributeChangedCallback(propertyName, oldValue, newValue);
        this.assignText();
    }
    connectedCallback() {
        super.connectedCallback();
        this.toggleSlots();
        this.wrapperElement = this.parentNode;
        if (this.wrapperElement instanceof NavigationRailComponent) {
            this.size = this.wrapperElement?.size;
            this.state = this.wrapperElement?.state;
        }
        this.tooltipElement = this.shadowRoot?.querySelector('chameleon-tooltip-v3');
    }
    /* 'defaultNode' is the text, what the user typed in to the component
     * and we assign it to the content slot of the tooltip v3
     */
    defaultSlottedNodesChanged() {
        this.assignText();
        this.toggleSlots();
    }
    assignText() {
        if (this.defaultSlottedNodes && this.defaultSlottedNodes.length > 0) {
            const textNodes = this.defaultSlottedNodes.filter((defaultNode) => defaultNode.nodeName === '#text');
            const trueTextNode = textNodes
                .filter((textNode) => textNode.textContent)
                .map((textNode) => textNode.textContent)
                .join('');
            if (this.tooltipElement.textContent && !!trueTextNode) {
                this.tooltipElement.textContent = trueTextNode;
            }
        }
    }
    toggleSlots() {
        if (!this.querySelector('[slot="start"]')) {
            this.style.setProperty('--start-slot-display', 'none');
        }
        else {
            this.style.setProperty('--start-slot-display', 'contents');
        }
        if (!this.querySelector('[slot="end"]')) {
            this.shadowRoot?.querySelector('chameleon-base-navigation-rail-item')?.removeAttribute('data-has-end-slot');
            this.style.setProperty('--end-slot-display', 'none');
        }
        else {
            this.shadowRoot?.querySelector('chameleon-base-navigation-rail-item')?.setAttribute('data-has-end-slot', '');
            this.style.setProperty('--end-slot-display', 'contents');
        }
    }
    endSlottedNodesChanged() {
        this.setBadgeCounter();
    }
    setBadgeCounter() {
        if (this.endSlottedNodes && this.endSlottedNodes.length > 0) {
            const textNodes = this.endSlottedNodes[0];
            if (this.endSlottedNodes[0] instanceof Element) {
                textNodes.setAttribute('size', 'small');
            }
        }
    }
}
__decorate([
    attr
], NavigationRailItemComponent.prototype, "size", void 0);
__decorate([
    attr
], NavigationRailItemComponent.prototype, "state", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "sticky", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "collapse", void 0);
__decorate([
    attr
], NavigationRailItemComponent.prototype, "appearance", void 0);
__decorate([
    observable
], NavigationRailItemComponent.prototype, "defaultSlottedNodes", void 0);
__decorate([
    observable
], NavigationRailItemComponent.prototype, "endSlottedNodes", void 0);
