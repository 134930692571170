import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const skeletonTemplate = html `<template
  :style="${(x) => (x.variant === 'rectangle' ? `width: ${x.computedWidth()};` : ``)}"
></template>`;
export class SkeletonComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.variant = 'square';
        this.width = null;
    }
    computedWidth() {
        const defaultWidth = this.width ?? '100%';
        return this.width && Number.isInteger(Number(this.width)) ? `${this.width}px` : defaultWidth;
    }
}
__decorate([
    attr
], SkeletonComponent.prototype, "variant", void 0);
__decorate([
    attr
], SkeletonComponent.prototype, "size", void 0);
__decorate([
    attr
], SkeletonComponent.prototype, "width", void 0);
