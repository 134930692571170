import { __decorate } from "tslib";
import { FoundationElement } from '@microsoft/fast-foundation';
import { attr, html } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { TooltipV2Component } from '../../TooltipV2';
export const interactiveSubItemTemplate = (context, _definition) => {
    const tooltipV2Tag = context.tagFor(TooltipV2Component);
    return html `<template tabindex="-1" aria-label=${(x) => x.label} id=${(x) => x.uniqueId}
    ><${tooltipV2Tag}
      ><div slot="trigger">
        <slot tabindex="-1"></slot>
      </div>
      <span slot="content">${(x) => x.label}</span>
    </${tooltipV2Tag}>
    </template>`;
};
export class InteractiveSubItemComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.label = '';
        this.uniqueId = uniqueId('interactive-sub-item-');
        this.handleKeyDown = (event) => {
            const { key } = event;
            const selectedChildInteractiveItem = this.firstElementChild;
            if (selectedChildInteractiveItem &&
                selectedChildInteractiveItem instanceof HTMLElement &&
                (key === 'Enter' || key === ' ')) {
                selectedChildInteractiveItem.click();
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
        this.$emit('interactive-item-added');
    }
    disconnectedCallback() {
        this.removeEventListener('keydown', this.handleKeyDown);
    }
}
__decorate([
    attr
], InteractiveSubItemComponent.prototype, "label", void 0);
__decorate([
    attr
], InteractiveSubItemComponent.prototype, "uniqueId", void 0);
