import { Breadcrumb } from '@microsoft/fast-components';
import styles from './Breadcrumb.styles.scss';
import { BreadcrumbComponent, breadcrumbTemplate } from './Breadcrumb';
import { withStylePrefix } from '../common/withStylePrefix';
export const breadcrumbDefinition = {
    baseName: 'breadcrumb',
    baseClass: Breadcrumb,
    template: breadcrumbTemplate,
    styles: withStylePrefix(styles),
};
export const chameleonBreadcrumb = BreadcrumbComponent.compose(breadcrumbDefinition);
