import { __decorate } from "tslib";
import { attr, DOM, html, observable, slotted, when } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { SVG_ALERT_OUTLINED, SVG_CLOSE_OUTLINED, SVG_PERSON_OUTLINED } from '@getgo/chameleon-icons';
import { FoundationElement } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { DEV } from 'esm-env';
import { SvgComponent } from '../Svg';
import { RoundButtonComponent } from '../RoundButton';
export const chipV2Template = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    const roundButtonTag = context.tagFor(RoundButtonComponent);
    return html `${when((x) => x.type === 'default', html `<template>
      ${when((x) => x.person && !x.error, () => html `<${svgTag} class="person-icon">${SVG_PERSON_OUTLINED}</${svgTag}>`)}
      ${when((x) => !x.person && !x.error, () => html `<slot name="icon"></slot>`)}
      ${when((x) => x.error, () => html `<${svgTag} class="error-icon">${SVG_ALERT_OUTLINED}</${svgTag}>`)}
      <slot ${slotted('slottedAvatar')} name="avatar"></slot>
      <span class="content" part="content"><slot></slot></span>
      ${when((x) => x.closable, () => html `<${roundButtonTag}
            variant="tertiary"
            @click=${(x, { event }) => x.close(event)}
            size="${(x) => x.closeButtonSize()}"
            label="${(x) => x.closeLabel}"
            ><${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}></${roundButtonTag}
          >`)}
    </template>`)}
  ${when((x) => x.type === 'button', html `<button type="button" class="button-chip" @click=${(x) => x.action()}>
      ${when((x) => x.person && !x.error, () => html `<${svgTag} class="person-icon">${SVG_PERSON_OUTLINED}</${svgTag}>`)}
      ${when((x) => !x.person && !x.error, () => html `<slot name="icon"></slot>`)}
      ${when((x) => x.error, () => html `<${svgTag} class="error-icon">${SVG_ALERT_OUTLINED}</${svgTag}>`)}
      <slot ${slotted('slottedAvatar')} name="avatar"></slot>
      <span class="content" part="content"><slot></slot></span>
      ${when((x) => x.closable, () => html `<${roundButtonTag}
            variant="tertiary"
            @click=${(x, { event }) => x.close(event)}
            size="${(x) => x.closeButtonSize()}"
            label="${(x) => x.closeLabel}"
            ><${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}></${roundButtonTag}
          >`)}
    </button>`)}
  ${when((x) => x.type === 'checkbox' || x.type === 'radio', html `<label
        for="${(x) => x.uniqueId}"
        @click=${(x) => {
        if (x.type === 'radio' && !x.disabled) {
            x.active = true;
        }
    }}
      >
        <input
          id="${(x) => x.uniqueId}"
          type="${(x) => x.type}"
          aria-label="${(x) => x.textContent}"
          checked="${(x) => x.active}"
          aria-checked="${(x) => x.active}"
          name="${(x) => x.name}"
          value="${(x) => x.value}"
          @click=${(x) => {
        if (!x.disabled) {
            x.active = x.type === 'checkbox' ? !x.active : true;
            x.action();
        }
    }}
        />
      </label>
      ${when((x) => x.person && !x.error, () => html `<${svgTag} class="person-icon">${SVG_PERSON_OUTLINED}</${svgTag}>`)}
      ${when((x) => !x.person && !x.error, () => html `<slot name="icon"></slot>`)}
      ${when((x) => x.error, () => html `<${svgTag} class="error-icon">${SVG_ALERT_OUTLINED}</${svgTag}>`)}
      <slot ${slotted('slottedAvatar')} name="avatar"></slot>
      <span class="content" part="content"><slot></slot></span>
      ${when((x) => x.closable, () => html `<${roundButtonTag}
            variant="tertiary"
            @click=${(x, { event }) => x.close(event)}
            size="${(x) => x.closeButtonSize()}"
            label="${(x) => x.closeLabel}"
            ><${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}></${roundButtonTag}
          >`)} `)}`;
};
export class ChipV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.type = 'default';
        this.uniqueId = uniqueId('chip-id-');
        this.handleKeyDown = (event) => {
            const { key } = event;
            if (key === ' ' || key === 'enter') {
                event.stopPropagation();
                event.preventDefault();
                this.action();
            }
            if (event.key === ' ' && !this.disabled) {
                if (this.type === 'checkbox') {
                    this.active = !this.active;
                }
                else if (this.type === 'radio') {
                    this.active = true;
                }
            }
        };
    }
    colorTokenChanged(_, newValue) {
        this.changeColorToken(newValue);
    }
    sizeChanged(_, newValue) {
        if (this.slottedAvatar && this.slottedAvatar?.length > 0) {
            this.slottedAvatar.forEach((avatar) => {
                avatar.setAttribute('size', `${newValue}`);
                if (newValue === null) {
                    avatar.setAttribute('size', 'medium');
                }
            });
        }
    }
    disabledChanged(_, newValue) {
        requestAnimationFrame(() => {
            if (this.slottedAvatar && this.slottedAvatar?.length > 0) {
                this.slottedAvatar.forEach((avatar) => {
                    avatar.setAttribute('disabled', `${newValue}`);
                    if (newValue === null) {
                        avatar.setAttribute('disabled', 'false');
                    }
                });
            }
            if (this.closable) {
                this.getInternalCloseElement()?.setAttribute('disabled', 'true');
            }
            if (this.type === 'button') {
                this.getInternalButtonElement()?.removeAttribute('disabled');
                this.getInternalButtonElement()?.setAttribute('aria-disabled', `${newValue}`);
            }
            if (this.type === 'checkbox' || this.type === 'radio') {
                this.getInternalInputElement()?.removeAttribute('disabled');
                this.getInternalInputElement()?.setAttribute('aria-disabled', `${newValue}`);
            }
        });
    }
    errorChanged(_, newValue) {
        requestAnimationFrame(() => {
            if (newValue === true) {
                this.setAttribute('aria-label', 'error');
            }
            else {
                this.removeAttribute('aria-label');
            }
        });
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    typeChanged(_, newValue) {
        if (newValue === 'checkbox' || newValue === 'radio') {
            this.role = newValue;
        }
        else {
            this.role = null;
        }
    }
    activeChanged(oldValue, newValue) {
        if (oldValue !== newValue) {
            this.checked = newValue;
        }
    }
    checkedChanged(oldValue, newValue) {
        if (oldValue !== newValue) {
            this.active = newValue;
            this.$emit('change', null, { bubbles: true });
        }
    }
    closeButtonSize() {
        switch (this.size) {
            case 'xsmall': {
                return 'xsmall';
            }
            default: {
                return 'small';
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
        this.setAccessibleLabel();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
    }
    changeColorToken(token) {
        DOM.queueUpdate(() => {
            if (token) {
                this.style.setProperty('--goto-chip-bg-color', `var(--goto-${token})`);
            }
            else {
                this.style.removeProperty('--goto-chip-bg-color');
            }
        });
    }
    slottedAvatarChanged(_, newValue) {
        // TODO : [Backward compatibility] remove tagname check in next major version, should use icon slot
        if (newValue && newValue.length > 0 && newValue.some((element) => element.tagName.includes('AVATAR'))) {
            this.setAttribute('has-avatar', 'true');
            newValue.forEach((avatar) => {
                avatar.setAttribute('size', `${this.size}`);
                if (this.getAttribute('disabled')) {
                    avatar.setAttribute('disabled', '');
                }
            });
        }
        else {
            this.removeAttribute('has-avatar');
        }
    }
    action() {
        if (this.disabled)
            return;
        this.$emit('chip-action', null, { bubbles: true });
        // When the chips are used as links, we want to trigger the default click event so the redirect happens
        if (this.parentElement?.tagName === 'A') {
            return true;
        }
    }
    close(event) {
        if (this.disabled)
            return;
        event.stopPropagation();
        this.$emit('close', null, { bubbles: false });
        this.$emit('ch-chip-close', null, { bubbles: true }); // Needed for the autocomplete multiple
    }
    getInternalButtonElement() {
        return this.shadowRoot?.querySelector('.button-chip');
    }
    getInternalInputElement() {
        return this.shadowRoot?.querySelector('input');
    }
    getInternalCloseElement() {
        return this.shadowRoot?.querySelector('.close');
    }
    setAccessibleLabel() {
        if (this.closable && DEV) {
            this.validateAccessibleCloseLabel();
        }
        if (this.innerText?.length) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleCloseLabel() {
        if (!this.closeLabel && !this.getInternalCloseElement()?.getAttribute('aria-label')?.length) {
            console.error('CHAMELEON-A11Y: Closable chip component must have an accessible label for the X icon provided via the closeLabel attribute.');
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-labelledby')?.length && !this.getAttribute('aria-label')?.length) {
            console.error(`CHAMELEON-A11Y: Chip component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId 
  3) a non-empty label attribute to be used as aria-label.
`);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "active", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "checked", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "closable", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "person", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], ChipV2Component.prototype, "labelId", void 0);
__decorate([
    attr({ attribute: 'close-label' })
], ChipV2Component.prototype, "closeLabel", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "label", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "name", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "value", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "size", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "type", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "uniqueId", void 0);
__decorate([
    attr({ attribute: 'color-token' })
], ChipV2Component.prototype, "colorToken", void 0);
__decorate([
    observable
], ChipV2Component.prototype, "closeButtonSize", null);
__decorate([
    observable
], ChipV2Component.prototype, "slottedAvatar", void 0);
