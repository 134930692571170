import { FC } from 'react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import { ReactComponent as Paypal } from 'assets/Paypal.svg';
import DefaultDeleteCTAs from 'components/default-delete-ctas';
import { PaypalAgreements } from 'types/payment-method';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';

import './paypal-info.css';

interface PaypalInfoProps {
  paypal: PaypalAgreements;
}

const PaypalInfo: FC<PaypalInfoProps> = ({ paypal }): JSX.Element => {
  return (
    <section className="paypal-info">
      <div className="paypal-info__details">
        <Paypal />
        <Typography tag="h3" variant="body-small" gutterBottom>
          {paypal.email}
        </Typography>
      </div>
      <DefaultDeleteCTAs
        isDefault={paypal.isDefault}
        paymentMethodKey={paypal.paymentMethodKey}
        isDeletable={paypal.isDeletable}
        paymentType={PAYMENT_METHODS_TYPES.paypal}
      />
    </section>
  );
};

export default PaypalInfo;
