import { withStylePrefix } from '../common/withStylePrefix';
import { ToolbarComponent, toolbarTemplate } from './Toolbar';
import styles from './Toolbar.styles.scss';
export const toolbarDefinition = {
    baseName: 'toolbar',
    template: toolbarTemplate,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonToolbar = ToolbarComponent.compose(toolbarDefinition);
