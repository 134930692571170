import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorSnackbar from 'components/error-snackbar';
import PaymentFailure from 'components/feedback/payment-failure';
import PaymentSuccess from 'components/feedback/payment-success';
import PaymentFeedback from 'components/payment-feedback';
import ProcessQuoteLoader from 'components/process-quote-loader';
import useViewPortSize from 'hooks/useViewport';
import PayNow from 'routes/pay-now';
import PaymentMethods from 'routes/payment-methods';
import { ONE_PAY_INTENT, PAYMENT_METHODS_TYPES } from 'utils/constants';

const CoreView: FC = (): JSX.Element => {
  const viewport = useViewPortSize();
  const isDesktop = viewport.width > 1200;

  return (
    <>
      <ErrorSnackbar />
      {isDesktop ? (
        <>
          <PaymentSuccess /> <PaymentFailure /> <ProcessQuoteLoader />
        </>
      ) : (
        <PaymentFeedback />
      )}
      <Routes>
        <Route
          path="/:accountKey/:sessionId/paymentmethod/cc"
          element={<PaymentMethods intent={PAYMENT_METHODS_TYPES.cc} />}
        />
        <Route
          path="/:accountKey/:sessionId/paymentmethod/add"
          element={<PaymentMethods intent={ONE_PAY_INTENT.addPayment} />}
        />
        <Route
          path="/:accountKey/:sessionId/paymentmethod/dd"
          element={<PaymentMethods intent={ONE_PAY_INTENT.dd} />}
        />
        <Route
          path="/:accountKey/:sessionId/paymentmethod/ach"
          element={<PaymentMethods intent={ONE_PAY_INTENT.ach} />}
        />
        <Route
          path="/:accountKey/:sessionId/paymentmethod/paypal"
          element={<PaymentMethods intent={ONE_PAY_INTENT.paypal} />}
        />
        <Route path="/:accountKey/:sessionId/pay" element={<PayNow />} />
      </Routes>
    </>
  );
};
export default CoreView;
