import { Anchor, anchorTemplate as template } from '@microsoft/fast-foundation';
import { LinkComponent } from './Link';
import styles from './Link.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const linkDefinition = {
    baseName: 'link',
    baseClass: Anchor,
    template: template,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonLink = LinkComponent.compose(linkDefinition);
