import { ListboxElement, listboxTemplate as template, } from '@microsoft/fast-foundation';
import { ListboxComponent } from './Listbox';
import styles from './Listbox.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const listboxDefinition = {
    baseName: 'listbox',
    baseClass: ListboxElement,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonListbox = ListboxComponent.compose(listboxDefinition);
