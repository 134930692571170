import { html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { TypographyComponent } from '../../Typography';
export const navigationDrawerSectionTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    return html `<template tabindex="-1">
    <${typographyTag} variant="caption-medium-strong" tag="h2"><slot></slot></${typographyTag}
    ><slot name="end"></slot>
  </template>`;
};
export class NavigationDrawerSectionComponent extends ChameleonElementMixin(FoundationElement) {
}
