import { __decorate } from "tslib";
import { SVG_CHEVRON_DOWN_OUTLINED, SVG_CHEVRON_UP_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, observable, ref, when } from '@microsoft/fast-element';
import { assertSlotNonNull } from '../common/asserts';
import { ChameleonElementMixin } from '../common/mixin';
import { getHostChildrenFromEvent } from '../common/utils/DomUtil';
import { DEV } from 'esm-env';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ButtonComponent } from '../Button';
import { SvgComponent } from '../Svg';
import { PopoverV2Component } from '../PopoverV2';
var InternalButtonType;
(function (InternalButtonType) {
    InternalButtonType["Main"] = "main-button";
    InternalButtonType["Menu"] = "menu-button";
})(InternalButtonType || (InternalButtonType = {}));
export const squircleButtonTemplate = (context, _definition) => {
    const buttonTag = context.tagFor(ButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    const popoverV2 = context.tagFor(PopoverV2Component);
    return html `<div @click=${(x, { event }) => x.clickHandler(event)}>
      <${buttonTag}
        ${ref('actionButton')}
        id="main-button"
        variant="${(x) => x.variant}"
        size="${(x) => x.size}"
        disabled="${(x) => x.disabled}"
        is-loading="${(x) => x.isLoading}"
      >
        <slot ${ref('iconSlot')} class="icon" name="icon"></slot>
      </${buttonTag}>
      ${when((x) => x.split, html `<${popoverV2}
            class="popover"
            ${ref('popoverButton')}
            position="${(x) => x.position}"
            width="${(x) => x.popoverWidth}"
            trigger-id="menu-button"
            menu
            label="popover"
          >
            <slot ${ref('actionsSlot')} name="actions"></slot>
          </${popoverV2}>
          <${buttonTag}
            id="menu-button"
            variant="${(x) => x.variant}"
            size="${(x) => x.size}"
            disabled=${(x) => x.disabled || x.isLoading}
          >
            ${when((x) => x.shouldShowChevronBottom(), html `<${svgTag}>${SVG_CHEVRON_DOWN_OUTLINED}</${svgTag}>`)}
            ${when((x) => !x.shouldShowChevronBottom(), html `<${svgTag}>${SVG_CHEVRON_UP_OUTLINED}</${svgTag}>`)}
          </${buttonTag}>`)}
    </div>
    <span class="label" slot="label">${(x) => x.label}</span>`;
};
export class SquircleButtonComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'large';
        this.variant = 'primary';
        this.position = 'top-center';
        this.split = false;
        this.disabled = false;
        this.isLoading = false;
        this.popoverWidth = undefined;
        this.displayLabel = true;
        this.popoverChangeHandler = (e) => this.$emit('popoverchange', e.detail, { bubbles: false });
        this.shouldShowChevronBottom = () => {
            if (this.position.includes('bottom')) {
                return true;
            }
            return false;
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.assertForIconSlot();
        this.setMainLabel();
        this.setMenuLabel();
        this.popoverButton?.addEventListener('popoverchange', this.popoverChangeHandler);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.popoverButton?.removeEventListener('popoverchange', this.popoverChangeHandler);
    }
    labelChanged() {
        requestAnimationFrame(() => {
            this.setMainLabel();
        });
    }
    labelIdChanged() {
        requestAnimationFrame(() => {
            this.setMainLabel();
        });
    }
    menuLabelChanged() {
        requestAnimationFrame(() => {
            this.setMenuLabel();
        });
    }
    clickHandler(e) {
        if (this.actionButton) {
            const childs = getHostChildrenFromEvent(e, this.actionButton);
            if (!childs.length) {
                e.stopImmediatePropagation();
            }
        }
    }
    getInternalButtonElement(type) {
        return this.shadowRoot?.getElementById(type)?.shadowRoot?.querySelector('button');
    }
    get mainButton() {
        return this.getInternalButtonElement(InternalButtonType.Main);
    }
    get menuButton() {
        return this.getInternalButtonElement(InternalButtonType.Menu);
    }
    validateMenuLabel() {
        if (this.split && !this.menuLabel && !this.menuButton?.getAttribute('aria-label')?.length) {
            console.error('CHAMELEON-A11Y: Squircle Button component must have an accessible label for the Menu Button provided by a non-empty menuLabel/menu-label attribute to be used as aria-label.');
        }
    }
    validateMainLabel() {
        if (!this.mainButton?.getAttribute('aria-label')?.length && !this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: Squircle Button component must have an accessible label provided by either
  1) a visible label whose id is given as the attribute labelId
  2) a non-empty label attribute to be used as aria-label.`);
        }
    }
    setMenuLabel() {
        if (this.split && this.menuButton && this.menuLabel) {
            this.menuButton.setAttribute('aria-label', this.menuLabel);
            return;
        }
        if (DEV) {
            this.validateMenuLabel();
        }
    }
    setMainLabel() {
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.mainButton?.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV)
            this.validateMainLabel();
    }
    assertForIconSlot() {
        const iconElement = this.iconSlot.assignedElements()[0];
        if (!iconElement)
            assertSlotNonNull(iconElement, 'SquircleButtonComponent', 'icon');
    }
}
__decorate([
    attr
], SquircleButtonComponent.prototype, "size", void 0);
__decorate([
    attr
], SquircleButtonComponent.prototype, "variant", void 0);
__decorate([
    attr
], SquircleButtonComponent.prototype, "position", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SquircleButtonComponent.prototype, "split", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SquircleButtonComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SquircleButtonComponent.prototype, "isLoading", void 0);
__decorate([
    attr({ attribute: 'popover-width' })
], SquircleButtonComponent.prototype, "popoverWidth", void 0);
__decorate([
    attr
], SquircleButtonComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'display-label' })
], SquircleButtonComponent.prototype, "displayLabel", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], SquircleButtonComponent.prototype, "labelId", void 0);
__decorate([
    attr({ attribute: 'menu-label' })
], SquircleButtonComponent.prototype, "menuLabel", void 0);
__decorate([
    observable
], SquircleButtonComponent.prototype, "mainButton", null);
__decorate([
    observable
], SquircleButtonComponent.prototype, "menuButton", null);
