import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const chatStackTemplate = html `<template>
  <div class="container">
    <div>
      <slot name="avatar"></slot>
    </div>
    <div class="chat-bubble-container">
      <slot name="author-name"></slot>
      <div><slot></slot></div>
      <slot name="end"></slot>
    </div>
  </div>
</template>`;
export class ChatStackComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.type = 'inbound';
    }
    typeChanged(_, newValue) {
        if (this.chatBubbleElements) {
            this.chatBubbleElements.forEach((bubble) => (bubble.type = newValue));
        }
    }
    get chatBubbleElements() {
        return this.querySelectorAll(`${this.tagName}-chat-bubble`);
    }
}
__decorate([
    attr
], ChatStackComponent.prototype, "type", void 0);
