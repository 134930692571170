import { createScript } from 'lib/dom-helpers';

let libLoadedPromise: Promise<void>;

export class CybersourceFingerprint {
  /**
   * Load the Cybersource fingerprinting JS
   * @param {country}
   * @returns {Promise} Promise that resolves when the script is successfully loaded; rejects otherwise
   */
  static load = (url: string) => {
    // only load the library once
    if (libLoadedPromise) {
      return libLoadedPromise;
    }

    libLoadedPromise = createScript(url);

    return libLoadedPromise;
  };
}

const loadFingerprint = async (fingerprintURL: string) => {
  try {
    await CybersourceFingerprint.load(fingerprintURL);
  } catch (err) {
    console.log(err);
  }
};

export default loadFingerprint;
