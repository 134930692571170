import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import st from 'utils/shared-translations';

export const getSepaFormValidationSchema = (intl: IntlShape): object => {
  const validationSchema = {
    iban: Yup.string()
      .trim()
      .nullable()
      .required(intl.formatMessage(st['dd.sepa.validation.required']))
      .min(15, intl.formatMessage(st['dd.sepa.validation.length']))
      .max(34, intl.formatMessage(st['dd.sepa.validation.length'])),
  };

  return Yup.object().shape(validationSchema);
};
