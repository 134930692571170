import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement, forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
export const Snackbar = forwardRef((_a, ref) => {
    var { progress, actions, title, children } = _a, props = __rest(_a, ["progress", "actions", "title", "children"]);
    return createReactWrapper({
        tagName: 'chameleon-snackbar',
        props: Object.assign(Object.assign({}, props), { ref }),
        children: (_jsxs(_Fragment, { children: [_jsx("h2", { slot: "title", children: title }), _jsx("p", { slot: "message", children: children }), progress !== undefined &&
                    createElement('chameleon-linear-progress', { slot: 'progress', value: progress < 0 ? undefined : progress, inverse: true }, undefined), actions && _jsx("div", { slot: "action", children: actions })] })),
    });
});
