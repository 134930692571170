import { jsx as _jsx } from "react/jsx-runtime";
export const isRenderable = (value) => {
    return (value === null ||
        !(typeof value === 'object' ||
            typeof value === 'function' ||
            typeof value === 'symbol' ||
            typeof value === 'bigint'));
};
export const addExtraProps = (component, extraProps) => {
    return isRenderable(component) ? (_jsx("span", Object.assign({}, extraProps, { children: component }))) : (_jsx(component.type, Object.assign({}, component.props, extraProps)));
};
