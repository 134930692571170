import { __rest } from "tslib";
import { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
import { ChangeSyntheticEvent, InputSyntheticEvent } from '../common/SyntheticEvent';
export const Checkbox = forwardRef((_a, ref) => {
    var { children, onChange, onInput, indeterminate, errorText } = _a, otherProps = __rest(_a, ["children", "onChange", "onInput", "indeterminate", "errorText"]);
    const checkboxRef = useRef();
    const handledRef = handleForwardRef(checkboxRef, ref);
    useEffect(() => {
        var _a;
        const handleChange = (event) => onChange === null || onChange === void 0 ? void 0 : onChange(new ChangeSyntheticEvent(event));
        (_a = checkboxRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('change', handleChange);
        return () => {
            var _a;
            (_a = checkboxRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', handleChange);
        };
    }, [onChange]);
    useEffect(() => {
        var _a;
        const handleInput = (event) => onInput === null || onInput === void 0 ? void 0 : onInput(new InputSyntheticEvent(event));
        (_a = checkboxRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('change', handleInput);
        return () => {
            var _a;
            (_a = checkboxRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', handleInput);
        };
    }, [onInput]);
    return createReactWrapper({
        tagName: 'chameleon-checkbox',
        props: Object.assign(Object.assign({}, otherProps), { 'error-text': errorText, ref: handledRef }),
        children,
    });
});
