import { MenuItem, menuItemTemplate as template } from '@microsoft/fast-foundation';
import { NavigationDrawerItemComponent } from './NavigationDrawerItem';
import styles from './NavigationDrawerItem.styles.scss';
import { withStylePrefix } from '../../common/withStylePrefix';
export const navigationDrawerItemDefinition = {
    baseName: 'navigation-drawer-item',
    baseClass: MenuItem,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonNavigationDrawerItem = NavigationDrawerItemComponent.compose(navigationDrawerItemDefinition);
