import { __decorate } from "tslib";
import { SVG_CHEVRON_DOWN_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, ref } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { getHostChildrenFromEvent } from '../common/utils/DomUtil';
import { DEV } from 'esm-env';
import { ButtonComponent } from '../Button';
import { SvgComponent } from '../Svg';
import { PopoverV2Component } from '../PopoverV2';
export const splitButtonTemplate = (context, _definition) => {
    const buttonTag = context.tagFor(ButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    const popoverV2Tag = context.tagFor(PopoverV2Component);
    return html `<div class="container" @click=${(x, { event }) => x.clickHandler(event)}>
  <${buttonTag} is-loading="${(x) => x.isLoading}" part="button" class="action" variant="${(x) => x.variant}" size="${(x) => x.size}"
  disabled=${(x) => x.disabled}>
    <span slot="start">
      <slot name="start"></slot>
    </span>
    <slot></slot>
  </${buttonTag}>
  <${buttonTag} id="menu-button" @click="${(x) => x.handleTriggerClick()}" variant="${(x) => x.variant}" size="${(x) => x.size}"
  disabled=${(x) => x.disabled || x.isLoading}>
    <${svgTag}>${SVG_CHEVRON_DOWN_OUTLINED}<${svgTag}>
  </${buttonTag}>
  <${popoverV2Tag} label="popover" trigger-id="menu-button" part="popover" z-index="${(x) => x.popoverZIndex}" position="${(x) => x.position}" menu width="${(x) => x.popoverWidth}">
    <slot ${ref('actionsSlot')} name="actions"></slot>
  </${popoverV2Tag}>
  </div>`;
};
export class SplitButtonComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.variant = 'primary';
        this.position = 'bottom-end';
        this.disabled = false;
        this.fullWidth = false;
        this.popoverWidth = undefined;
        this.popoverZIndex = undefined;
        this.getActionButton = () => this.shadowRoot?.querySelector('.action');
        this.getPopover = () => this.shadowRoot?.querySelector('chameleon-popover-v2');
        this.popoverChangeHandler = (e) => this.$emit('popoverchange', e.detail, { bubbles: false });
    }
    menuLabelChanged() {
        requestAnimationFrame(() => {
            this.setAccessibleLabel();
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        this.getPopover()?.addEventListener('popoverchange', this.popoverChangeHandler);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.getPopover()?.removeEventListener('popoverchange', this.popoverChangeHandler);
    }
    clickHandler(e) {
        const actionButton = this.getActionButton();
        if (actionButton) {
            const children = getHostChildrenFromEvent(e, actionButton);
            if (!children.length) {
                e.stopImmediatePropagation();
            }
        }
    }
    handleTriggerClick() {
        this.$emit('split-button-trigger-click');
    }
    getInternalMenuButtonElement() {
        return this.shadowRoot?.getElementById('menu-button')?.shadowRoot?.querySelector('button');
    }
    validateMenuLabel() {
        if (!this.menuLabel && !this.getInternalMenuButtonElement()?.getAttribute('aria-label')?.length) {
            console.error(`CHAMELEON-A11Y: Split Button component must have an accessible label for the chevron button provided by a non-empty menuLabel/menu-label attribute to be used as aria-label.`);
        }
    }
    setAccessibleLabel() {
        const menuButton = this.getInternalMenuButtonElement();
        if (menuButton && this.menuLabel) {
            menuButton.setAttribute('aria-label', this.menuLabel);
            return;
        }
        if (DEV) {
            this.validateMenuLabel();
        }
    }
}
__decorate([
    attr
], SplitButtonComponent.prototype, "size", void 0);
__decorate([
    attr
], SplitButtonComponent.prototype, "variant", void 0);
__decorate([
    attr
], SplitButtonComponent.prototype, "position", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SplitButtonComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'full-width' })
], SplitButtonComponent.prototype, "fullWidth", void 0);
__decorate([
    attr({ attribute: 'popover-width' })
], SplitButtonComponent.prototype, "popoverWidth", void 0);
__decorate([
    attr({ attribute: 'popover-z-index' })
], SplitButtonComponent.prototype, "popoverZIndex", void 0);
__decorate([
    attr({ attribute: 'menu-label' })
], SplitButtonComponent.prototype, "menuLabel", void 0);
__decorate([
    attr({ attribute: 'is-loading' })
], SplitButtonComponent.prototype, "isLoading", void 0);
