import { __rest } from "tslib";
import { forwardRef, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { useEventListener } from '../common/useEventListener';
import { handleForwardRef } from '../common/RefUtils';
export const Menu = forwardRef((_a, ref) => {
    var { children, labelId, onChange } = _a, props = __rest(_a, ["children", "labelId", "onChange"]);
    const menuRef = useRef();
    const handledRef = handleForwardRef(menuRef, ref);
    // biome-ignore lint/suspicious/noExplicitAny: React has a defautl typing for onChange and it conflicts with what we use
    useEventListener('change', onChange, menuRef);
    return createReactWrapper({
        tagName: 'chameleon-menu',
        props: Object.assign(Object.assign({}, props), { 'label-id': labelId, ref: handledRef }),
        children,
    });
});
