import { optionStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { ListboxOption, listboxOptionTemplate as template, } from '@microsoft/fast-foundation';
import { OptionComponent } from './Option';
import styles from './Option.styles.scss';
export const optionDefinition = {
    baseName: 'option',
    baseClass: ListboxOption,
    template: template,
    styles: (ctx, def) => css `
    ${optionStyles(ctx, def)}
    ${styles}
  `,
};
export const chameleonOption = OptionComponent.compose(optionDefinition);
