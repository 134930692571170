import { ContextualActionSeparatorComponent } from './ContextualActionSeparator';
import { html } from '@microsoft/fast-element';
import styles from './ContextualActionSeparator.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
const template = html ``;
export const chameleonContextualActionSeparator = ContextualActionSeparatorComponent.compose({
    baseName: 'contextual-action-separator',
    template,
    styles: withStylePrefix(styles),
});
