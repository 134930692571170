import { __decorate } from "tslib";
import { SVG_PERSON_OUTLINED } from '@getgo/chameleon-icons';
import { DEV } from 'esm-env';
import { SvgComponent } from '../Svg';
import { attr, DOM, html, observable, slotted, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
const BADGE_ALLOWED_SIZES = ['xlarge'];
export const avatarV2Template = (context, _definition) => {
    const svgIconTag = context.tagFor(SvgComponent);
    return html ` ${when((x) => x.type === 'button', html `<button @click=${(x, { event }) => x.onAction(event)}>
      <slot ${slotted('content')}>
        <${svgIconTag}>${SVG_PERSON_OUTLINED}</${svgIconTag}>
      </slot>
      ${when((x) => x.canHostABadge(), html `<slot name="badge" ${slotted('slottedBadge')}></slot>`)}
      ${when((x) => x.size !== 'xxxxlarge', html `<slot name="presence"></slot>`)}
    </button>`)}
  ${when((x) => x.type === 'default', html `<template>
      <slot ${slotted('content')}>
        <${svgIconTag}>${SVG_PERSON_OUTLINED}</${svgIconTag}>
      </slot>
      ${when((x) => x.canHostABadge(), html `<slot name="badge" ${slotted('slottedBadge')}></slot>`)}
      ${when((x) => x.size !== 'xxxxlarge', html `<slot name="presence"></slot>`)}
    </template>`)}`;
};
export class AvatarV2Component extends ChameleonPrefixedElementMixin(FoundationElement, 'avatar-v2') {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.variant = 'primary';
        this.type = 'default';
        this.disabled = false;
        this.hidden = false;
        this.handleKeyDown = (event) => {
            const { key } = event;
            if (key === ' ' || key === 'enter') {
                this.onAction(event);
                event.preventDefault();
            }
        };
    }
    colorTokenChanged(_, newValue) {
        this.changeColorToken(newValue);
    }
    contentChanged() {
        this.verifyContent();
        this.verifyText();
        this.verifyImage();
    }
    slottedBadgeChanged(_, newValue) {
        if (newValue && newValue.length > 0) {
            newValue.forEach((badge) => {
                badge.setAttribute('size', 'small');
                badge.setAttribute('border', 'fill');
            });
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        this.addEventListener('keydown', this.handleKeyDown);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    disabledChanged(_, newValue) {
        requestAnimationFrame(() => {
            this.getInternalButtonElement()?.removeAttribute('disabled');
            this.getInternalButtonElement()?.setAttribute('aria-disabled', `${newValue}`);
        });
    }
    getInternalButtonElement() {
        return this.shadowRoot?.querySelector('button');
    }
    changeColorToken(token) {
        DOM.queueUpdate(() => {
            if (token) {
                this.style.setProperty('--goto-avatar-bg-color', `var(--${token})`);
            }
            else {
                this.style.removeProperty('--goto-avatar-bg-color');
            }
        });
    }
    canHostABadge() {
        return BADGE_ALLOWED_SIZES.includes(this.size);
    }
    isActiveButton() {
        return this.type === 'button' && !this.disabled;
    }
    hasContent() {
        return this.content.length > 0;
    }
    isContentText() {
        return !!this.content.find((contentElement) => contentElement.nodeName === '#text');
    }
    isContentImage() {
        return !!this.content.find((contentElement) => contentElement.nodeName === 'IMG');
    }
    onAction(event) {
        if (this.isActiveButton()) {
            this.$emit('avatar-action', event, { bubbles: true });
        }
    }
    verifyContent() {
        this.classList.toggle('no-content', !this.hasContent());
    }
    verifyText() {
        this.classList.toggle('text-content', this.isContentText());
    }
    verifyImage() {
        this.classList.toggle('img-content', this.isContentImage());
    }
    getChildImage() {
        return this.querySelector('img');
    }
    setAccessibleLabel() {
        if (this.label) {
            if (this.getChildImage()) {
                this.getChildImage()?.setAttribute('alt', `${this.label}`);
            }
            else {
                this.setAttribute('aria-label', `${this.label}`);
            }
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-label')?.length && !this.getChildImage()?.alt?.length) {
            console.error(`CHAMELEON-A11Y: Avatar component must have an accessible label provided by a non-empty label attribute to be used as 
  1) alt-text if the avatar has an image 
  2) aria-label if the avatar has initials or an icon`);
        }
    }
}
__decorate([
    attr
], AvatarV2Component.prototype, "size", void 0);
__decorate([
    attr
], AvatarV2Component.prototype, "variant", void 0);
__decorate([
    attr
], AvatarV2Component.prototype, "type", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AvatarV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'color-token' })
], AvatarV2Component.prototype, "colorToken", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AvatarV2Component.prototype, "hidden", void 0);
__decorate([
    attr
], AvatarV2Component.prototype, "label", void 0);
__decorate([
    observable
], AvatarV2Component.prototype, "content", void 0);
__decorate([
    observable
], AvatarV2Component.prototype, "slottedBadge", void 0);
