import { Menu, menuTemplate as template } from '@microsoft/fast-foundation';
import { MenuComponent } from './Menu';
import styles from './Menu.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const menuDefinition = {
    baseName: 'menu',
    baseClass: Menu,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonMenu = MenuComponent.compose(menuDefinition);
