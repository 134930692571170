import { Button, buttonTemplate as template, } from '@microsoft/fast-foundation';
import { ButtonComponent } from './Button';
import styles from './Button.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const buttonDefinition = {
    baseName: 'button',
    baseClass: Button,
    template: template,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonButton = ButtonComponent.compose(buttonDefinition);
