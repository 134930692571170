import { __decorate } from "tslib";
import { TextField } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export class InputFieldComponent extends ChameleonElementMixin(TextField) {
    constructor() {
        super(...arguments);
        this.fieldsize = 'medium';
        this.success = false;
        this.error = false;
        this.fullwidth = false;
        this.optional = false;
        this.disableBrowserDefaults = false;
        this.infoContainer = document.createElement('div');
        this.helperTextEl = document.createElement('slot');
        this.handleRootClick = (e) => {
            if (!e.defaultPrevented) {
                this.control.focus();
            }
        };
        this.handleHelperTextSlotChange = () => {
            const helperText = this.helperTextEl.assignedElements()[0];
            if (!helperText || !helperText.textContent) {
                return;
            }
            if (this.querySelector('[slot="helpertext"]')) {
                this.control.setAttribute('aria-describedby', this.querySelector('[slot="helpertext"]').id);
            }
        };
    }
    autocompleteChanged(_, next) {
        if (next) {
            this.control?.setAttribute('autocomplete', next);
        }
        else {
            this.control?.removeAttribute('autocomplete');
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.shadowRoot?.querySelector('.root')?.addEventListener('click', this.handleRootClick);
        this.infoContainer.classList.add('info');
        this.shadowRoot?.appendChild(this.infoContainer);
        this.addHelperText();
        this.addIdForLabel();
        this.setAccessibleLabel();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.shadowRoot?.querySelector('.root')?.removeEventListener('click', this.handleRootClick);
        this.helperTextEl.removeEventListener('slotchange', this.handleHelperTextSlotChange);
    }
    // we cannot add a maxlengthChanged method since it's `private` in FAST and would throw several TS errors
    // this is a workaround for that.
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        if (name === 'readonly' && this.hasAttribute('readonly')) {
            this.setAttribute('aria-readonly', 'true');
        }
        else if (name === 'readonly' && !this.hasAttribute('readonly')) {
            this.removeAttribute('aria-readonly');
        }
        this.addAllRelevantAttributes();
        if (this.hasAttribute('disabled')) {
            requestAnimationFrame(() => {
                this.control?.removeAttribute('disabled');
            });
        }
        this.control?.setAttribute('aria-disabled', `${this.hasAttribute('disabled')}`);
        this.control?.addEventListener('keydown', (event) => {
            if (this.hasAttribute('disabled') && event.key !== 'Tab') {
                event.preventDefault();
            }
        });
    }
    addIdForLabel() {
        if (this.labelId) {
            this.control.setAttribute('aria-labelledby', this.labelId);
        }
    }
    addAllRelevantAttributes() {
        const attributes = this.getAttributeNames();
        attributes.forEach((attribute) => {
            if (Object.keys(HTMLInputElement.prototype).includes(attribute)) {
                this.control?.setAttribute(attribute, this.getAttribute(attribute));
                if (attribute === 'disabled') {
                    this.control?.removeAttribute('disabled');
                    this.control?.setAttribute('aria-disabled', `${this.hasAttribute('disabled')}`);
                }
            }
        });
        if (this.optional || this.textContent?.includes('(optional)')) {
            this.control?.removeAttribute('aria-required');
        }
        else {
            this.control?.setAttribute('aria-required', 'true');
        }
    }
    addHelperText() {
        this.helperTextEl.setAttribute('name', 'helpertext');
        if (this.querySelector('[slot="helpertext"]')) {
            this.querySelector('[slot="helpertext"]')?.setAttribute('id', uniqueId('helpertext-'));
        }
        this.helperTextEl.addEventListener('slotchange', this.handleHelperTextSlotChange);
        const helperText = this.shadowRoot?.querySelector('[name="helpertext"]');
        if (!helperText) {
            this.infoContainer?.prepend(this.helperTextEl);
        }
    }
    setAccessibleLabel() {
        if (this.textContent?.trim() && this.helperTextEl.assignedElements()[0]?.textContent !== this.textContent?.trim()) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: InputField component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
}
__decorate([
    attr
], InputFieldComponent.prototype, "fieldsize", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "success", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "optional", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], InputFieldComponent.prototype, "labelId", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'disable-browser-defaults' })
], InputFieldComponent.prototype, "disableBrowserDefaults", void 0);
__decorate([
    attr
], InputFieldComponent.prototype, "autocomplete", void 0);
