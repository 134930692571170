import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { Button } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonPrefixedElementMixin } from './mixin';
import { addHasAttributesToParent, addSvgIconSize, createLoadingSvg } from './LoadingIconUtils';
export class LoadingButton extends ChameleonPrefixedElementMixin(Button) {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.buttonLoadingIndicatorId = uniqueId('button-loading-indicator-');
        this.updateHasAttributes = () => {
            addHasAttributesToParent(this);
        };
    }
    isLoadingChanged(_) {
        if (this.isConnected)
            addHasAttributesToParent(this);
    }
    sizeChanged(_, next) {
        if (!next) {
            return;
        }
        if (this.loadingIcon) {
            addSvgIconSize(this.loadingIcon, next);
        }
    }
    disabledChanged(oldValue, newValue) {
        super.disabledChanged?.(oldValue, newValue);
        if (!newValue && !!this.isLoading) {
            this.disabled = newValue;
        }
    }
    placeSVGToCorrectPlaceInDOM() {
        if (this.loadingIcon) {
            return;
        }
        this.loadingIcon = createLoadingSvg(this.size, this.tagFor('svg'));
        this.loadingIcon.id = this.buttonLoadingIndicatorId;
        const contentNode = this.shadowRoot?.querySelector('[part="control"]')?.querySelector('[part="content"]');
        const endSlot = this.querySelector('[slot="end"]');
        if (endSlot) {
            this.shadowRoot?.querySelector('[part="control"]')?.appendChild(this.loadingIcon);
            return;
        }
        this.shadowRoot?.querySelector('[part="control"]')?.insertBefore(this.loadingIcon, contentNode);
    }
    connectedCallback() {
        super.connectedCallback();
        this.placeSVGToCorrectPlaceInDOM();
        this.shadowRoot?.querySelector('slot[name="start"]').addEventListener('slotchange', this.updateHasAttributes);
        this.shadowRoot?.querySelector('slot[name="end"]').addEventListener('slotchange', this.updateHasAttributes);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.shadowRoot?.querySelector('slot[name="start"]').removeEventListener('slotchange', this.updateHasAttributes);
        this.shadowRoot?.querySelector('slot[name="end"]').removeEventListener('slotchange', this.updateHasAttributes);
    }
}
__decorate([
    attr({ mode: 'boolean', attribute: 'is-loading' })
], LoadingButton.prototype, "isLoading", void 0);
__decorate([
    attr
], LoadingButton.prototype, "size", void 0);
