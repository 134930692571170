import { __decorate } from "tslib";
import { SVG_FILE } from '@getgo/chameleon-icons';
import { attr, html, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { SvgComponent } from '../Svg';
import { IconBadgeComponent } from '../IconBadge';
import { TypographyComponent } from '../Typography';
export const thumbnailTemplate = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    const iconBadgeTag = context.tagFor(IconBadgeComponent);
    const typographyTag = context.tagFor(TypographyComponent);
    return html `
    <div class="icon">
        ${when((x) => !x.fileType, html `<slot name="image"></slot>`)}
        ${when((x) => x.fileType, html `<${svgTag}>${SVG_FILE}</${svgTag}> ${when((x) => x.fileType !== 'blank', html `<${iconBadgeTag}
                size="${(x) => x.computeIconBadgeSize(x.size)}"
                type="${(x) => x.fileType}"
              ></${iconBadgeTag}>`)}`)}
    </div>
    <div class="content">
      <${typographyTag}
        variant="caption-medium-strong"
        class="title"
        color="type-color-default"
        ?nowrap=${(x) => x.size !== 'large'}
        ><slot></slot
      ></${typographyTag}>
      ${when((x) => x.size !== 'small', html `<${typographyTag}
          variant="caption-medium"
          color="type-color-secondary"
          ?nowrap=${(x) => x.size !== 'large'}
          ><slot name="description"></slot>
        </${typographyTag}>`)}
    </div>`;
};
export class ThumbnailComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
    }
    computeIconBadgeSize(size) {
        if (!size) {
            return 'medium';
        }
        if (size === 'large') {
            return 'xlarge';
        }
        return size === 'medium' ? 'small' : size;
    }
}
__decorate([
    attr
], ThumbnailComponent.prototype, "size", void 0);
__decorate([
    attr({ attribute: 'file-type' })
], ThumbnailComponent.prototype, "fileType", void 0);
