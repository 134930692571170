import config from 'config';
import { AddPaypalStep1Payload, AddPaypalStep2Payload, UsePaypalPayPayload } from 'types/transaction-paypal';
import fetchWithToken from './provider/fetch';

const transactionsPaypalEndPoint = (accountKey: string, sessionId: string): string =>
  `${config.COPAS_URL}/payment/ui/cip/v1/${accountKey}/session/${sessionId}/transaction/pp`;

export const daoTransactionAddPaypalStep1Post = (
  accountKey: string,
  sessionId: string,
  data: AddPaypalStep1Payload,
): Promise<object> =>
  fetchWithToken({
    reqUrl: `${transactionsPaypalEndPoint(accountKey, sessionId)}/addPayPalWithoutPayment/step1`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoTransactionAddPaypalStep2Post = (
  accountKey: string,
  sessionId: string,
  data: AddPaypalStep2Payload,
): Promise<object> =>
  fetchWithToken({
    reqUrl: `${transactionsPaypalEndPoint(accountKey, sessionId)}/addPayPalWithoutPayment/step2`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoTransactionUsePaypalPayPost = (
  accountKey: string,
  sessionId: string,
  data: UsePaypalPayPayload,
): Promise<object> =>
  fetchWithToken({
    reqUrl: `${transactionsPaypalEndPoint(accountKey, sessionId)}/useStoredPaymentMethodWithPayment`,
    method: 'POST',
    body: JSON.stringify(data),
  });
