import { getDayOfWeek, isSameDay, isSameMonth, parseDate, startOfWeek, today, } from '@internationalized/date';
export const getDatesInWeek = (locale, weekIndex, from) => {
    let date = from.add({ weeks: weekIndex });
    const dates = [];
    date = startOfWeek(date, locale);
    // startOfWeek will clamp dates within the calendar system's valid range, which may
    // start in the middle of a week. In this case, add null placeholders.
    const dayOfWeek = getDayOfWeek(date, locale);
    for (let i = 0; i < dayOfWeek; i++) {
        dates.push(null);
    }
    while (dates.length < 7) {
        dates.push(date);
        const nextDate = date.add({ days: 1 });
        if (isSameDay(date, nextDate)) {
            // If the next day is the same, we have hit the end of the calendar system.
            break;
        }
        date = nextDate;
    }
    // Add null placeholders if at the end of the calendar system.
    while (dates.length < 7) {
        dates.push(null);
    }
    return dates;
};
export const getWeekDays = (timezone, locale) => {
    const weekStart = startOfWeek(today(timezone), locale);
    return [...new Array(7).keys()].map((index) => {
        const date = weekStart.add({ days: index });
        const dateDay = date.toDate(timezone);
        return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(dateDay);
    });
};
export const isBeforeMin = (date, min) => {
    return min && parseDate(min).compare(date) > 0;
};
export const isAfterMax = (date, max) => {
    // Check if the date is later than the maximum (if max is set)
    // When dates are equal, this returns false
    return max && parseDate(max).compare(date) < 0;
};
export const formatNumber = (locale, num) => new Intl.NumberFormat(locale, { style: 'decimal' }).format(num);
export const isLocaleValid = (locale) => {
    try {
        Intl.getCanonicalLocales(locale);
        return true;
    }
    catch (e) {
        return false;
    }
};
export const isTimezoneValid = (timezone) => {
    try {
        Intl.DateTimeFormat(undefined, { timeZone: timezone });
        return true;
    }
    catch (e) {
        return false;
    }
};
export const constructPreviousMonth = (locale) => new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(-1, 'month');
export const constructNextMonth = (locale) => new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(1, 'month');
export const constructCurrentMonth = (locale) => new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(0, 'day');
export const getTabIndexForDay = (day, focusedDate) => {
    return isSameDay(day, focusedDate) ? '0' : '-1';
};
export const getAriaLabelForDay = (day, locale, timezone) => {
    return new Intl.DateTimeFormat(locale, {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    }).format(day.toDate(timezone));
};
export const isDayInactive = (date, focusedDate, min, max) => {
    // A date is inactive if it's before min, after max, or not in the same month
    return isBeforeMin(date, min) || isAfterMax(date, max) || !isSameMonth(date, focusedDate);
};
