import { __decorate } from "tslib";
import { attr, DOM } from '@microsoft/fast-element';
import { SVG_SEARCH_OUTLINED, SVG_CLOSE_OUTLINED } from '@getgo/chameleon-icons';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { TextFieldComponent } from '../TextField';
import { DEV } from 'esm-env';
export class SearchFieldComponent extends ChameleonPrefixedElementMixin(TextFieldComponent, 'search-field') {
    constructor() {
        super(...arguments);
        this.alignment = 'left';
        this.fieldsize = 'medium';
        this.handleClearButtonClick = () => {
            this.value = '';
            this.$emit('input');
            this.$emit('close', null, { bubbles: false });
            this.$emit('change');
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.appendStartIcon();
        this.appendClearButton();
    }
    disconnectedCallback() {
        this.clearButton?.removeEventListener('click', this.handleClearButtonClick);
    }
    valueChanged(_oldValue, newValue) {
        super.valueChanged(_oldValue, newValue);
        if (newValue !== '') {
            this.toggleClearButton(newValue);
        }
        else {
            this.toggleClearButton(this.value);
        }
    }
    labelChanged(_, next) {
        if (!this.isConnected) {
            return;
        }
        if (next && next.length > 0) {
            this.control.setAttribute('aria-label', next);
        }
        else {
            this.control.removeAttribute('aria-label');
        }
    }
    appendStartIcon() {
        const startSlot = this.shadowRoot?.querySelector('[name="start"]');
        const svgTag = this.tagFor('svg');
        if (startSlot?.innerHTML.includes('chameleon-svg') || startSlot?.innerHTML.includes(this.tagFor('svg')))
            return;
        const svg = document.createElement(svgTag);
        svg.innerHTML = SVG_SEARCH_OUTLINED;
        startSlot?.appendChild(svg);
    }
    appendClearButton() {
        const endSlot = this.shadowRoot?.querySelector('[name="end"]');
        const svgTag = this.tagFor('svg');
        /* If we don't have a svg inside the end slot, we have to create the clearButton with the svg inside. */
        if (!endSlot?.innerHTML.includes(svgTag) || !endSlot?.innerHTML.includes('chameleon-svg')) {
            this.clearButton = document.createElement('button');
            this.clearButton.setAttribute('aria-label', 'Clear Button');
            const endSvg = document.createElement(svgTag);
            endSvg.innerHTML = SVG_CLOSE_OUTLINED;
            this.clearButton?.appendChild(endSvg);
        }
        if (this.clearButton) {
            this.clearButton.addEventListener('click', this.handleClearButtonClick);
            endSlot?.appendChild(this.clearButton);
        }
    }
    toggleClearButton(value) {
        DOM.queueUpdate(() => {
            if (!value) {
                this.setAttribute('clear-button-hidden', 'true');
            }
            else {
                this.setAttribute('clear-button-hidden', 'false');
            }
        });
    }
    setAccessibleLabel() {
        if (this.label && this.label !== '') {
            this.control.setAttribute('aria-label', this.label);
            return;
        }
        if (this.labelId && this.labelId !== '') {
            this.control.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: SearchField component must have an accessible label provided by either 
  1) text content 
  2) setting the label attribute on the search-field
  2) a visible element whose id is given as the attribute labelId
`);
        }
    }
}
__decorate([
    attr
], SearchFieldComponent.prototype, "alignment", void 0);
__decorate([
    attr
], SearchFieldComponent.prototype, "fieldsize", void 0);
__decorate([
    attr
], SearchFieldComponent.prototype, "label", void 0);
