import { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { showErrorSnack } from 'modules/error';
import { postPaymentMethods, setChosenPaymentMethodKey, setPayNowIntent } from 'modules/payment-methods';
import { sessionIntent, sessionSuccessRedirectUrl } from 'modules/session-details';
import Track, { PaypalSaveRedirect, PaypalSaveStep1 } from 'modules/tracking';
import { postTransactionAddPaypalStep1, postTransactionAddPaypalStep2 } from 'modules/transaction-paypal';
import { AddPaypalStep2Resp } from 'types/transaction-paypal';
import {
  COPAS_PAYMENT_INTENT,
  COPAS_PAYMENT_STATUS,
  PAYMENT_METHODS_TYPES,
  SEARCH_PARAMS,
  TRACKING_EVENT_STATUS,
} from 'utils/constants';
import st from 'utils/shared-translations';

const PaypalAdd: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isStep2APICalled, setIsStep2APICalled] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  const selectedSessionIntent = useAppSelector(sessionIntent);

  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);

  const isPaypalRedirect = searchParams.get(SEARCH_PARAMS.redirect) === PAYMENT_METHODS_TYPES.paypal;

  const clearSearchParamTokens = () => {
    // The following searchParams will be added when PayPal redirects to OnePay. So clear them.
    searchParams.delete('redirect');
    searchParams.delete('token');
    searchParams.delete(SEARCH_PARAMS.baToken);

    const modifiedSearchParams = searchParams.toString();
    const newUrl = location.href.split('?')[0] + (modifiedSearchParams ? `?${modifiedSearchParams}` : '');

    history.pushState(null, '', newUrl);
  };

  const handleStep2Resp = (response: AddPaypalStep2Resp) => {
    if (response.status === COPAS_PAYMENT_STATUS.SUCCESS) {
      Track(PaypalSaveRedirect, { status: TRACKING_EVENT_STATUS.success });
      if (selectedSessionIntent === COPAS_PAYMENT_INTENT.PAYMENT_OR_PAYMENT_METHOD_CHANGE) {
        clearSearchParamTokens();

        dispatch(postPaymentMethods()).then(() => {
          dispatch(setChosenPaymentMethodKey(response.paymentMethodKey));
          dispatch(setPayNowIntent(''));
        });
      } else {
        localStorage.clear();
        window.location.assign(selectedSuccessRedirectUrl);
      }
    } else {
      Track(PaypalSaveRedirect, { status: TRACKING_EVENT_STATUS.copasFailure });
      clearSearchParamTokens();
      dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure']));
    }
  };

  useEffect(() => {
    // Call step2 API only when it's a redirect from PayPal
    if (isPaypalRedirect) {
      Track(PaypalSaveRedirect, { status: TRACKING_EVENT_STATUS.start });
      const payload = {
        baToken: searchParams.get(SEARCH_PARAMS.baToken) || '',
        setAsDefault: false,
      };

      setIsStep2APICalled(true);

      dispatch(postTransactionAddPaypalStep2(payload))
        .unwrap()
        .then((resp) => handleStep2Resp(resp))
        .catch(() => {
          clearSearchParamTokens();
          dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure']));
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Call step1 API only when it's not a redirect from PayPal and step2 API is not called; else this will be triggered as soon as the searchParam is cleared from clearSearchParamTokens() after step2 API call
    if (!isPaypalRedirect && !isStep2APICalled) {
      Track(PaypalSaveStep1, {});
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(SEARCH_PARAMS.redirect, PAYMENT_METHODS_TYPES.paypal);

      const modifiedSearchParams = searchParams.toString();
      const returnUrl = location.href.split('?')[0] + `?${modifiedSearchParams}`;

      const payload = {
        returnUrl,
        cancelUrl: window.location.href,
      };
      dispatch(postTransactionAddPaypalStep1(payload))
        .unwrap()
        .then((response: any) => {
          const redirectUrl = response?.redirectUrl;
          window.location.href = redirectUrl;
        })
        .catch(() => dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure'])));
    }
    // eslint-disable-next-line
  }, []);

  return <CircularProgress />;
};

export default PaypalAdd;
