import { __rest } from "tslib";
import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const Option = forwardRef((_a, ref) => {
    var { children, start, end } = _a, props = __rest(_a, ["children", "start", "end"]);
    return createReactWrapper({
        tagName: 'chameleon-option',
        props: Object.assign(Object.assign({}, props), { ref }),
        children: (_jsxs(_Fragment, { children: [start && addExtraProps(start, { slot: 'start' }), children, end && addExtraProps(end, { slot: 'end' })] })),
    });
});
