import { RootState } from 'types/root';
import { AddCcResp, PrepareResp, TransactionsCcState, UseCcPayResp } from 'types/transactions-cc';

export const transactionsCcTree = (state: RootState): TransactionsCcState => state.transactionsCc || {};

export const transactionsCcIsLoading = (state: RootState): boolean => transactionsCcTree(state).isLoading || false;

export const transactionsCcPrepareResp = (state: RootState): PrepareResp => transactionsCcTree(state).prepareResp || {};

export const transactionCcCaptureContext = (state: RootState): string =>
  transactionsCcPrepareResp(state).captureContext || '';

export const transactionFingerprintingId = (state: RootState): string =>
  transactionsCcPrepareResp(state).fingerprintingId || '';

export const transactionsKeyId = (state: RootState): string => transactionsCcPrepareResp(state).keyId || '';

export const addCcResp = (state: RootState): AddCcResp => transactionsCcTree(state).addCcResp || {};

export const useCcPayResp = (state: RootState): UseCcPayResp => transactionsCcTree(state).useCcPayResp || {};
