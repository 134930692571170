import { withStylePrefix } from '../common/withStylePrefix';
import { DateRangePickerComponent, dateRangePickerTemplate } from './DateRangePicker';
import styles from './DateRangePicker.styles.scss';
export const chameleonDateRangePicker = DateRangePickerComponent.compose({
    baseName: 'date-range-picker',
    template: dateRangePickerTemplate,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
});
