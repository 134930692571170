import { __decorate } from "tslib";
import { attr, observable, html, DOM, repeat, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { NumberParser } from '@internationalized/number';
import { ChameleonElementMixin } from '../common/mixin';
import { getLocalTimeZone, parseDate, today, getMinimumMonthInYear, getMinimumDayInMonth, } from '@internationalized/date';
const EDITABLE_SEGMENTS = ['year', 'month', 'day'];
function isEditableSegment(segment) {
    return EDITABLE_SEGMENTS.includes(segment);
}
export const dateInputTemplate = (context, _definition) => {
    return html `<template>
      ${repeat((x) => x.dateParts, html `
          ${when((x) => x.type === 'literal', html `<span>${(x) => x.text}</span>`)}
          ${when((x) => x.type !== 'literal', html `<div
              id="${(x) => x.type}"
              contenteditable
              autocapitalize="off"
              spellcheck="false"
              autocorrect="off"
              enteykeyhint="next"
              inputmode="numeric"
              role="spinbutton"
              tabindex="0"
              aria-label="${(x) => x.type}"
              aria-valuenow="${(x) => (!x.isPlaceholder ? x.value : null)}"
              aria-valuetext="${(x) => (!x.isPlaceholder ? x.text : null)}"
              aria-valuemin="${(x) => x.minValue}"
              aria-valuemax="${(x) => x.maxValue}"
              @keydown=${(x, c) => c.parent.spinButtonKeyDown(x, c.event)}
              @focus=${(x, c) => c.parent.handleSpinButtonFocus(x)}
            >
              ${(x) => {
        return x.isPlaceholder ? x.placeholder : x.text;
    }}
            </div>`)}
        `)}
</template>`;
};
export class DateInputComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.timezone = getLocalTimeZone();
        /* The selected value of the DatePicker. This should always be in ISO-8601 format **/
        this.value = '';
        this.defaultValue = '';
        this.disabled = false;
        this.placeholder = 'MM-DD-YYYY';
        this.locale = navigator.language;
        this.dateParts = [];
        /**
         * This date is used initially when there are no default date set as
         * base value for when the user tries to cycle through the date segments
         * */
        this.placeholderDate = today(this.timezone);
        // This is used to get the default browser validation messages for certain invalid states.
        this.validationInput = document.createElement('input');
        this.internals = this.attachInternals();
    }
    checkValidity() {
        return this.internals.checkValidity();
    }
    reportValidity() {
        this.internals.reportValidity();
    }
    get validity() {
        return this.internals.validity;
    }
    get validationMessage() {
        return this.internals.validationMessage;
    }
    localeChanged() {
        if (!this.isConnected || !this.isLocaleValid(this.locale)) {
            return;
        }
        this.dateParts = this.calculateDateSegments();
    }
    timezoneChanged() {
        if (!this.isConnected || !this.isTimezoneValid(this.timezone)) {
            return;
        }
        this.dateParts = this.calculateDateSegments();
    }
    labelChanged() {
        this.internals.ariaLabel = this.label ?? null;
    }
    valueChanged(_prev, next) {
        if (!this.isConnected || !this.internals) {
            return;
        }
        let newDate = undefined;
        try {
            newDate = parseDate(next);
        }
        catch (e) {
            console.warn(e);
            if (!next) {
                this.dateParts = this.dateParts.map((c) => ({
                    ...c,
                    isPlaceholder: true,
                    enteredKeys: '',
                    value: 0,
                }));
            }
        }
        if (this.hasAttribute('required') && !this.value) {
            this.validationInput.setAttribute('required', 'true');
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ valueMissing: true }, message, this);
        }
        else if (this.hasAttribute('required')) {
            this.internals.setValidity({});
            this.validationInput.removeAttribute('required');
            this.validationInput.value = '';
        }
        this.internals.setFormValue(this.value);
        if (newDate) {
            // Creating a new variable that can't be undefined so the closure inside the array map doesn't expect an undefined value
            const dateToSet = newDate;
            this.dateParts = this.dateParts.map((c) => {
                if (c.type === 'literal') {
                    return c;
                }
                return this.createSegment(c, dateToSet, false);
            });
        }
        const date = this.value !== '' ? newDate?.toDate(this.timezone) : '';
        this.$emit('change', { date });
        this.validateMinAndMax(newDate);
    }
    spinButtonKeyDown(segment, event) {
        const parser = new NumberParser(this.locale, { maximumFractionDigits: 0 });
        switch (true) {
            case event.key === 'ArrowUp':
                if (segment.isPlaceholder) {
                    this.revealSegment(segment);
                }
                else {
                    this.increment(segment);
                }
                break;
            case event.key === 'ArrowDown':
                if (segment.isPlaceholder) {
                    this.revealSegment(segment);
                }
                else {
                    this.decrement(segment);
                }
                break;
            case event.key === 'Backspace':
                this.handleBackSpace(segment);
                break;
            case event.key === ' ':
                // In date no one car hear you space
                // Do nothing if space key is entered
                return false;
            case parser.isValidPartialNumber(event.key, segment.minValue, segment.maxValue): {
                this.updateSegment(segment, event.key);
                break;
            }
            // On tab we want it to work us usual so we return true to prevent fast from calling
            // .preventDefault on th event
            case event.key === 'Tab':
                return true;
        }
    }
    handleSpinButtonFocus(segment) {
        segment.enteredKeys = '';
    }
    handleBackSpace(segment) {
        const parser = new NumberParser(this.locale, { maximumFractionDigits: 0 });
        if (!parser.isValidPartialNumber(segment.text) || segment.isPlaceholder) {
            return;
        }
        const newValue = segment.text.slice(0, -1);
        const parsed = parser.parse(newValue);
        segment.enteredKeys = '';
        if (newValue.length === 0 || parsed === 0) {
            this.clearSegment(segment);
            if (!this.isDateValid(this.dateParts)) {
                this.value = '';
            }
        }
        else {
            const dateToUse = this.value ? parseDate(this.value) : this.placeholderDate;
            const newDate = dateToUse.set({ [segment.type]: parsed });
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
    }
    createSegment(segment, newDate, isPlaceholder = false) {
        return {
            ...segment,
            isPlaceholder,
            value: newDate[segment.type],
            text: this.getTextForPart(segment.type, newDate),
            ...this.getSegmentLimits(newDate, segment.type),
        };
    }
    clearSegment(segment) {
        this.placeholderDate = today(this.timezone);
        const newSegment = this.createSegment(segment, this.placeholderDate, true);
        this.setSegment(segment, newSegment, this.placeholderDate);
    }
    updateSegment(segment, value) {
        const parser = new NumberParser(this.locale, { maximumFractionDigits: 0 });
        const newValue = segment.enteredKeys + value;
        const numberValue = parser.parse(newValue);
        let valueToSet;
        if (numberValue <= segment.maxValue && numberValue >= segment.minValue) {
            valueToSet = numberValue;
        }
        else {
            valueToSet = parser.parse(value);
        }
        // create a variable that's the inverse of the following experssion: valueToSet === 0 && segment.minValue !== 0
        const shouldUpdate = valueToSet !== 0 || segment.minValue === 0;
        if (!shouldUpdate) {
            return;
        }
        let newSegment;
        if (this.value) {
            const newDate = parseDate(this.value).set({ [segment.type]: valueToSet });
            newSegment = this.createSegment(segment, newDate);
            if (newValue.length >= String(segment.maxValue).length || Number(numberValue + '0') > segment.maxValue) {
                newSegment.enteredKeys = '';
                if (shouldUpdate) {
                    DOM.queueUpdate(() => {
                        this.focusNextSegment(newSegment);
                    });
                }
            }
            else {
                newSegment.enteredKeys = newValue;
            }
            this.setSegment(segment, newSegment, newDate);
        }
        else {
            this.placeholderDate = this.placeholderDate.set({ [segment.type]: valueToSet });
            newSegment = this.createSegment(segment, this.placeholderDate);
            if (newValue.length >= String(segment.maxValue).length || Number(numberValue + '0') > segment.maxValue) {
                newSegment.enteredKeys = '';
                if (shouldUpdate) {
                    DOM.queueUpdate(() => {
                        this.focusNextSegment(newSegment);
                    });
                }
            }
            else {
                newSegment.enteredKeys = newValue;
            }
            this.setSegment(segment, newSegment, this.placeholderDate);
        }
    }
    focusNextSegment(segment) {
        const segmentIndex = this.dateParts.findIndex((part) => part.type === segment.type);
        const nextSegment = this.dateParts.find((part, index) => index > segmentIndex && part.type !== 'literal');
        if (!nextSegment) {
            return;
        }
        const elementToFocus = this.shadowRoot?.querySelector(`#${nextSegment.type}`);
        elementToFocus?.focus();
    }
    setSegment(oldSegment, newSegment, newDate) {
        let segmentIndex = this.dateParts.indexOf(oldSegment);
        if (segmentIndex < 0) {
            // When the keydown event is spammed, the event handler receives the same oldSegment twice
            // (because the eventHandler wasn't replaced, as no re-rendering has had time to happen yet)
            // After the first event, oldSegment is replaced in the array and we can't find it by reference.
            // We then fallback by searching by segment type
            segmentIndex = this.dateParts.findIndex((s) => s.type === oldSegment.type);
        }
        this.dateParts.splice(segmentIndex, 1, newSegment);
        this.dateParts = this.dateParts.map((segment) => {
            if (segment.type === 'literal') {
                return segment;
            }
            const newLimits = this.getSegmentLimits(newDate, segment.type);
            if (segment.value > newLimits.maxValue) {
                return {
                    ...segment,
                    ...newLimits,
                    value: newLimits.maxValue,
                    text: this.getTextForPart(segment.type, newDate),
                };
            }
            if (segment.value < newLimits.minValue) {
                return {
                    ...segment,
                    ...newLimits,
                    value: newLimits.minValue,
                    text: this.getTextForPart(segment.type, newDate),
                };
            }
            return {
                ...segment,
                ...newLimits,
            };
        });
        if (this.isDateValid(this.dateParts)) {
            this.value = newDate.toString();
        }
        else {
            this.placeholderDate = newDate;
        }
    }
    // Takes an array of DateSegments, return true if all of them has the isPlaceholder property set to false, false otherwise
    isDateValid(segments) {
        for (const segment of segments) {
            if (segment.type !== 'literal' && segment.isPlaceholder) {
                return false;
            }
        }
        return true;
    }
    revealSegment(segment) {
        const segmentIndex = this.dateParts.findIndex((part) => part.type === segment.type);
        const newSegment = this.createSegment(segment, this.placeholderDate);
        this.dateParts.splice(segmentIndex, 1, newSegment);
        if (this.isDateValid(this.dateParts)) {
            this.value = this.placeholderDate.toString();
        }
    }
    increment(segment) {
        segment.enteredKeys = '';
        if (!isEditableSegment(segment.type)) {
            return;
        }
        if (this.value) {
            const newDate = parseDate(this.value).cycle(segment.type, 1);
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
        else {
            this.placeholderDate = this.placeholderDate.cycle(segment.type, 1);
            const newSegment = this.createSegment(segment, this.placeholderDate);
            this.setSegment(segment, newSegment, this.placeholderDate);
        }
    }
    decrement(segment) {
        segment.enteredKeys = '';
        if (!isEditableSegment(segment.type)) {
            return;
        }
        if (this.value) {
            const newDate = parseDate(this.value).cycle(segment.type, -1);
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
        else {
            const newDate = this.placeholderDate.cycle(segment.type, -1);
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.isLocaleValid(this.locale) && !this.isTimezoneValid(this.locale)) {
            return;
        }
        this.dateParts = this.calculateDateSegments();
        if (this.defaultValue) {
            this.value = this.defaultValue;
        }
        else {
            this.internals.setFormValue('');
        }
        if (this.hasAttribute('required') && !this.value) {
            this.validationInput.setAttribute('required', 'true');
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ valueMissing: true }, message, this);
        }
    }
    get localizedParts() {
        const dateFormat = new Intl.DateTimeFormat(this.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: this.timezone,
        });
        return dateFormat.formatToParts(today(this.timezone).toDate(this.timezone));
    }
    calculateDateSegments() {
        const dateFormat = new Intl.DateTimeFormat(this.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: this.timezone,
        });
        const parts = dateFormat.formatToParts(today(this.timezone).toDate(this.timezone));
        const valueSet = this.value ? parseDate(this.value) : this.defaultValue ? parseDate(this.defaultValue) : null;
        this.placeholderDate = today(this.timezone);
        const value = valueSet ? valueSet : this.placeholderDate;
        // Add minValue and maxValue to make key inputs works later
        return parts.map((part) => ({
            enteredKeys: '',
            type: part.type,
            isPlaceholder: !valueSet,
            placeholder: part.type === 'literal' ? undefined : this.getPlaceholderForPart(part),
            isEditable: part.type !== 'literal',
            text: value && part.type !== 'literal' ? this.getTextForPart(part.type, value) : part.value,
            value: part.type !== 'literal' ? value[part.type] : part.value,
            ...this.getSegmentLimits(value, part.type),
        }));
    }
    getSegmentLimits(date, type) {
        switch (type) {
            case 'year': {
                return {
                    minValue: 1,
                    maxValue: date.calendar.getYearsInEra(date),
                };
            }
            case 'month': {
                return {
                    minValue: getMinimumMonthInYear(date),
                    maxValue: date.calendar.getMonthsInYear(date),
                };
            }
            case 'day': {
                return {
                    minValue: getMinimumDayInMonth(date),
                    maxValue: date.calendar.getDaysInMonth(date),
                };
            }
        }
    }
    getPlaceholderForPart(part) {
        const localizedPartNames = new Intl.DisplayNames(this.locale, { type: 'dateTimeField' });
        return localizedPartNames.of(part.type).slice(0, 1).toUpperCase().repeat(part.value.length);
    }
    getTextForPart(type, value) {
        const formatter = new Intl.DateTimeFormat(this.locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: this.timezone,
        });
        const parts = formatter.formatToParts(value.toDate(this.timezone));
        const res = parts
            .filter((part) => part.type === type)
            .map((part) => part.value)
            .join('');
        return res;
    }
    isLocaleValid(locale) {
        try {
            Intl.getCanonicalLocales(locale);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    isTimezoneValid(timezone) {
        try {
            Intl.DateTimeFormat(undefined, { timeZone: timezone });
            return true;
        }
        catch (e) {
            return false;
        }
    }
    validateMinAndMax(calendarDate) {
        if (!this.value) {
            return;
        }
        if (!calendarDate) {
            return;
        }
        const date = calendarDate;
        const isBeforeMin = !this.min ? false : parseDate(this.min).compare(date) > 0;
        const isAfterMax = !this.max ? false : parseDate(this.max).compare(date) < 0;
        if (isBeforeMin && this.min) {
            this.validationInput.setAttribute('type', 'date');
            this.validationInput.setAttribute('min', this.min);
            this.validationInput.value = this.value;
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ rangeUnderflow: true }, message, this);
        }
        if (isAfterMax) {
            this.validationInput.setAttribute('type', 'number');
            this.validationInput.setAttribute('max', '10');
            this.validationInput.value = '11';
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ rangeOverflow: true }, message, this);
        }
        this.validationInput.removeAttribute('min');
        this.validationInput.removeAttribute('max');
    }
}
DateInputComponent.formAssociated = true;
__decorate([
    attr({ attribute: 'label' })
], DateInputComponent.prototype, "label", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "fieldsize", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "timezone", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "value", void 0);
__decorate([
    attr({ attribute: 'default-value' })
], DateInputComponent.prototype, "defaultValue", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "disabled", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "placeholder", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "locale", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "min", void 0);
__decorate([
    attr
], DateInputComponent.prototype, "max", void 0);
__decorate([
    observable
], DateInputComponent.prototype, "dateParts", void 0);
