import { __decorate } from "tslib";
import { attr, observable, html, ref, DOM, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { getLocalTimeZone } from '@internationalized/date';
import { CalendarComponent } from '../Calendar';
import { PopoverV2Component } from '../PopoverV2';
import { SVG_CALENDAR_OUTLINED } from '@getgo/chameleon-icons';
import { IconButtonComponent } from '../IconButton';
import { SvgComponent } from '../Svg';
import { TypographyComponent } from '../Typography';
import { DateInputComponent } from '../DateInput';
const POPOVER_OFFSET_TOP_WITH_LABEL = '25px';
const POPOVER_OFFSET_TOP_WITHOUT_LABEL = '3px';
const POPOVER_OFFSET_BOTTOM_WITH_HELPER = '-27px';
const POPOVER_OFFSET_BOTTOM_WITHOUT_HELPER = '-8px';
export const datePickerTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    const popoverV2Tag = context.tagFor(PopoverV2Component);
    const calendarTag = context.tagFor(CalendarComponent);
    const dateInputTag = context.tagFor(DateInputComponent);
    return html `<template>
    ${when((x) => x.inputLabel, html `<${typographyTag} class="input-label" variant="caption-medium-strong" color="type-color-default">
      ${(x) => x.inputLabel}
    </${typographyTag}>`)}
    <div tabindex="-1" ${ref('field')} class="input-container">
      <div class="control">
        <${dateInputTag} ${ref('dateInput')} label=${(x) => x.inputLabel} timezone=${(x) => x.timezone} value=${(x) => x.value} default-value=${(x) => x.defaultValue} ?disabled=${(x) => x.disabled} placeholder=${(x) => x.placeholder} locale=${(x) => x.locale} min=${(x) => x.min} max=${(x) => x.max}
        @change=${(x, ctx) => {
        x.value = ctx.event.target.value;
    }}></${dateInputTag}>
      </div>
      <${iconButtonTag}
        size="${(x) => x.fieldsize}"
        label="${(x) => x.calendarToggleLabel}"
        id="trigger"
        slot="end"
        disabled="${(x) => x.disabled}"
      >
        <${svgTag}>${SVG_CALENDAR_OUTLINED}</${svgTag}>
      </${iconButtonTag}>
    </div>
    <${popoverV2Tag}
      trigger-id="trigger"
      slot="end"
      :position="${(x) => x.position}"
      ${ref('popoverElement')}
      :anchorElement=${(el) => el.field}
      @popoverchange=${(x) => x.calculatePopoverOffset()}
      @position-changed=${(x) => x.calculatePopoverOffset()}
      compact
    >
      <div role="rowgroup">
        <${calendarTag}
          ${ref('calendar')}
          locale="${(x) => x.locale}"
          timezone="${(x) => x.timezone}"
          error="${(x) => x.error}"
          min="${(x) => x.min}"
          max="${(x) => x.max}"
          value=${(x) => x.value}
          @ch-calendar-change="${(x, c) => x.handleCalendarChange(c.event)}"
        >        <slot slot="header" name="header"></slot></${calendarTag}>
      </div>
    </${popoverV2Tag}>
    ${when((x) => x.helperText, html `<${typographyTag} id="text" class="helpertext" variant="caption-medium" color="type-color-secondary"
        >${(x) => x.helperText}</${typographyTag}
      >`)}
  </template>`;
};
export class DatePickerComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.timezone = getLocalTimeZone();
        /* The selected value of the DatePicker. This should always be in ISO-8601 format **/
        this.value = '';
        this.defaultValue = '';
        this.disabled = false;
        this.error = false;
        this.placeholder = 'MM-DD-YYYY';
        this.position = 'bottom-start';
        this.locale = navigator.language;
        this.calendarToggleLabel = '';
        // This is used to get the default browser validation messages for certain invalid states.
        this.validationInput = document.createElement('input');
        this.internals = this.attachInternals();
    }
    reportValidity() {
        return this.internals.reportValidity();
    }
    get validationMessage() {
        return this.internals.validationMessage;
    }
    valueChanged(_prev, next) {
        if (this.isConnected && this.internals) {
            this.internals.setFormValue(next);
            const isRequired = this.hasAttribute('required');
            this.validationInput.toggleAttribute('required', isRequired);
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ customError: isRequired && !next }, message, this.field);
        }
    }
    checkValidity() {
        return this.internals.checkValidity();
    }
    get validity() {
        return this.internals.validity;
    }
    willValidate() {
        return this.internals.willValidate;
    }
    inputLabelChanged() {
        this.internals.ariaLabel = this.inputLabel ?? null;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.isLocaleValid(this.locale) && !this.isTimezoneValid(this.locale)) {
            return;
        }
        if (this.defaultValue) {
            this.value = this.defaultValue;
        }
        else {
            this.internals.setFormValue('');
        }
        if (this.hasAttribute('required')) {
            this.validationInput.toggleAttribute('required', true);
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ valueMissing: true }, message, this.field);
        }
    }
    // TODO: reimplement this correctly
    handleCalendarChange(e) {
        const newDate = e.detail.customDate;
        this.value = newDate.toString();
        // We delay closing here to prevent the popover automatically reopening when the date
        // is selected using a keyboard.
        DOM.queueUpdate(() => {
            this.popoverElement.close();
        });
    }
    calculatePopoverOffset() {
        if (!this.popoverElement.isOpen) {
            return;
        }
        const position = this.popoverElement?.dataset.renderedPosition ?? this.position;
        const isBottom = position.includes('bottom');
        const hasHelperText = this.helperText && this.helperText.length > 0;
        const hasLabel = this.inputLabel && this.inputLabel.length > 0;
        if (isBottom && hasHelperText) {
            this.popoverOffset = POPOVER_OFFSET_BOTTOM_WITH_HELPER;
        }
        if (isBottom && !hasHelperText) {
            this.popoverOffset = POPOVER_OFFSET_BOTTOM_WITHOUT_HELPER;
        }
        if (!isBottom && hasLabel) {
            this.popoverOffset = POPOVER_OFFSET_TOP_WITH_LABEL;
        }
        if (!isBottom && !hasLabel) {
            this.popoverOffset = POPOVER_OFFSET_TOP_WITHOUT_LABEL;
        }
    }
    isLocaleValid(locale) {
        try {
            Intl.getCanonicalLocales(locale);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    isTimezoneValid(timezone) {
        try {
            Intl.DateTimeFormat(undefined, { timeZone: timezone });
            return true;
        }
        catch (e) {
            return false;
        }
    }
}
DatePickerComponent.formAssociated = true;
__decorate([
    attr({ attribute: 'input-label' })
], DatePickerComponent.prototype, "inputLabel", void 0);
__decorate([
    attr({ attribute: 'helper-text' })
], DatePickerComponent.prototype, "helperText", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "fieldsize", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "timezone", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "value", void 0);
__decorate([
    attr({ attribute: 'default-value' })
], DatePickerComponent.prototype, "defaultValue", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "disabled", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "error", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "placeholder", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "position", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "locale", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "min", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "max", void 0);
__decorate([
    attr({ attribute: 'calendar-toggle-label' })
], DatePickerComponent.prototype, "calendarToggleLabel", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "field", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "popoverElement", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "calendar", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "popoverOffset", void 0);
