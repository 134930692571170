import config from 'config';
import { AddDDPayload, UseDDPayPayload } from 'types/transaction-dd';
import fetchWithToken from './provider/fetch';

const transactionsDDEndPoint = (accountKey: string, sessionId: string): string =>
  `${config.COPAS_URL}/payment/ui/cip/v1/${accountKey}/session/${sessionId}/transaction/dd`;

export const daoTransactionDDAddPost = (accountKey: string, sessionId: string, data: AddDDPayload): Promise<object> =>
  fetchWithToken({
    reqUrl: `${transactionsDDEndPoint(accountKey, sessionId)}/add`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoTransactionDDPayPost = (
  accountKey: string,
  sessionId: string,
  data: UseDDPayPayload,
): Promise<object> =>
  fetchWithToken({
    reqUrl: `${transactionsDDEndPoint(accountKey, sessionId)}/pay`,
    method: 'POST',
    body: JSON.stringify(data),
  });
