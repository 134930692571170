import { withStylePrefix } from '../../common/withStylePrefix';
import { TabComponent } from './Tab';
import styles from './Tab.styles.scss';
import { html } from '@microsoft/fast-element';
const chameleonTabTemplate = html `
  <template slot="tab" role="tab" aria-disabled="${(x) => x.disabled}">
    <slot slot="start" name="start"></slot>
    <slot></slot>
  </template>
`;
export const chameleonTabDefinition = {
    baseName: 'tab',
    template: chameleonTabTemplate,
    styles: withStylePrefix(styles),
};
export const chameleonTab = TabComponent.compose(chameleonTabDefinition);
