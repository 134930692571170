import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonOption } from '../Select/Option.definition';
import { chameleonSvg } from '../Svg/Svg.definition';
import { chameleonAutocomplete } from './Autocomplete.definition';
import { chameleonSkeleton } from '../Skeleton/Skeleton.definition';
import { chameleonChipV2 } from '../ChipV2/ChipV2.definition';
import { chameleonIconButton } from '../IconButton/IconButton.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonAutocomplete({ prefix }), chameleonOption({ prefix }), chameleonSvg({ prefix }), chameleonIconButton({ prefix }), chameleonChipV2({ prefix }), chameleonSkeleton({ prefix }));
}
