import { __decorate } from "tslib";
import { SVG_CHEVRON_LEFT, SVG_CHEVRON_RIGHT } from '@getgo/chameleon-icons';
import { constructCurrentMonth, constructNextMonth, constructPreviousMonth, formatNumber, getAriaLabelForDay, getDatesInWeek, getTabIndexForDay, getWeekDays, isAfterMax, isBeforeMin, isDayInactive, isLocaleValid, isTimezoneValid, } from '../common/CalendarUtils';
import { TypographyComponent } from '../Typography';
import { IconButtonComponent } from '../IconButton';
// biome-ignore lint/style/useImportType: The autofix breaks the code
import { attr, DOM, html, observable, repeat } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ButtonComponent } from '../Button';
// biome-ignore lint/style/useImportType: The autofix breaks the code
import { isSameDay, today, getLocalTimeZone, parseDate, getWeeksInMonth, startOfMonth, isSameMonth, } from '@internationalized/date';
import { ChameleonElementMixin } from '../common/mixin';
import { SvgComponent } from '../Svg';
export const rangeCalendarTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const buttonTag = context.tagFor(ButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    return html `
  <${typographyTag} class="title" variant="heading-small">
    <slot name="header"></slot>
  </${typographyTag}>
  <div class="header">
    <${iconButtonTag}
      size="small"
      aria-label="${(x) => isLocaleValid(x.locale) && constructPreviousMonth(x.locale)}"
      @click=${(x) => x.handlePreviousMonthClick()}
      ><${svgTag}>${SVG_CHEVRON_LEFT}</${svgTag}></${iconButtonTag}
    >
    <h2>${(x) => x.month}</h2>
    <${buttonTag} size="small" class="today-button" variant="tertiary" @click=${(x) => x.handleTodayButtonClick()}
      >${(x) => isLocaleValid(x.locale) && constructCurrentMonth(x.locale)}</${buttonTag}
    >
    <${iconButtonTag}
      size="small"
      aria-label="${(x) => isLocaleValid(x.locale) && constructNextMonth(x.locale)}"
      @click=${(x) => x.handleNextMonthClick()}>
      <${svgTag}>${SVG_CHEVRON_RIGHT}</${svgTag}>
    </${iconButtonTag}>
  </div>
  <table>
    <thead>
      <tr>
        ${repeat((x) => x.weekDays, html `<td>${(x) => x}</td>`)}
      </tr>
    </thead>
    <tbody>
      ${repeat((x) => x.weeks, html `
          <tr>
            ${repeat((x) => x, html `
                <td role="gridcell" class="cell
                  ${(x, c) => (c.parentContext.parent.isInRange(x) ? 'in-range' : '')}
                  ${(x, c) => c.parentContext.parent.selectedStartDate && isSameDay(x, c.parentContext.parent.selectedStartDate)
        ? 'start-date'
        : ''}
                         ${(x, c) => c.parentContext.parent.selectedEndDate &&
        isSameDay(x, c.parentContext.parent.selectedEndDate)
        ? 'end-date'
        : ''}
                ">
                  <span
                    tabindex="${(x, c) => getTabIndexForDay(x, c.parentContext.parent.focusedDate)}"
                    role="button"
                    aria-label="${(x, c) => getAriaLabelForDay(x, c.parentContext.parent.locale, c.parentContext.parent.timezone)}"
                    data-value="${(x) => x.toString()}"
                    class="day
                        ${(x, c) => (c.parentContext.parent.selectedStartDate &&
        isSameDay(x, c.parentContext.parent.selectedStartDate)) ||
        (c.parentContext.parent.selectedEndDate &&
            isSameDay(x, c.parentContext.parent.selectedEndDate))
        ? 'selected'
        : ''}
                        ${(x, c) => (isSameDay(x, today(c.parentContext.parent.timezone)) ? 'today' : '')}
                        ${(x, c) => (isDayInactive(x, c.parentContext.parent.focusedDate, c.parentContext.parent.min, c.parentContext.parent.max) ? 'inactive' : '')}
                        "
                    @click=${(x, c) => c.parentContext.parent.handleDayClick(x)}
                    @keydown=${(x, c) => c.parentContext.parent.handleDayKeyDown(c.event, x)}
                  >
                    ${(x, c) => formatNumber(c.parentContext.parent.locale, x.day)}
                  </span>
                </td>
              `)}
          </tr>
        `)}
    </tbody>
  </table>
`;
};
export class RangeCalendarComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.locale = navigator.language;
        this.timezone = getLocalTimeZone();
        this.weeks = [];
        this.weekDays = [];
        this.month = '';
        /**
         * Represents the currently focused or focusable date.
         * Scenarios where this can be different from `this.date`:
         * - When there's no selected date, this.date is undefined, but this is initialized to the current day
         * - When there's a selected date, but the user has gone to the next/previous month.
         * */
        this.focusedDate = this.start ? parseDate(this.start) : this.end ? parseDate(this.end) : today(this.timezone);
    }
    connectedCallback() {
        super.connectedCallback();
        if (isLocaleValid(this.locale) && isTimezoneValid(this.timezone)) {
            this.month = this.getMonth();
            this.weeks = this.getWeeks();
            this.weekDays = getWeekDays(this.timezone, this.locale);
            if (this.start)
                this.selectStart(parseDate(this.start));
            if (this.end)
                this.selectEnd(parseDate(this.end));
        }
    }
    startChanged() {
        try {
            if (this.start && this.start !== '') {
                this.selectStart(parseDate(this.start));
            }
            if (this.start === '') {
                this.selectedStartDate = undefined;
            }
        }
        catch { }
    }
    endChanged() {
        try {
            if (this.end && this.end !== '') {
                this.selectEnd(parseDate(this.end));
            }
            if (this.end === '') {
                this.selectedEndDate = undefined;
            }
        }
        catch { }
    }
    localeChanged() {
        if (!isLocaleValid(this.locale)) {
            return;
        }
        this.weeks = this.getWeeks();
        this.weekDays = getWeekDays(this.timezone, this.locale);
    }
    timezoneChanged() {
        if (!isTimezoneValid(this.timezone)) {
            return;
        }
        this.weeks = this.getWeeks();
        this.weekDays = getWeekDays(this.timezone, this.locale);
    }
    getWeeks(base) {
        const n = base ? base : this.start ? parseDate(this.start) : this.end ? parseDate(this.end) : today(this.timezone);
        const normalized = n.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        return [...new Array(getWeeksInMonth(startOfMonth(normalized), this.locale)).keys()].map((i) => {
            return getDatesInWeek(this.locale, i, startOfMonth(normalized));
        });
    }
    getMonth(base) {
        const date = base
            ? base
            : this.start
                ? parseDate(this.start)
                : this.end
                    ? parseDate(this.end)
                    : today(this.timezone);
        return new Intl.DateTimeFormat(this.locale, { month: 'long', year: 'numeric' }).format(date.toDate(this.timezone));
    }
    handleDayClick(date) {
        this.selectDay(date);
    }
    handleNextMonthClick() {
        this.focusedDate = this.focusedDate.add({ months: 1 });
        this.refreshDaysInCalendar();
    }
    handlePreviousMonthClick() {
        this.focusedDate = this.focusedDate.subtract({ months: 1 });
        this.refreshDaysInCalendar();
    }
    handleTodayButtonClick() {
        const currentDay = today(this.timezone);
        if (isBeforeMin(currentDay, this.min) || isAfterMax(currentDay, this.max)) {
            return;
        }
        this.focusedDate = currentDay;
        this.refreshDaysInCalendar();
    }
    handleDayKeyDown(e, day) {
        switch (e.code) {
            case 'ArrowUp':
                this.moveToPrevWeek(day);
                // Prevent scrolling
                return false;
            case 'ArrowDown':
                this.moveToNextWeek(day);
                // Prevent scrolling
                return false;
            case 'ArrowLeft':
                this.moveToPrevDay(day);
                break;
            case 'ArrowRight':
                this.moveToNextDay(day);
                break;
            case 'Enter':
                this.selectDay(day);
                break;
            case 'Space':
                this.selectDay(day);
                break;
            default:
                break;
        }
        // We have to return true to prevent fast calling .preventDefault on the event
        // we need the default behaviour so the Tab keypress properly moves focus to the next element
        return true;
    }
    moveToNextWeek(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.add({ weeks: 1 });
        if (isAfterMax(newDate, this.max)) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            const nextDayEl = this.shadowRoot?.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    moveToPrevWeek(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.subtract({ weeks: 1 });
        if (isBeforeMin(newDate, this.min)) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            const nextDayEl = this.shadowRoot?.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    moveToPrevDay(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.subtract({ days: 1 });
        if (isBeforeMin(newDate, this.min)) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            const nextDayEl = this.shadowRoot?.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    moveToNextDay(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.add({ days: 1 });
        if (isAfterMax(newDate, this.max)) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            const nextDayEl = this.shadowRoot?.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    selectDay(day) {
        const event = {
            start: this.selectedStartDate ? this.selectedStartDate.toString() : undefined,
            end: this.selectedEndDate ? this.selectedEndDate.toString() : undefined,
        };
        if (!this.start || (this.start && this.end)) {
            // We only want to check this section if we only have an end date
            if (this.selectedEndDate && day.compare(this.selectedEndDate) > 0 && !this.start) {
                this.selectStart(this.selectedEndDate);
                this.selectEnd(day);
                event.end = day.toString();
                event.start = this.start;
                this.$emit('ch-range-calendar-change', event);
                return;
            }
            // We need to clear the value of the end date selection because if we don't,
            // then the selection will stick in the calendar,
            // and we want to reset the range when we select the start date
            // Todo: In further stories we might have to change this logic [CHAMELEON-3005]
            if (this.selectedStartDate) {
                this.selectedEndDate = undefined;
                this.end = undefined;
                event.end = undefined;
            }
            this.selectStart(day);
            event.start = day.toString();
            this.$emit('ch-range-calendar-change', event);
            return;
        }
        if (!this.end) {
            if (day.compare(this.selectedStartDate) < 0) {
                this.selectEnd(this.selectedStartDate);
                this.selectStart(day);
                event.start = day.toString();
                event.end = this.end;
                this.$emit('ch-range-calendar-change', event);
                return;
            }
            this.selectEnd(day);
            event.end = day.toString();
            this.$emit('ch-range-calendar-change', event);
            return;
        }
    }
    refreshDaysInCalendar() {
        this.weeks = this.getWeeks(this.focusedDate);
        this.month = this.getMonth(this.focusedDate);
    }
    selectStart(day) {
        if (this.selectedStartDate && this.start) {
            this.selectedStartDate = day;
            this.start = day.toString();
            this.focusedDate = day;
        }
        if (!this.selectedStartDate)
            this.selectedStartDate = day;
        if (!this.start)
            this.start = day.toString();
    }
    selectEnd(day) {
        if (this.end) {
            this.selectedEndDate = day;
            this.end = day.toString();
            this.focusedDate = day;
        }
        if (!this.selectedEndDate)
            this.selectedEndDate = day;
        if (!this.end)
            this.end = day.toString();
    }
    isInRange(date) {
        // Check if the date is later than the selected start date
        // When isAfterStartDate is equal or less than 0 we know it is after or exactly the selectedStartDate
        const isAfterStartDate = this.selectedStartDate && parseDate(this.selectedStartDate.toString()).compare(date) <= 0;
        // When isBeforeEndDate is equal or higher than 0 we know it is before or exactly the selectedEndDate
        const isBeforeEndDate = this.selectedEndDate && parseDate(this.selectedEndDate.toString()).compare(date) >= 0;
        // If either the isAfterStartDate or the isBeforeEndDate is falsy we can be sure that the date is not in the range
        return isAfterStartDate && isBeforeEndDate;
    }
}
__decorate([
    attr
], RangeCalendarComponent.prototype, "locale", void 0);
__decorate([
    attr
], RangeCalendarComponent.prototype, "timezone", void 0);
__decorate([
    attr
], RangeCalendarComponent.prototype, "value", void 0);
__decorate([
    attr
], RangeCalendarComponent.prototype, "start", void 0);
__decorate([
    attr
], RangeCalendarComponent.prototype, "end", void 0);
__decorate([
    attr
], RangeCalendarComponent.prototype, "min", void 0);
__decorate([
    attr
], RangeCalendarComponent.prototype, "max", void 0);
__decorate([
    observable
], RangeCalendarComponent.prototype, "weeks", void 0);
__decorate([
    observable
], RangeCalendarComponent.prototype, "weekDays", void 0);
__decorate([
    observable
], RangeCalendarComponent.prototype, "month", void 0);
__decorate([
    observable
], RangeCalendarComponent.prototype, "selectedStartDate", void 0);
__decorate([
    observable
], RangeCalendarComponent.prototype, "selectedEndDate", void 0);
__decorate([
    observable
], RangeCalendarComponent.prototype, "focusedDate", void 0);
