import { TextArea, textAreaTemplate as template } from '@microsoft/fast-foundation';
import { TextAreaComponent } from './TextArea';
import styles from './TextArea.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const chameleonTextArea = TextAreaComponent.compose({
    baseName: 'text-area',
    baseClass: TextArea,
    template: template,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
});
