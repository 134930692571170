import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  daoPaymentMethodsDefaultSetPost,
  daoPaymentMethodsDeletePost,
  daoPaymentMethodsPost,
  daoPaymentMethodTypesPost,
} from 'dao/payment-methods-dao';
import { Action } from 'types/action';
import {
  AchDetails,
  CreditCard,
  DdDetails,
  PaymentMethodDeletePayload,
  PaymentMethodLimits,
  PaymentMethodSetDefaultPayload,
  PaymentMethodsState,
  PaypalAgreements,
} from 'types/payment-method';
import { Thunk } from 'types/root';

export const postPaymentMethods = createAsyncThunk<PaymentMethodsState, void, Thunk>(
  'paymentMethods/get',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoPaymentMethodsPost(accountKey, sessionId);
    return response as PaymentMethodsState;
  },
);

export const postPaymentMethodsDelete = createAsyncThunk<PaymentMethodsState, PaymentMethodDeletePayload, Thunk>(
  'paymentMethods/delete',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoPaymentMethodsDeletePost(accountKey, sessionId, payload);
    return response as PaymentMethodsState;
  },
);

export const postPaymentMethodsSetDefault = createAsyncThunk<
  PaymentMethodsState,
  PaymentMethodSetDefaultPayload,
  Thunk
>('paymentMethods/setDefault', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const {
    globalWrapper: { accountKey, sessionId },
  } = state;
  const response = await daoPaymentMethodsDefaultSetPost(accountKey, sessionId, payload);
  return response as PaymentMethodsState;
});

export const postPaymentMethodTypes = createAsyncThunk<PaymentMethodsState, void, Thunk>(
  'paymentMethods/types',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoPaymentMethodTypesPost(accountKey, sessionId);
    return response as PaymentMethodsState;
  },
);

const initialState: PaymentMethodsState = {
  isLoading: false,
  isPaymentTypeLoading: false,
  isDeletePaymentMethodLoading: false,
  isSetDefaultPaymentLoading: false,
  creditCards: [] as CreditCard[],
  ach: [] as AchDetails[],
  directDebits: [] as DdDetails[],
  payPalAgreements: [] as PaypalAgreements[],
  paymentMethodLimits: {} as PaymentMethodLimits,
  paymentMethodTypes: [],
  payNowIntent: '',
  paymentMethodIntent: '',
  chosenPaymentMethodKey: '',
};

const paymentMethodsSlice = createSlice({
  name: 'payment-methods',
  initialState,
  reducers: {
    setPayNowIntent(state, action) {
      state.payNowIntent = action.payload;
    },
    setPaymentMethodIntent(state, action) {
      state.paymentMethodIntent = action.payload;
    },
    setChosenPaymentMethodKey(state, action) {
      state.chosenPaymentMethodKey = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postPaymentMethods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPaymentMethods.fulfilled, (state, action: Action) => {
        const { creditCards, directDebits, achBankAccounts, paymentMethodLimits, payPalAgreements } = action.payload;
        state.isLoading = false;
        state.creditCards = creditCards;
        state.ach = achBankAccounts;
        state.directDebits = directDebits;
        state.payPalAgreements = payPalAgreements;
        state.paymentMethodLimits = paymentMethodLimits?.limits;
      })
      .addCase(postPaymentMethods.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postPaymentMethodsDelete.pending, (state) => {
        state.isDeletePaymentMethodLoading = true;
      })
      .addCase(postPaymentMethodsDelete.fulfilled, (state) => {
        state.isDeletePaymentMethodLoading = false;
      })
      .addCase(postPaymentMethodsDelete.rejected, (state) => {
        state.isDeletePaymentMethodLoading = false;
      })
      .addCase(postPaymentMethodsSetDefault.pending, (state) => {
        state.isSetDefaultPaymentLoading = true;
      })
      .addCase(postPaymentMethodsSetDefault.fulfilled, (state) => {
        state.isSetDefaultPaymentLoading = false;
      })
      .addCase(postPaymentMethodsSetDefault.rejected, (state) => {
        state.isSetDefaultPaymentLoading = false;
      })
      .addCase(postPaymentMethodTypes.pending, (state) => {
        state.isPaymentTypeLoading = true;
      })
      .addCase(postPaymentMethodTypes.fulfilled, (state, action: Action) => {
        state.isPaymentTypeLoading = false;
        state.paymentMethodTypes = action.payload;
      })
      .addCase(postPaymentMethodTypes.rejected, (state) => {
        state.isPaymentTypeLoading = false;
      });
  },
});

export const { setPayNowIntent, setPaymentMethodIntent, setChosenPaymentMethodKey } = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
