import { FoundationElement, Menu } from '@microsoft/fast-foundation';
import { debounce } from 'lodash-es';
import { ChameleonElementMixin } from '../common/mixin';
export class FASTElementHeightOverflowObserver extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.observerCallback = debounce((entries) => {
            entries.forEach((entry) => this.handleElementHeightOverflow(entry.target));
        }, 200);
        this.observer = new ResizeObserver(this.observerCallback);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.observerCallback.cancel();
        this.observer.disconnect();
    }
}
export class NavigationRailHeightOverflowObserver extends ChameleonElementMixin(Menu) {
    constructor() {
        super(...arguments);
        this.childrenObserverCallback = debounce((mutations) => {
            if (mutations.some((mutation) => mutation.type === 'childList')) {
                mutations.forEach((entry) => this.invalidateOverflow(entry.target));
            }
        }, 200);
        this.resizeObserverCallback = debounce((entries) => {
            entries.forEach((entry) => this.invalidateOverflow(entry.target));
        }, 200);
    }
    connectedCallback() {
        super.connectedCallback();
        this.childrenObserver = new MutationObserver(this.childrenObserverCallback);
        this.resizeObserver = new ResizeObserver(this.resizeObserverCallback);
        this.resizeObserver?.observe(this);
        this.childrenObserver?.observe(this, { childList: true });
    }
    disconnectedCallback() {
        this.resizeObserverCallback.cancel();
        this.resizeObserver?.disconnect();
        this.childrenObserverCallback.cancel();
        this.childrenObserver?.disconnect();
        this.childrenObserver = undefined;
        this.resizeObserver = undefined;
        super.disconnectedCallback();
    }
    invalidateOverflow(target) {
        this.childrenObserver?.disconnect();
        try {
            if (target.nodeType === Node.ELEMENT_NODE) {
                this.validateOverflow(target);
            }
        }
        finally {
            this.childrenObserver?.observe(this, { childList: true });
        }
    }
}
