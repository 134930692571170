import { __rest } from "tslib";
import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const Button = forwardRef((_a, ref) => {
    var { labelId, isLoading, children, leadingIcon, trailingIcon, autoFocus } = _a, props = __rest(_a, ["labelId", "isLoading", "children", "leadingIcon", "trailingIcon", "autoFocus"]);
    return createReactWrapper({
        tagName: 'chameleon-button',
        props: Object.assign(Object.assign({}, props), { 'is-loading': isLoading, 'label-id': labelId, autofocus: autoFocus, ref }),
        children: (_jsxs(_Fragment, { children: [leadingIcon && addExtraProps(leadingIcon, { slot: 'start' }), children, trailingIcon && addExtraProps(trailingIcon, { slot: 'end' })] })),
    });
});
