import { __decorate } from "tslib";
import { Radio } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
import { SVG_ALERT_OUTLINED } from '@getgo/chameleon-icons';
export class RadioButtonComponent extends ChameleonPrefixedElementMixin(Radio, 'radio') {
    constructor() {
        super(...arguments);
        this.errIcon = document.createElement(this.tagFor('svg'));
        this.msg = document.createElement(this.tagFor('typography'));
        this.partError = document.createElement('div');
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        if (this.errorText) {
            this.appendErrorState();
        }
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    setAccessibleLabel() {
        if (this.textContent?.length) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-label')?.length && !this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: Each Radio Button component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId 
  3) a non-empty label attribute to be used as aria-label.
`);
        }
    }
    errorTextChanged(oldValue, newValue) {
        if (newValue && oldValue !== newValue) {
            this.appendErrorState();
        }
        else if (this.partError && oldValue !== newValue) {
            this.removeErrorState();
        }
    }
    appendErrorState() {
        if (!this.errorText) {
            return;
        }
        this.classList.add('has-error');
        this.partError.setAttribute('part', 'error');
        this.errIcon.classList.add('error-icon');
        this.errIcon.innerHTML = SVG_ALERT_OUTLINED;
        this.msg.classList.add('error-text');
        this.msg.setAttribute('color', 'type-color-danger');
        this.msg.setAttribute('variant', 'caption-medium');
        this.msg.textContent = this.errorText;
        this.setAttribute('aria-invalid', 'true');
        this.shadowRoot?.appendChild(this.errIcon);
        this.shadowRoot?.appendChild(this.msg);
    }
    removeErrorState() {
        this.classList.remove('has-error');
        this.errIcon.remove();
        this.msg.remove();
        this.removeAttribute('aria-invalid');
    }
}
__decorate([
    attr({ attribute: 'label-id' })
], RadioButtonComponent.prototype, "labelId", void 0);
__decorate([
    attr
], RadioButtonComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'error-text' })
], RadioButtonComponent.prototype, "errorText", void 0);
