import { withStylePrefix } from '../common/withStylePrefix';
import { ChipComponent, chipTemplate } from './Chip';
import styles from './Chip.styles.scss';
export const chipDefinition = {
    template: chipTemplate,
    styles: withStylePrefix(styles),
};
export const chameleonChip = ChipComponent.compose({
    baseName: 'chip',
    ...chipDefinition,
});
