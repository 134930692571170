import { Button, buttonTemplate as template } from '@microsoft/fast-foundation';
import { ToolbarButtonComponent } from './ToolbarButton';
import styles from './ToolbarButton.styles.scss';
import { withStylePrefix } from '../../common/withStylePrefix';
export const toolbarButtonDefinition = {
    baseName: 'toolbar-button',
    baseClass: Button,
    template: template,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonToolbarButton = ToolbarButtonComponent.compose(toolbarButtonDefinition);
