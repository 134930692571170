import { __decorate } from "tslib";
import { attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
import { SVG_CLOSE_OUTLINED, SVG_SUBTRACT_OUTLINED, SVG_STRETCH_EXPAND_OUTLINED, SVG_PIP_MAXIMIZE_OUTLINED, } from '@getgo/chameleon-icons';
import { IconButtonComponent } from '../IconButton';
import { SvgComponent } from '../Svg';
export const topBarTemplate = (context, _definition) => {
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    return html ` <div class="container">
    ${when((x) => x.platform === 'macOS', html `<div class="traffic-light-container">
        <button class="close-button traffic-light" id="traffic-light-close">${SVG_CLOSE_OUTLINED}</button>
        <button class="minimize-button traffic-light" id="traffic-light-minimize">${SVG_SUBTRACT_OUTLINED}</button>
        <button class="maximize-button traffic-light" id="traffic-light-maximize">
          ${SVG_STRETCH_EXPAND_OUTLINED}
        </button>
      </div>`)}
    <div class="logo">
      <slot name="logo" ${slotted('logoNodes')}></slot>
    </div>
    <div class="submenu">
      <slot name="submenu" ${slotted('submenuNodes')}></slot>
    </div>
    <div class="search-container">
      <slot name="search-field"></slot>
    </div>
    <div class="extra-element-container">
      <slot name="extra-element-container"></slot>
    </div>
    <div class="button-container">
      <slot name="button-container"></slot>
    </div>
    <div class="sign-in-container">
      <slot name="sign-in-button"></slot>
    </div>
    ${when((x) => x.platform === 'windows', html `<div class="windows-container">
      <${iconButtonTag} class="minimize-button" id="windows-button-minimize"
        ><${svgTag}>${SVG_SUBTRACT_OUTLINED}</${svgTag}></${iconButtonTag}
      >
      <${iconButtonTag} class="maximize-button" id="windows-button-maximize"
        ><${svgTag}>${SVG_PIP_MAXIMIZE_OUTLINED}</${svgTag}></${iconButtonTag}
      >
      <${iconButtonTag} class="close-button" id="windows-button-close"
        ><${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}></${iconButtonTag}
      >
    </div>`)}
  </div>`;
};
export class TopBarComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.platform = 'web';
        this.onMinimize = () => {
            this.$emit('ch-top-bar-minimize', null, { bubbles: false });
        };
        this.onMaximize = () => {
            this.$emit('ch-top-bar-maximize', null, { bubbles: false });
        };
        this.onClose = () => {
            this.$emit('ch-top-bar-close', null, { bubbles: false });
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('state', 'focus');
        if (this.platform === 'macOS') {
            this.setTrafficLights();
            this.setSeparator();
        }
        if (this.platform === 'windows') {
            this.setWindowsButtons();
        }
        this.closeBtn?.addEventListener('click', this.onClose);
        this.maximizeBtn?.addEventListener('click', this.onMaximize);
        this.minimizeBtn?.addEventListener('click', this.onMinimize);
    }
    logoNodesChanged() {
        if (this.logoNodes && this.logoNodes.length > 0) {
            const logoNode = this.logoNodes[0];
            if (this.submenuNodes.length > 0 &&
                logoNode &&
                logoNode instanceof Element &&
                !this.shadowRoot?.querySelector('.separator')) {
                this.setSeparator();
            }
        }
        else if (this.shadowRoot?.querySelector('.separator')) {
            this.removeSeparator();
        }
    }
    submenuNodesChanged() {
        if (this.submenuNodes && this.submenuNodes.length > 0) {
            const submenuNode = this.submenuNodes[0];
            if (this.logoNodes.length > 0 &&
                submenuNode &&
                submenuNode instanceof Element &&
                !this.shadowRoot?.querySelector('.separator')) {
                this.setSeparator();
            }
        }
        else if (this.shadowRoot?.querySelector('.separator')) {
            this.removeSeparator();
        }
    }
    setSeparator() {
        const separator = document.createElement('div');
        separator.setAttribute('class', 'separator');
        this.shadowRoot?.querySelector('.logo')?.appendChild(separator);
    }
    removeSeparator() {
        this.shadowRoot?.querySelector('.separator')?.remove();
    }
    setTrafficLights() {
        this.closeBtn = this.shadowRoot?.querySelector('.close-button');
        this.minimizeBtn = this.shadowRoot?.querySelector('.minimize-button');
        this.maximizeBtn = this.shadowRoot?.querySelector('.maximize-button');
        window.addEventListener('blur', () => {
            this.setAttribute('state', 'blur');
        });
        window.addEventListener('focus', () => {
            this.setAttribute('state', 'focus');
        });
    }
    setWindowsButtons() {
        this.closeBtn = this.shadowRoot?.querySelector('.close-button');
        this.maximizeBtn = this.shadowRoot?.querySelector('.maximize-button');
        this.minimizeBtn = this.shadowRoot?.querySelector('.minimize-button');
    }
}
__decorate([
    attr
], TopBarComponent.prototype, "platform", void 0);
__decorate([
    observable
], TopBarComponent.prototype, "logoNodes", void 0);
__decorate([
    observable
], TopBarComponent.prototype, "submenuNodes", void 0);
