import { chameleonIconButton } from '../IconButton/IconButton.definition';
import { chameleonSvg } from '../Svg';
import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonDateRangePicker } from './DateRangePicker.definition';
import { chameleonTypography } from '../Typography/Typography.definition';
import { chameleonDateInput } from '../DateInput/DateInput.definition';
import { chameleonRangeCalendar } from '../RangeCalendar/RangeCalendar.defintion';
import { chameleonPopoverV2 } from '../PopoverV2/PopoverV2.definition';
import { chameleonButton } from '../Button/Button.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonSvg({ prefix }), chameleonTypography({ prefix }), chameleonDateInput({ prefix }), chameleonIconButton({ prefix }), chameleonButton({ prefix }), chameleonPopoverV2({ prefix }), chameleonRangeCalendar({ prefix }), chameleonDateRangePicker({ prefix }));
}
