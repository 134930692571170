import { __decorate } from "tslib";
import { FoundationElement } from '@microsoft/fast-foundation';
import { attr, html, ref } from '@microsoft/fast-element';
import DOMPurify from 'dompurify';
import { ChameleonElementMixin } from '../common/mixin';
const DEFAULT_ICON_SIZE = '16';
export const svgTemplate = html `<template>
  <div ${ref('iconIndentifierContainer')} class="icon-identifier hidden">
    <slot></slot>
  </div>
  <div ${ref('resolvedIconContainer')} class="resolved-icon"></div>
</template>`;
export class SvgComponent extends ChameleonElementMixin(FoundationElement) {
    widthChanged(_prev, next) {
        const svg = this.shadowRoot?.querySelector('svg');
        if (next && svg) {
            svg.setAttribute('width', next);
        }
    }
    heightChanged(_prev, next) {
        const svg = this.shadowRoot?.querySelector('svg');
        if (next && svg) {
            svg.setAttribute('height', next);
        }
    }
    constructor() {
        super();
        this.role = null;
        this.width = DEFAULT_ICON_SIZE;
        this.height = DEFAULT_ICON_SIZE;
        this.handleContentMutation = (mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'characterData') {
                    this.updateIcon();
                }
            }
        };
        this.contentObserver = new MutationObserver(this.handleContentMutation);
    }
    connectedCallback() {
        super.connectedCallback();
        this.setSvgRole(this.role);
        this.updateIcon();
        this.contentObserver.observe(this, { childList: true, subtree: true, characterData: true });
    }
    roleChanged(_oldValue, newValue) {
        this.setSvgRole(newValue);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.contentObserver.disconnect();
    }
    updateIcon() {
        const icon = this.textContent?.trim() || '';
        if (icon) {
            const sanitizedIcon = DOMPurify.sanitize(icon, { USE_PROFILES: { svg: true, svgFilters: true } });
            this.resolvedIconContainer.innerHTML = sanitizedIcon;
            this.resolvedIconContainer.classList.remove('hidden');
            this.iconIndentifierContainer.classList.add('hidden');
            if (icon.includes('pictogram')) {
                this.classList.add('pictogram');
            }
        }
        else {
            this.resolvedIconContainer.classList.add('hidden');
            this.iconIndentifierContainer.classList.remove('hidden');
        }
    }
    setSvgRole(role) {
        const svgElement = this.getChildSvg();
        if (svgElement) {
            this.setInternalSvgRole(svgElement, role);
        }
    }
    setInternalSvgRole(svgElement, role) {
        if (role) {
            svgElement.setAttribute('role', role);
        }
        else {
            svgElement.removeAttribute('role');
        }
    }
    getChildSvg() {
        return this.querySelector('svg');
    }
}
__decorate([
    attr
], SvgComponent.prototype, "role", void 0);
__decorate([
    attr
], SvgComponent.prototype, "width", void 0);
__decorate([
    attr
], SvgComponent.prototype, "height", void 0);
