import { ListboxOption, listboxOptionTemplate as template, } from '@microsoft/fast-foundation';
import { ListboxItemComponent } from './ListboxItem';
import styles from './ListboxItem.styles.scss';
import { withStylePrefix } from '../../common/withStylePrefix';
export const listboxItemDefinition = {
    baseName: 'listbox-item',
    baseClass: ListboxOption,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonListboxItem = ListboxItemComponent.compose(listboxItemDefinition);
