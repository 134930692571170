import { withStylePrefix } from '../common/withStylePrefix';
import { ChipV2Component, chipV2Template } from './ChipV2';
import styles from './ChipV2.styles.scss';
export const chipV2Definition = {
    template: chipV2Template,
    styles: withStylePrefix(styles),
};
export const chameleonChipV2 = ChipV2Component.compose({
    ...chipV2Definition,
    baseName: 'chip-v2',
});
