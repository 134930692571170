import config from 'config';
import fetchWithToken from './provider/fetch';

const sessionDetailsEndPoint = (accountKey: string, sessionId: string): string =>
  `${config.COPAS_URL}/payment/ui/cip/v1/${accountKey}/session/${sessionId}/get`;

export const daoSessionDetailsPost = (accountKey: string, sessionId: string): Promise<object> =>
  fetchWithToken({ reqUrl: sessionDetailsEndPoint(accountKey, sessionId), method: 'POST', body: null });

export default {
  daoSessionDetailsPost,
};
