import { __decorate } from "tslib";
import { TreeView } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
export class TreeViewComponent extends ChameleonPrefixedElementMixin(TreeView, 'tree-view') {
    constructor() {
        super(...arguments);
        this.size = 'medium';
    }
    sizeChanged(_oldSize, newSize) {
        this.querySelectorAll(this.tagFor('tree-item')).forEach((treeItem) => treeItem.setAttribute('size', newSize));
    }
}
__decorate([
    attr
], TreeViewComponent.prototype, "size", void 0);
