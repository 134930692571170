import { __decorate } from "tslib";
import { attr, html, nullableNumberConverter } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
const renderComponent = ({ variant, tag, color, lineClamp }) => {
    const colorName = color === 'inherit' ? 'inherit' : `var(--goto-${color})`;
    const lineClampStyle = lineClamp ? `${lineClamp} !important` : 'inherit';
    const style = `
    font: var(--goto-${variant});
    color: ${colorName};
    -webkit-line-clamp: ${lineClampStyle};
  `;
    return html `<template><${tag} part="text" style="${style}"><slot></slot></${tag}></template>`;
};
export const typographyTemplate = html `${(x) => renderComponent(x)}`;
export class TypographyComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.variant = 'body-small';
        this.tag = 'p';
        this.color = 'type-color-default';
        this.lineClamp = null;
    }
    colorChanged(_oldValue, newValue) {
        if (!newValue) {
            this.color = 'type-color-default';
        }
    }
}
__decorate([
    attr
], TypographyComponent.prototype, "variant", void 0);
__decorate([
    attr
], TypographyComponent.prototype, "tag", void 0);
__decorate([
    attr
], TypographyComponent.prototype, "color", void 0);
__decorate([
    attr({ attribute: 'gutter-bottom', mode: 'boolean' })
], TypographyComponent.prototype, "gutterBottom", void 0);
__decorate([
    attr({ attribute: 'gutter-top', mode: 'boolean' })
], TypographyComponent.prototype, "gutterTop", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TypographyComponent.prototype, "nowrap", void 0);
__decorate([
    attr({ attribute: 'line-clamp', converter: nullableNumberConverter })
], TypographyComponent.prototype, "lineClamp", void 0);
