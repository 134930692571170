import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { Tabs } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export class TabsComponent extends ChameleonElementMixin(Tabs) {
    constructor() {
        super(...arguments);
        /**
         * `orientation` is techincally supported in the library, but is an undesired pattern from a UX perspective.
         * If you find yourself reaching for it, reach out to our designers on the #chameleonux channel and they will help you
         * find a better alternative.
         */
        this.orientation = 'horizontal';
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    getTablistElement() {
        return this.shadowRoot?.querySelector('[role="tablist"]');
    }
    setAccessibleLabel() {
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-label')?.length && !this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: Tabs component must have an accessible label provided by either
  1) a visible label whose id is given as the attribute labelId
  2) a non-empty label attribute to be used as aria-label.
`);
        }
    }
    orientationChanged() {
        super.orientationChanged();
        // Orientation will need to be forced to horizontal in the future
        /*if (newValue === 'vertical') {
          this.orientation = 'horizontal';
        }*/
        requestAnimationFrame(() => this.getTablistElement()?.setAttribute('aria-orientation', this.orientation));
    }
    tabsChanged() {
        super.tabsChanged();
        if (!this.activeid && this.tabs.length !== 0) {
            requestAnimationFrame(() => (this.activeid = this.tabs[0]?.id !== '' ? this.tabs[0]?.id : 'tab-1'));
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], TabsComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TabsComponent.prototype, "noPadding", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], TabsComponent.prototype, "labelId", void 0);
__decorate([
    attr
], TabsComponent.prototype, "orientation", void 0);
__decorate([
    attr
], TabsComponent.prototype, "label", void 0);
