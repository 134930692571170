import { __decorate } from "tslib";
import { SVG_CLOSE_OUTLINED, SVG_ARROW_LEFT } from '@getgo/chameleon-icons';
import { html, when, attr, slotted, observable, ref } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonElementMixin } from '../common/mixin';
import { debounce } from 'lodash-es';
import { IconButtonComponent } from '../IconButton';
import { SvgComponent } from '../Svg';
import { TypographyComponent } from '../Typography';
export const sidePanelTemplate = (context, _definition) => {
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const svgTag = context.tagFor(SvgComponent);
    const typographyTag = context.tagFor(TypographyComponent);
    return html `<template
   id="${(x) => x.uniqueId}"
   aria-labelledby="${(x) => `${x.uniqueId}-heading`}"
   role="dialog"
   aria-hidden="${(x) => (x.isOpen ? 'false' : 'true')}"
 >
  <div class="header" part="header">
    ${when((x) => x.toolbarSlot, html `<slot name="toolbar" ${slotted('toolbarSlot')}></slot>`)}
    <div class="heading">
      ${when((x) => x.backLabel, html `<${iconButtonTag}
          variant="primary"
          size="large"
          aria-label="${(x) => x.backLabel}"
          @click=${(x) => x.back()}
        >
          <${svgTag}>${SVG_ARROW_LEFT}</${svgTag}>
        </${iconButtonTag}>`)}
      <slot name="heading-start"></slot>
      <slot name="heading" id="${(x) => `${x.uniqueId}-heading`}">
        <${typographyTag} variant="heading-small">${(x) => x.heading}</${typographyTag}>
      </slot>
      ${when((x) => x.closeLabel, html `
          <div class="spacer"></div>

          <slot name="heading-end"></slot>
          <${iconButtonTag}
            variant="primary"
            size="large"
            aria-label="${(x) => x.closeLabel}"
            aria-controls="${(x) => x.uniqueId}"
            @click=${(x) => x.close()}
          >
            <${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}>
          </${iconButtonTag}>
        `)}
    </div>
  </div>
  <div class="body" ${ref('body')}>
    <slot></slot>
  </div>

  ${when((x) => x.footerSlot, html `<div class="footer">
      <slot name="footer" ${slotted('footerSlot')}></slot>
    </div>`)}
    
  </div>
</template>`;
};
export class SidePanelComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.closesOnClickOutside = false;
        this.heading = '';
        this.closeLabel = undefined;
        this.backLabel = undefined;
        this.isOpen = false;
        this.contentScrollable = false;
        this.footerSlot = [];
        this.toolbarSlot = [];
        this.uniqueId = uniqueId('side-panel-');
        this.observerCallback = debounce((entries) => {
            let scrollable = false;
            entries.forEach((entry) => {
                if (entry.target.scrollHeight > Math.ceil(entry.contentRect.height)) {
                    scrollable = true;
                }
            });
            this.contentScrollable = scrollable;
        }, 100);
        this.onOutsideClick = (event) => {
            if (this.isOpen && !event.defaultPrevented && !event.composedPath().some((target) => target === this)) {
                this.clickOutside();
                if (this.closesOnClickOutside) {
                    this.close();
                }
            }
        };
        this.onHandleEscKey = (event) => {
            if (event.key === 'Escape' && this.isOpen) {
                this.close();
            }
        };
        this.observer = new ResizeObserver(this.observerCallback);
    }
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('click', this.onOutsideClick);
        document.addEventListener('keydown', this.onHandleEscKey);
        this.observer.observe(this.body);
    }
    disconnectedCallback() {
        window.removeEventListener('click', this.onOutsideClick);
        document.removeEventListener('keydown', this.onHandleEscKey);
        this.observer.disconnect();
    }
    isOpenChanged(oldValue, newValue) {
        if (this.$fastController.isConnected) {
            if (newValue !== oldValue) {
                if (newValue) {
                    this.$emit('opening', null, { bubbles: false });
                }
                else {
                    this.$emit('closing', null, { bubbles: false });
                }
            }
        }
    }
    clickOutside() {
        this.$emit('click-outside', null, { bubbles: false });
    }
    close() {
        this.$emit('close', null, { bubbles: false });
    }
    back() {
        this.$emit('back', null, { bubbles: false });
    }
}
__decorate([
    attr({ mode: 'boolean', attribute: 'closes-on-click-outside' })
], SidePanelComponent.prototype, "closesOnClickOutside", void 0);
__decorate([
    attr
], SidePanelComponent.prototype, "heading", void 0);
__decorate([
    attr({ attribute: 'close-label' })
], SidePanelComponent.prototype, "closeLabel", void 0);
__decorate([
    attr({ attribute: 'back-label' })
], SidePanelComponent.prototype, "backLabel", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'is-open' })
], SidePanelComponent.prototype, "isOpen", void 0);
__decorate([
    attr({ attribute: 'data-content-scrollable' })
], SidePanelComponent.prototype, "contentScrollable", void 0);
__decorate([
    observable
], SidePanelComponent.prototype, "footerSlot", void 0);
__decorate([
    observable
], SidePanelComponent.prototype, "toolbarSlot", void 0);
__decorate([
    attr
], SidePanelComponent.prototype, "uniqueId", void 0);
