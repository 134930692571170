import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { AccordionItem } from '@microsoft/fast-foundation';
import { ChameleonPrefixedElementMixin } from '../../common/mixin';
import { DEV } from 'esm-env';
export class AccordionItemComponent extends ChameleonPrefixedElementMixin(AccordionItem, 'accordion-item') {
    constructor() {
        super(...arguments);
        this.chevronPosition = 'right';
    }
    expandedChanged(_, _newValue) {
        if (this.isConnected) {
            this.$emit('expanded');
        }
    }
    disabledChanged() {
        if (this.isConnected) {
            this.propagateDisabledState();
        }
    }
    propagateDisabledState() {
        if (this.disabled) {
            this.getInternalButtonElement()?.setAttribute('disabled', '');
        }
        else {
            this.getInternalButtonElement()?.removeAttribute('disabled');
        }
    }
    getHeadingSlot() {
        return this.querySelector('[slot="heading"]');
    }
    getInternalButtonElement() {
        return this.shadowRoot?.querySelector('.button');
    }
    setAccessibleLabel() {
        if (this.getHeadingSlot()?.textContent?.length) {
            return;
        }
        if (this.label) {
            this.getInternalButtonElement()?.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getInternalButtonElement()?.getAttribute('aria-label')?.length) {
            console.error(`CHAMELEON-A11Y: Accordion Item component must have an accessible label provided by either 
  1) text content in the heading slot 
  2) a non-empty label attribute to be used as aria-label.`);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        this.propagateDisabledState();
    }
}
__decorate([
    attr({ mode: 'boolean' })
], AccordionItemComponent.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'chevron-position' })
], AccordionItemComponent.prototype, "chevronPosition", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'full-width' })
], AccordionItemComponent.prototype, "fullWidth", void 0);
__decorate([
    attr
], AccordionItemComponent.prototype, "divider", void 0);
__decorate([
    attr
], AccordionItemComponent.prototype, "label", void 0);
