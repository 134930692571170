import { __decorate } from "tslib";
import { SVG_CLOSE_OUTLINED, SVG_INFO_OUTLINED, SVG_ALERT_OUTLINED, SVG_CHECKMARK_OUTLINED, SVG_BLOCK_OUTLINED, } from '@getgo/chameleon-icons';
import { html, when, slotted, observable } from '@microsoft/fast-element';
import { AlertBase, DEFAULT_CLOSE_ARIA_LABEL } from '../common/AlertBase';
import { SvgComponent } from '../Svg';
import { IconButtonComponent } from '../IconButton';
export const alertTemplate = (context, _definition) => {
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const svgIconTag = context.tagFor(SvgComponent);
    return html `
    <template role="alert" variant="${(x) => x.variant ?? 'info'}">
      <slot name="icon"></slot>
      <div class="alert-content">
        <slot></slot>
        <slot name="action"></slot>
      </div>
      ${when((x) => x.closable, html `<${iconButtonTag}
          size="small"
          aria-label="${(x) => x.closeLabel ?? DEFAULT_CLOSE_ARIA_LABEL}"
          @click=${(x) => x.close()}
        >
          <${svgIconTag}>${SVG_CLOSE_OUTLINED}</${svgIconTag}>
        </${iconButtonTag}>`)}
    </template>
  `;
};
export const alertV2Template = (context, _definition) => {
    const iconButtonTag = context.tagFor(IconButtonComponent);
    const svgIconTag = context.tagFor(SvgComponent);
    return html `
    <template role="alert" variant="${(x) => x.variant ?? 'info'}">
      <slot name="icon" ${slotted('icon')}
        >${html `<${svgIconTag} class="alert-leading-icon">
          ${when((x) => x.variant === 'info', html `${SVG_INFO_OUTLINED}`)}
          ${when((x) => x.variant === 'success', html `${SVG_CHECKMARK_OUTLINED}`)}
          ${when((x) => x.variant === 'danger', html `${SVG_BLOCK_OUTLINED}`)}
          ${when((x) => x.variant === 'warning', html `${SVG_ALERT_OUTLINED}`)}
        </${svgIconTag}>`}
      </slot>
      <div class="alert-content">
        <slot></slot>
        <slot name="action"></slot>
      </div>
      ${when((x) => x.closable, html `
          <${iconButtonTag}
            part="close-icon"
            size="xsmall"
            aria-label="${(x) => x.closeLabel ?? DEFAULT_CLOSE_ARIA_LABEL}"
            @click=${(x) => x.close()}
          >
            <${svgIconTag}>${SVG_CLOSE_OUTLINED}</${svgIconTag}>
          </${iconButtonTag}>
        `)}
    </template>
  `;
};
export class AlertComponent extends AlertBase {
}
__decorate([
    observable
], AlertComponent.prototype, "icon", void 0);
