import { __decorate } from "tslib";
import { attr, observable } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { MenuItem } from '@microsoft/fast-foundation';
// PREFIXING : This component does not support prefixing.
export class NavigationRailLinkComponent extends ChameleonElementMixin(MenuItem) {
    constructor() {
        super(...arguments);
        /*
         *   @deprecated
         *   Will be removed in upcoming major update
         * */
        this.size = 'small';
        this.state = 'expanded';
        this.disabled = false;
        this.target = '_blank';
        this.appearance = 'navrailitem';
        this.navigationRailElement = null;
        this.handleMenuKeyDown = (event) => {
            this.handleMenuItemKeyDown(event);
            if (event.key === 'Enter') {
                window.open(this.href, this.target);
            }
            return true;
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.toggleSlots();
        this.navigationRailElement = this.parentNode;
        this.size = this.navigationRailElement.size;
        this.state = this.navigationRailElement?.state;
        this.contentElement = this.shadowRoot?.querySelector('[slot="content"]');
        this.tooltipElement = this.shadowRoot?.querySelector('chameleon-tooltip-v3');
    }
    disconnectedCallback() { }
    /* 'defaultNode' is the text, what the user typed in to the component
     * and we assign it to the content slot of the tooltip v3
     */
    defaultSlottedNodesChanged() {
        this.assignText();
        if (this.defaultSlottedNodes.length > 0) {
            this.toggleSlots();
            const textNodes = this.defaultSlottedNodes.filter((defaultNode) => defaultNode.nodeName === '#text');
            const trueTextNode = textNodes.find((textNode) => textNode.textContent && !textNode.textContent.startsWith('\n'));
            if (this.contentElement && !!trueTextNode?.textContent) {
                this.contentElement.textContent = trueTextNode.textContent;
            }
        }
    }
    handleItemClick(event) {
        this.handleMenuItemClick(event);
        return true;
    }
    toggleSlots() {
        if (!this.querySelector('[slot="start"]')) {
            this.style.setProperty('--start-slot-display', 'none');
        }
        else {
            this.style.setProperty('--start-slot-display', 'contents');
        }
        if (!this.querySelector('[slot="end"]')) {
            this.shadowRoot?.querySelector('chameleon-base-navigation-rail-item')?.removeAttribute('data-has-end-slot');
            this.style.setProperty('--end-slot-display', 'none');
        }
        else {
            this.shadowRoot?.querySelector('chameleon-base-navigation-rail-item')?.setAttribute('data-has-end-slot', '');
            this.style.setProperty('--end-slot-display', 'contents');
        }
    }
    assignText() {
        if (this.defaultSlottedNodes && this.defaultSlottedNodes.length > 0) {
            const textNodes = this.defaultSlottedNodes.filter((defaultNode) => defaultNode.nodeName === '#text');
            const trueTextNode = textNodes.find((textNode) => textNode.textContent && !textNode.textContent.startsWith('\n'));
            if (this.tooltipElement.textContent && !!trueTextNode?.textContent) {
                this.tooltipElement.textContent = trueTextNode.textContent;
            }
            if (this.className === 'more-items-container') {
                this.tooltipElement.textContent = this.textContent;
            }
        }
    }
    endSlottedNodesChanged() {
        this.setBadgeCounter();
    }
    setBadgeCounter() {
        if (this.endSlottedNodes && this.endSlottedNodes.length > 0) {
            const textNodes = this.endSlottedNodes[0];
            if (this.endSlottedNodes[0] instanceof Element) {
                textNodes.setAttribute('size', 'small');
            }
        }
    }
}
__decorate([
    attr
], NavigationRailLinkComponent.prototype, "size", void 0);
__decorate([
    attr
], NavigationRailLinkComponent.prototype, "state", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "sticky", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "collapse", void 0);
__decorate([
    attr()
], NavigationRailLinkComponent.prototype, "href", void 0);
__decorate([
    attr()
], NavigationRailLinkComponent.prototype, "target", void 0);
__decorate([
    attr
], NavigationRailLinkComponent.prototype, "appearance", void 0);
__decorate([
    observable
], NavigationRailLinkComponent.prototype, "defaultSlottedNodes", void 0);
__decorate([
    observable
], NavigationRailLinkComponent.prototype, "endSlottedNodes", void 0);
