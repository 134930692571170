import { FC, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftOutlinedIcon, GotoAdminPartnerFaviconIcon } from '@getgo/chameleon-icons/react';
import { Button, GotoLogo } from '@getgo/chameleon-web-react-wrapper';

import SessionExpiredModal from 'components/session-expired-modal';
import SessionTimer from 'components/session-timer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { accountKey } from 'modules/global-wrapper';
import { postPaymentMethodTypes } from 'modules/payment-methods';
import { isSessionExpired, sesssionCancelRedirectUrl } from 'modules/session-details';
import Track, { PaymentMethodBackToG2A, PaymentMethodLanding } from 'modules/tracking';
import PaymentMethodOptions from 'routes/pay-now/payment-method-options';
import { ONE_PAY_INTENT } from 'utils/constants';
import st from 'utils/shared-translations';
import ACHMethod from './ach-method';
import CreditCardMethod from './credit-card-method';
import DirectDebitMethod from './direct-debit-method';
import PaypalMethod from './paypal-method';

import './payment-methods.css';

interface PaymentMethodsProps {
  intent: string;
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ intent }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pathName = window.location.pathname;

  const selectedAccountKey = useAppSelector(accountKey);
  const selectedSessionIsExpired = useAppSelector(isSessionExpired);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  const isPaymentMethodAdd = useRef(pathName.includes('paymentmethod/add'));

  useEffect(() => {
    if (selectedAccountKey) {
      dispatch(postPaymentMethodTypes());
    }
  }, [dispatch, selectedAccountKey]);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(PaymentMethodLanding, { paymentMethodType: intent });
    }
  }, [selectedAccountKey, intent]);

  const redirectToPaymentMethod = () => {
    if (isPaymentMethodAdd.current && !pathName.includes('paymentmethod/add')) {
      // Redirect to the previous page by removing the last part of the path
      navigate(-1);
    } else {
      Track(PaymentMethodBackToG2A, {});
      localStorage.clear();
      window.location.assign(selectedCancelRedirectUrl);
    }
  };

  return (
    <div className="payment-methods" data-testid="payment-methods">
      {selectedSessionIsExpired && <SessionExpiredModal redirectType="PaymentMethod" />}
      <nav className="payment-methods__nav" data-testid="payment-methods__nav">
        <Button
          size="medium"
          variant="neutral"
          leadingIcon={<ChevronLeftOutlinedIcon />}
          onClick={redirectToPaymentMethod}
          data-testid="payment-methods__back"
        >
          {intl.formatMessage(st[isPaymentMethodAdd ? 'back.to.previous.page' : 'back.to.payment.methods'])}
        </Button>
      </nav>
      <header className="payment-methods__header" data-testid="payment-methods__header">
        <SessionTimer />
      </header>
      <GotoLogo className="payment-methods__logo" data-testid="payment-methods__logo">
        <GotoAdminPartnerFaviconIcon />
      </GotoLogo>
      <main className="payment-methods__main" data-testid="payment-methods__main">
        {
          {
            [ONE_PAY_INTENT.ach]: <ACHMethod />,
            [ONE_PAY_INTENT.cc]: <CreditCardMethod />,
            [ONE_PAY_INTENT.dd]: <DirectDebitMethod />,
            [ONE_PAY_INTENT.paypal]: <PaypalMethod />,
            [ONE_PAY_INTENT.addPayment]: <PaymentMethodOptions />,
          }[intent]
        }
      </main>
    </div>
  );
};

export default PaymentMethods;
