import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonNavigationRail } from './NavigationRail.definition';
import { chameleonNavigationRailItem } from './NavigationRailItem/NavigationRailItem.definition';
import { chameleonNavigationRailLink } from './NavigationRailLink/NavigationRailLink.definition';
import { chameleonBaseNavigationRailItem } from './BaseNavigationRailItem/BaseNavigationRailItem.definition';
import { chameleonBaseNavigationRailLink } from './BaseNavigationRailLink/BaseNavigationRailLink.definition';
import { chameleonTooltipV3 } from '../TooltipV3/TooltipV3.definition';
import { chameleonPopoverV2 } from '../PopoverV2/PopoverV2.definition';
import { chameleonSvg } from '../Svg';
import { chameleonMenu } from '../Menu/Menu.definition';
import { chameleonMenuItem } from '../Menu/MenuItem/MenuItem.definition';
function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonNavigationRail(), chameleonNavigationRailItem(), chameleonBaseNavigationRailItem(), chameleonNavigationRailLink(), chameleonBaseNavigationRailLink(), chameleonTooltipV3({ prefix }), chameleonPopoverV2(), chameleonMenu(), chameleonMenuItem(), chameleonSvg());
}
registerCustom();
