import { createElement, useContext } from 'react';
import { chameleonPrefixContext } from './PrefixContext';
const cleanProps = (props) => {
    if (!props) {
        return props;
    }
    return Object.assign(Object.assign({}, props), { class: props.className, className: undefined });
};
export const createReactWrapper = ({ tagName, props, children, }) => {
    const prefixContext = useContext(chameleonPrefixContext);
    const replacedTagName = prefixContext ? tagName.replace('chameleon', prefixContext) : tagName;
    return createElement(replacedTagName, cleanProps(props), children);
};
