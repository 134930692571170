import { SVG_GOTO_BLACK_YELLOW, SVG_GOTO_MARK, SVG_GOTO_WHITE_YELLOW } from '@getgo/chameleon-icons';
import { html, ref } from '@microsoft/fast-element';
import { ChameleonRebrandingObserver } from '../Observers';
import { SvgComponent } from '../Svg';
export const launchScreenComponentTemplate = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    return html ` <${svgTag}
    class="rebranding-logo-container"
    ${ref('rebrandingLogoContainer')}
  ></${svgTag}>
  <div class="old-logo-container">
    <slot></slot>
  </div>`;
};
export class LaunchScreenComponent extends ChameleonRebrandingObserver {
    constructor() {
        super(...arguments);
        this.baseName = 'launch-screen';
    }
    connectedCallback() {
        super.connectedCallback();
        const svgTag = this.tagFor('svg');
        const slot = this.shadowRoot?.querySelector('slot');
        if (!slot?.assignedNodes().length && !slot?.querySelectorAll(svgTag).length) {
            const svg = document.createElement(svgTag);
            svg.innerHTML = SVG_GOTO_MARK;
            slot?.appendChild(svg);
        }
        this.observer.observe(this.getThemeProvider(), { attributes: true });
        this.updateVisibility();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    rebrandingObserverHandler() {
        this.updateVisibility();
    }
    updateVisibility() {
        const themeProvider = this.getThemeProvider();
        if (!themeProvider) {
            console.error(`[@getgo/chameleon-web] - Couldn't find chameleon-theme-provider in the DOM for ${this}`);
            return;
        }
        if (themeProvider.hasAttribute('rebranding2021')) {
            this.updateIconBasedOnTheme();
            this.classList.add('rebranding');
        }
        else {
            this.classList.remove('rebranding');
        }
    }
    updateIconBasedOnTheme() {
        if (this.isDarkMode() || this.isHighContrast()) {
            this.rebrandingLogoContainer.innerHTML = SVG_GOTO_WHITE_YELLOW;
        }
        else {
            this.rebrandingLogoContainer.innerHTML = SVG_GOTO_BLACK_YELLOW;
        }
    }
    isDarkMode() {
        const themeProvider = this.getThemeProvider();
        const automaticAttr = themeProvider.getAttribute('automatic');
        const isAutomatic = automaticAttr === 'true' || automaticAttr === '';
        return isAutomatic
            ? window.matchMedia('(prefers-color-scheme: dark)').matches
            : themeProvider.getAttribute('theme') === 'dark';
    }
    isHighContrast() {
        const themeProvider = this.getThemeProvider();
        return themeProvider.getAttribute('theme') === 'high-contrast';
    }
}
