import { createSlice } from '@reduxjs/toolkit';

import { GlobalWrapperState } from 'types/global-wrapper';

const globalWrapperSlice = createSlice({
  name: 'globalWrapper',

  initialState: {
    isLoading: true,
    accountKey: '',
    sessionId: '',
    language: 'en',
  } as GlobalWrapperState,

  reducers: {
    hideGlobalLoading(state) {
      state.isLoading = false;
    },
    setGlobalAccountKey(state, action) {
      state.accountKey = action.payload;
    },
    setGlobalSessionId(state, action) {
      state.sessionId = action.payload;
    },
    setGlobalLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export const { hideGlobalLoading, setGlobalAccountKey, setGlobalSessionId, setGlobalLanguage } =
  globalWrapperSlice.actions;

export default globalWrapperSlice.reducer;
