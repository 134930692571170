import { splitButtonTemplate } from '..';
import { withStylePrefix } from '../common/withStylePrefix';
import { SplitButtonComponent } from './SplitButton';
import styles from './SplitButton.styles.scss';
export const splitButtonDefinition = {
    baseName: 'split-button',
    template: splitButtonTemplate,
    styles: withStylePrefix(styles),
};
export const chameleonSplitButton = SplitButtonComponent.compose(splitButtonDefinition);
