import { withStylePrefix } from '../common/withStylePrefix';
import { AlertComponent, alertTemplate, alertV2Template } from './Alert';
import styles from './Alert.styles.scss';
export const alertDefinition = {
    baseName: 'alert',
    template: alertTemplate,
    styles: withStylePrefix(styles),
};
export const alertV2Definition = {
    baseName: 'alert-v2',
    template: alertV2Template,
    styles: withStylePrefix(styles),
};
export const chameleonAlert = AlertComponent.compose(alertDefinition);
export const chameleonAlertV2 = AlertComponent.compose(alertV2Definition);
