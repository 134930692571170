import { __decorate } from "tslib";
import { attr, html, ref, slotted, observable, volatile, when } from '@microsoft/fast-element';
import { SVG_SEND_FILLED } from '@getgo/chameleon-icons';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const chatInputTemplate = html `<template class=" ${(x) => (x.readOnly ? 'readonly' : '')}">
  <div part="label" class="${(x) => (x.label && x.label.length ? 'label' : 'label hidden')}">
    <slot name="label" ${slotted('label')}></slot>
  </div>
  <div class="container">
    <div class="attachments ${(x) => (!x.attachments || x.attachments.length === 0 ? 'no-attachments' : '')}">
      <slot name="attachments" ${slotted('attachments')}></slot>
    </div>
    <div class="control-with-actions">
      ${when((x) => x.loadingState === 'spinner', html `<chameleon-circular-progress class="loading-placeholder"></chameleon-circular-progress>`)}
      <chameleon-scroll-box class="control-wrapper">
        <div class="placeholder ${(x) => (x.isPlaceholderVisible ? '' : 'hidden')}">${(x) => x.placeholderText}</div>
        <div
          contenteditable="${(x) => (x.readOnly || x.disabled ? 'false' : 'true')}"
          role="textbox"
          part="control"
          class="control"
          id="control"
          ?autofocus="${(x) => x.autofocus}"
          ?disabled="${(x) => x.disabled}"
          ?spellcheck="${(x) => x.spellcheck}"
          aria-atomic="${(x) => x.ariaAtomic}"
          aria-busy="${(x) => x.ariaBusy}"
          aria-current="${(x) => x.ariaCurrent}"
          aria-disabled="${(x) => x.ariaDisabled}"
          aria-hidden="${(x) => x.ariaHidden}"
          aria-invalid="${(x) => x.ariaInvalid}"
          aria-label="${(x) => x.ariaLabel}"
          aria-live="${(x) => x.ariaLive}"
          @keydown="${(x, c) => x.handleKeyDown(c.event)}"
          @keypress="${(x, c) => x.handleKeyPress(c.event)}"
          @keyup="${(x, c) => x.handleKeyUp(c.event)}"
          @paste="${(x, c) => x.handlePaste(c.event)}"
          @change="${(x, c) => x.handleChange(c.event)}"
          @input="${(x, c) => x.handleInput(c.event)}"
          ${ref('controlRef')}
        ></div>
      </chameleon-scroll-box>
      <div class="control-actions">
        <slot name="actions" ${slotted('actions')}></slot>
        <div class="spacer"></div>
        <chameleon-icon-button
          part="send-button"
          id="send-button"
          aria-label="${(x) => x.sendLabel}"
          ?disabled="${(x) => x.isSendButtonDisabled || x.disableSendButton}"
          @click="${(x) => x.handleSend('button')}"
          ><chameleon-svg>${SVG_SEND_FILLED}</chameleon-svg></chameleon-icon-button
        >
        <chameleon-tooltip-v3 trigger-id="send-button" position="top">${(x) => x.sendLabel}</chameleon-tooltip-v3>
      </div>
    </div>
  </div>
  <div
    part="helper-text"
    class="${(x) => (x.helperText && x.helperText.length ? 'helper-text' : 'helper-text hidden')}"
  >
    <slot name="helperText" ${slotted('helperText')}></slot>
  </div>
</template>`;
export class ChatInputComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.placeholder = '';
        this.disabled = false;
        this.readOnly = false;
        this.disableSendButton = false;
        this.loadingState = 'none';
        this.loadingPlaceholder = 'Loading...';
        this.sendLabel = 'Send Message';
        this.error = false;
        this.draggingState = 'empty';
        this.dragAndDropEnabled = false;
        this.value = '';
    }
    actionsChanged() {
        if (!this.isConnected) {
            return;
        }
        if (this.actions?.length === 0) {
            this.setAttribute('data-no-actions', 'true');
        }
        else {
            this.removeAttribute('data-no-actions');
        }
    }
    attachmentsChanged() {
        if (!this.isConnected) {
            return;
        }
        if (this.attachments?.length === 0) {
            this.setAttribute('data-no-attachments', 'true');
        }
        else {
            this.removeAttribute('data-no-attachments');
        }
    }
    dragAndDropEnabledChanged(_, next) {
        if (!this.isConnected) {
            return;
        }
        if (next) {
            this.addEventListener('dragover', this.handleDragOver);
            this.addEventListener('drop', this.handleDrop);
            this.addEventListener('dragleave', this.handleDragLeave);
            this.addEventListener('dragend', this.handleDragLeave);
            this.addEventListener('dragstart', this.handleDragOver);
        }
        else {
            this.removeEventListener('dragover', this.handleDragOver);
            this.removeEventListener('drop', this.handleDrop);
            this.removeEventListener('dragleave', this.handleDragLeave);
            this.removeEventListener('dragend', this.handleDragLeave);
            this.removeEventListener('dragstart', this.handleDragOver);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.controlRef) {
            this.controlRef.innerText = this.value;
        }
        this.dragAndDropEnabledChanged(undefined, this.dragAndDropEnabled);
    }
    blur() {
        this.controlRef?.blur();
    }
    focus() {
        this.controlRef?.focus();
    }
    valueChanged(oldValue, newValue) {
        if (!this.isConnected) {
            return;
        }
        if (!this.controlRef) {
            return;
        }
        if (newValue !== this.controlRef.innerText) {
            this.controlRef.innerText = newValue;
        }
    }
    get isPlaceholderVisible() {
        return this.value.trim() === '' || this.loadingState === 'pulsing';
    }
    get placeholderText() {
        if (this.loadingState !== 'none' && this.loadingPlaceholder) {
            return this.loadingPlaceholder;
        }
        return this.placeholder;
    }
    get isSendButtonDisabled() {
        return (this.value.trim() === '' && this.attachments?.length === 0) || this.disabled;
    }
    handleInput(event) {
        if (event.target !== this.controlRef) {
            return false;
        }
        this.$emit('input', event);
        this.value = this.controlRef?.innerText || '';
    }
    handleChange(event) {
        this.$emit('change', event);
    }
    handleKeyDown(event) {
        this.$emit('keyDown', event);
        return true;
    }
    handleKeyUp(event) {
        this.$emit('keyUp', event);
        return true;
    }
    handleKeyPress(event) {
        this.$emit('keyPress', event);
        if (event.key === 'Enter') {
            if (!event.shiftKey) {
                this.handleSend('enter');
                event.preventDefault();
                return false;
            }
        }
        return true;
    }
    handleSend(source) {
        if (!this.isSendButtonDisabled) {
            this.$emit('send', {
                value: this.value,
                source,
                attachments: this.attachments,
            });
        }
    }
    handlePaste(event) {
        if (event.clipboardData) {
            event.preventDefault();
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertText', false, text);
        }
        return true;
    }
    handleDragOver(event) {
        this.draggingState = 'dragging';
        if (event.dataTransfer && Array.from(event.dataTransfer.types).includes('Files')) {
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.dropEffect = 'copy';
        }
    }
    handleDrop(event) {
        this.draggingState = 'dropped';
        if (event.dataTransfer) {
            const files = Array.from(event.dataTransfer.files);
            //To prevent default action (opening new tab) when receiving a file but not when receiving a string
            if (files.length > 0) {
                event.preventDefault();
            }
        }
    }
    handleDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.draggingState = 'empty';
    }
    handleDragEnd(event) {
        event.preventDefault();
        event.stopPropagation();
        this.draggingState = 'empty';
    }
    handleDragStart(event) {
        event.preventDefault();
        event.stopPropagation();
        this.draggingState = 'dragging';
    }
}
__decorate([
    observable
], ChatInputComponent.prototype, "attachments", void 0);
__decorate([
    observable
], ChatInputComponent.prototype, "actions", void 0);
__decorate([
    observable
], ChatInputComponent.prototype, "label", void 0);
__decorate([
    observable
], ChatInputComponent.prototype, "helperText", void 0);
__decorate([
    attr
], ChatInputComponent.prototype, "placeholder", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatInputComponent.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'read-only', mode: 'boolean' })
], ChatInputComponent.prototype, "readOnly", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'disable-send-button' })
], ChatInputComponent.prototype, "disableSendButton", void 0);
__decorate([
    attr({ attribute: 'loading-state' })
], ChatInputComponent.prototype, "loadingState", void 0);
__decorate([
    attr({ attribute: 'loading-placeholder' })
], ChatInputComponent.prototype, "loadingPlaceholder", void 0);
__decorate([
    attr({ attribute: 'send-label' })
], ChatInputComponent.prototype, "sendLabel", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatInputComponent.prototype, "error", void 0);
__decorate([
    attr({ attribute: 'dragging-state' })
], ChatInputComponent.prototype, "draggingState", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'drag-and-drop-enabled' })
], ChatInputComponent.prototype, "dragAndDropEnabled", void 0);
__decorate([
    attr({ attribute: 'value' })
], ChatInputComponent.prototype, "value", void 0);
__decorate([
    volatile
], ChatInputComponent.prototype, "isPlaceholderVisible", null);
__decorate([
    volatile
], ChatInputComponent.prototype, "placeholderText", null);
__decorate([
    volatile
], ChatInputComponent.prototype, "isSendButtonDisabled", null);
