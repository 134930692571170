import { __decorate } from "tslib";
import { SVG_CHEVRON_DOWN, SVG_CHEVRON_UP } from '@getgo/chameleon-icons';
import { attr, html, observable, ref, slotted, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { SvgComponent } from '../../Svg';
export const dataTableGroupRowV2Template = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    return html `
    <template aria-expanded="${(x) => (x.isExpanded ? 'true' : 'false')}">
      <div class="inner">
        <div class="title" ${ref('titleEl')}>
          <div class="drag"><slot ${slotted('dragSlot')} name="drag"></slot></div>
          <div class="checkbox"><slot name="checkbox"></slot></div>
        </div>
        ${when((x) => x.state === 'collapsed', html `<${svgTag}>${SVG_CHEVRON_UP}</${svgTag}>`)}
        ${when((x) => x.state === 'expanded', html `<${svgTag}>${SVG_CHEVRON_DOWN}</${svgTag}>`)}
      </div>
    </template>
  `;
};
export class DataTableGroupRowV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.state = 'collapsed';
        this.condensed = false;
        this.disabled = false;
        this.selected = false;
    }
    dragSlotChanged(_, next) {
        if (!next || next.length === 0) {
            this.titleEl?.classList.remove('has-drag');
        }
        else {
            this.titleEl?.classList.add('has-drag');
        }
    }
    get isExpanded() {
        return this.state === 'expanded';
    }
}
__decorate([
    attr
], DataTableGroupRowV2Component.prototype, "state", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableGroupRowV2Component.prototype, "condensed", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableGroupRowV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableGroupRowV2Component.prototype, "selected", void 0);
__decorate([
    observable
], DataTableGroupRowV2Component.prototype, "titleEl", void 0);
__decorate([
    observable
], DataTableGroupRowV2Component.prototype, "dragSlot", void 0);
