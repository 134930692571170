import { GlobalWrapperState, Theme } from 'types/global-wrapper';
import { RootState } from 'types/root';

export const globalWrapperTree = (state: RootState): GlobalWrapperState => state.globalWrapper || {};

export const isGlobalLoading = (state: RootState): boolean => globalWrapperTree(state).isLoading || false;

export const accountKey = (state: RootState): string => globalWrapperTree(state).accountKey || '';

export const theme = (state: RootState): Theme => globalWrapperTree(state).theme || 'light';

export const sessionId = (state: RootState): string => globalWrapperTree(state).sessionId || '';

export const locale = (state: RootState): string => globalWrapperTree(state).locale || 'en_US';
