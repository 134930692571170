import { html, slotted } from '@microsoft/fast-element';
import styles from './NavigationRailItem.styles.scss';
import { NavigationRailItemComponent } from './NavigationRailItem';
import { BaseNavigationRailItemComponent } from '../BaseNavigationRailItem/BaseNavigationRailItem';
import { withStylePrefix } from '../../common/withStylePrefix';
import { TooltipV3Component } from '../../TooltipV3';
export const chameleonNavigationRailItemTemplate = (context, _definition) => {
    const tooltipV3Tag = context.tagFor(TooltipV3Component);
    const baseNavigationRailItemTag = context.tagFor(BaseNavigationRailItemComponent);
    return html `
    <template
      @click="${(x, c) => x.handleMenuItemClick(c.event)}"
      @keydown="${(x, c) => x.handleMenuItemKeyDown(c.event)}"
      @mouseover="${(x, c) => x.handleMouseOver(c.event)}"
      @mouseout="${(x, c) => x.handleMouseOut(c.event)}"
      role="menuitem"
    >
    <${tooltipV3Tag}
          trigger-id="${(x) => (x.size === 'small' ? 'tooltip-trigger' : '')}"
          position="right-center"
          z-index="100"
          delay="0"
          tabindex="-1"
          style="--additional-offset-x: 8px;"
        >
        </${tooltipV3Tag}>
      <${baseNavigationRailItemTag}
          id="tooltip-trigger"
          :selected=${(x) => x.selected}
          :disabled=${(x) => x.disabled}
          :sticky=${(x) => x.sticky}
          :collapse=${(x) => x.collapse}
          :size=${(x) => x.size}
          :appearance=${(x) => x.appearance}
          role="menuitem"
        >
          <slot ${slotted('defaultSlottedNodes')}></slot>
          <slot slot="start" name="start"></slot>
          <slot slot="end" name="end" ${slotted('endSlottedNodes')}></slot>
        </${baseNavigationRailItemTag}>
    </template>
  `;
};
export const chameleonNavigationRailItemDefinition = {
    baseName: 'navigation-rail-item',
    template: chameleonNavigationRailItemTemplate,
    styles: withStylePrefix(styles),
};
export const chameleonNavigationRailItem = NavigationRailItemComponent.compose(chameleonNavigationRailItemDefinition);
