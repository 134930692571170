import { MenuItem, menuItemTemplate as template } from '@microsoft/fast-foundation';
import styles from './BaseNavigationRailItem.styles.scss';
import { BaseNavigationRailItemComponent } from './BaseNavigationRailItem';
import { withStylePrefix } from '../../common/withStylePrefix';
export const baseNavigationRailItemDefinition = {
    baseName: 'base-navigation-rail-item',
    baseClass: MenuItem,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonBaseNavigationRailItem = BaseNavigationRailItemComponent.compose(baseNavigationRailItemDefinition);
