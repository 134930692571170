import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { TextArea } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { DEV } from 'esm-env';
export class TextAreaComponent extends ChameleonElementMixin(TextArea) {
    constructor() {
        super(...arguments);
        this.success = false;
        this.error = false;
        this.fullwidth = false;
        this.hidecounter = false;
        this.optional = false;
        this.implicitSubmission = false;
        this.resize = 'both';
        this.infoContainer = document.createElement('div');
        this.counterEl = document.createElement('span');
        this.helperTextEl = document.createElement('slot');
        /**
         * By default textarea do not support implicit submission, but in FAST since the component inherit FormAssociated it does.
         * https://github.com/microsoft/fast/blob/b78c921ec4e49ec9d7ec980f079ec114045df42e/packages/web-components/fast-foundation/src/form-associated/form-associated.ts
         */
        this.handleImplicitSubmission = (e) => {
            if (e.key === 'Enter') {
                if (!this.implicitSubmission) {
                    e.stopPropagation();
                    return true;
                }
            }
        };
        this.handleInput = (e) => {
            const { value } = e?.target;
            if (this.maxlength && !this.hidecounter) {
                this.counterEl.textContent = `${value.length}/${this.maxlength}`;
            }
        };
        this.handleHelperTextSlotChange = () => {
            this.render();
            const helperText = this.helperTextEl.assignedElements()[0];
            if (!helperText || !helperText.textContent) {
                return;
            }
            this.control.setAttribute('aria-describedby', helperText.id);
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.control.addEventListener('input', this.handleInput);
        this.control.addEventListener('keypress', this.handleImplicitSubmission);
        this.infoContainer.classList.add('info');
        this.counterEl.classList.add('counter');
        this.shadowRoot?.appendChild(this.infoContainer);
        this.addHelperText();
        this.addIdForLabel();
        this.setAccessibleLabel();
        this.render();
    }
    disconnectedCallback() {
        this.control.removeEventListener('input', this.handleInput);
        this.control.removeEventListener('keypress', this.handleImplicitSubmission);
        this.helperTextEl.removeEventListener('slotchange', this.handleHelperTextSlotChange);
    }
    // we cannot add a maxlengthChanged method since it's `private` in FAST and would throw several TS erros
    // this is a workaround for that.
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        if (name === 'readonly' && newValue) {
            this.setAttribute('aria-readonly', 'true');
        }
        else if (name === 'readonly') {
            this.removeAttribute('aria-readonly');
        }
        this.control?.setAttribute('aria-disabled', `${this.hasAttribute('disabled')}`);
        this.control?.addEventListener('keydown', (event) => {
            if (this.hasAttribute('disabled') && event.key !== 'Tab') {
                event.preventDefault();
            }
        });
        if (this.optional || this.textContent?.includes('optional')) {
            this.control?.removeAttribute('aria-required');
        }
        else {
            this.control?.setAttribute('aria-required', 'true');
        }
        this.render();
    }
    addIdForLabel() {
        const label = this.shadowRoot?.querySelector('label');
        if (label) {
            label.id = uniqueId('label-');
            this.control.setAttribute('aria-labelledby', label.id);
        }
    }
    render() {
        if (this.maxlength && !this.hidecounter) {
            this.renderCounter();
        }
        else {
            this.removeCounter();
        }
    }
    hasHelperText() {
        const helperText = this.querySelector('[slot="helpertext"]');
        return helperText !== null;
    }
    addHelperText() {
        this.helperTextEl.setAttribute('name', 'helpertext');
        this.helperTextEl.setAttribute('id', uniqueId('helpertext-'));
        this.helperTextEl.addEventListener('slotchange', this.handleHelperTextSlotChange);
        const helperText = this.shadowRoot?.querySelector('[name="helpertext"]');
        if (!helperText) {
            this.infoContainer?.prepend(this.helperTextEl);
        }
    }
    renderCounter() {
        if (!this.maxlength) {
            return;
        }
        if (!this.hasHelperText()) {
            this.infoContainer.style.justifyContent = 'flex-end';
        }
        else {
            this.infoContainer.style.justifyContent = 'space-between';
        }
        this.counterEl.textContent = `${this.value.length}/${this.maxlength}`;
        this.infoContainer?.appendChild(this.counterEl);
    }
    removeCounter() {
        if (this.counterEl?.isConnected) {
            this.infoContainer.style.justifyContent = '';
            this.counterEl.remove();
        }
    }
    setAccessibleLabel() {
        if (this.textContent?.trim() && this.helperTextEl.assignedElements()[0]?.textContent !== this.textContent?.trim()) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: TextArea component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "success", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'hide-counter' })
], TextAreaComponent.prototype, "hidecounter", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "optional", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'implicit-submission' })
], TextAreaComponent.prototype, "implicitSubmission", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], TextAreaComponent.prototype, "labelId", void 0);
__decorate([
    attr
], TextAreaComponent.prototype, "resize", void 0);
