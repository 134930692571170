import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonSvg } from '../Svg';
import { chameleonProgressStep } from './ProgressStep/ProgressStep.definition';
import { chameleonProgressStepPanel } from './ProgressStepPanel/ProgressStepPanel.definition';
import { chameleonProgressStepper } from './ProgressStepper.definition';
import { chameleonTooltipV2 } from '../TooltipV2';
import { chameleonTypography } from '../Typography/Typography.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonTypography({ prefix }), chameleonProgressStepper({ prefix }), chameleonProgressStep({ prefix }), chameleonProgressStepPanel({ prefix }), chameleonSvg({ prefix }), chameleonTooltipV2({ prefix }));
}
