import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const AccordionItem = forwardRef((_a, ref) => {
    var { children, heading, leadingIcon, onChange, chevronPosition } = _a, props = __rest(_a, ["children", "heading", "leadingIcon", "onChange", "chevronPosition"]);
    const accordionItemRef = useRef();
    const handledRef = handleForwardRef(accordionItemRef, ref);
    const handleOnChangeEvent = (event) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
    };
    useEffect(() => {
        var _a;
        (_a = accordionItemRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('change', handleOnChangeEvent);
        return () => {
            var _a;
            (_a = accordionItemRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', handleOnChangeEvent);
        };
    }, [onChange, ref]);
    return createReactWrapper({
        tagName: 'chameleon-accordion-item',
        props: Object.assign(Object.assign({}, props), { slot: 'item', ref: handledRef, 'chevron-position': chevronPosition }),
        children: (_jsxs(_Fragment, { children: [children, heading && _jsx("div", { slot: "heading", children: heading }), leadingIcon && addExtraProps(leadingIcon, { slot: 'start' })] })),
    });
});
