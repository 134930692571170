import { __decorate } from "tslib";
import { html } from '@microsoft/fast-element';
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
export const scrollBoxTemplate = html `<template>
  <slot></slot>
</template>`;
/**
 * This custom element reserves space for the scrollbar, so that the content doesn't jump around when the scrollbar appears.
 * The scrollbars are hidden by default and only appears when the user hovers over the element.
 *
 * Between content and the vertical scrollbar there is a minimum margin of --scrollbar-margin-left
 * and to the right of the scrollbar is a margin of --scrollbar-margin-right.
 * The component tries to achieve a right padding of --scrollbox-padding-right, but this is not possible in all browsers.
 * --------------------------------------------------------------------------------------------
 * | content | --scrollbar-margin-left + make up | scrollbar | --scrollbar-margin-right |
 * | content |                        --scrollbox-padding-right                         |
 * --------------------------------------------------------------------------------------------
 *
 * Between content and the horizontal scrollbar there is a minimum margin of --scrollbar-margin-bottom
 * and to the bottom of the scrollbar is a margin of --scrollbar-margin-top.
 * The component tries to achieve a bottom padding of --scrollbox-padding-bottom, but this is not possible in all browsers.
 * |                           content                          |
 * --------------------------------------------------------------------------------------------
 * |            --scrollbar-margin-top + make up                |
 * |                          scrollbar                         |   --scrollbox-padding-bottom
 * |                  --scrollbar-margin-bottom                 |
 * --------------------------------------------------------------------------------------------
 *
 * Usage:
 * Wrap your content in a copilot-scroll-box element.
 * Use --scrollbox-padding-right instead of padding-right.
 * Use --scrollbox-padding-bottom instead of padding-bottom.
 * Use --scrollbar-margin-left and --scrollbar-margin-right to influence the gaps around the scrollbar.
 * Use --scrollbar-margin-bottom and --scrollbar-margin-top to influence the gaps around the scrollbar.
 * Set --scrollbar-width-ff to auto or thin to influence the width of the scrollbar in Firefox.
 */
export class ScrollBoxComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.direction = 'vertical';
    }
}
__decorate([
    attr
], ScrollBoxComponent.prototype, "direction", void 0);
