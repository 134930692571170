import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, RadioButton, RadioGroup, Skeleton, Typography } from '@getgo/chameleon-web-react-wrapper';

import ACHInfo from 'components/ach-info';
import BuyNow from 'components/buy-now';
import CreditCardInfo from 'components/credit-card-info';
import DirectDebitInfo from 'components/direct-debit-info';
import PaypalInfo from 'components/paypal-info';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  chosenPaymentMethodKey,
  isAddPaymentMethodSupported,
  isDeletePaymentMethodLoading,
  isSetDefaultPaymentLoading,
  paymentMethodsIsLoading,
  paymentMethodTypes,
  setChosenPaymentMethodKey,
  setPayNowIntent,
  sortedPaymentMethodList,
} from 'modules/payment-methods';
import Track, { PaynowAddNewMethod } from 'modules/tracking';
import { AchDetails, CreditCard, DdDetails, PaypalAgreements } from 'types/payment-method';
import { ONE_PAY_INTENT, PAYMENT_METHOD_TYPES, PAYMENT_METHODS_TYPES } from 'utils/constants';
import st from 'utils/shared-translations';

import './payment-methods-available.css';

const PaymentMethodsAvailable: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const selectedPaymentTypes = useAppSelector(paymentMethodTypes);

  const selectedPaymentMethodsIsLoading = useAppSelector(paymentMethodsIsLoading);

  const selectedAddPaymentMethodIsSupported = useAppSelector(isAddPaymentMethodSupported);
  const selectedSortedPaymentMethodList = useAppSelector(sortedPaymentMethodList);

  const selectedChosenPaymentMethodKey = useAppSelector(chosenPaymentMethodKey);

  const selectedDeletePaymentMethodIsLoading = useAppSelector(isDeletePaymentMethodLoading);
  const selectedSetDefaultPaymentIsLoading = useAppSelector(isSetDefaultPaymentLoading);

  const hasPaymentType = (paymentTypes: readonly string[]) =>
    selectedPaymentTypes.some((type) => paymentTypes.includes(type));

  const handlePaymentType = (event: any) => {
    const { value } = event.currentTarget;
    dispatch(setChosenPaymentMethodKey(value));
  };

  const handleAddPaymentMethod = () => {
    Track(PaynowAddNewMethod, {});
    dispatch(setPayNowIntent(ONE_PAY_INTENT.addPayment));
  };

  return (
    <>
      {selectedPaymentMethodsIsLoading ? (
        <Skeleton
          size="xxxlarge"
          className="payment-methods-available__skeleton"
          data-testid="payment-methods-available__skeleton"
        />
      ) : (
        <>
          <Typography tag="h1" variant="heading-medium">
            {intl.formatMessage(st['payment.methods.header'])}
          </Typography>
          <RadioGroup
            className="payment-methods-available"
            aria-label="payment-method-selector"
            value={selectedChosenPaymentMethodKey}
            onChange={(event) => handlePaymentType(event)}
            data-testid="payment-methods-available-radio-group"
          >
            {selectedSortedPaymentMethodList.map(
              (paymentMethod) =>
                ({
                  [PAYMENT_METHODS_TYPES.cc]: hasPaymentType(PAYMENT_METHOD_TYPES[PAYMENT_METHODS_TYPES.cc]) && (
                    <RadioButton
                      className="payment-methods-available__radio"
                      value={paymentMethod.paymentMethodKey}
                      key={paymentMethod.paymentMethodKey}
                      data-testid="payment-methods-available__cc-radio"
                    >
                      <CreditCardInfo ccDetails={paymentMethod as CreditCard} />
                    </RadioButton>
                  ),
                  [PAYMENT_METHODS_TYPES.ach]: hasPaymentType(PAYMENT_METHOD_TYPES[PAYMENT_METHODS_TYPES.ach]) && (
                    <RadioButton
                      className="payment-methods-available__radio"
                      value={paymentMethod.paymentMethodKey}
                      key={paymentMethod.paymentMethodKey}
                      data-testid="payment-methods-available__ach-radio"
                    >
                      <ACHInfo achDetails={paymentMethod as AchDetails} />
                    </RadioButton>
                  ),
                  [PAYMENT_METHODS_TYPES.dd]: hasPaymentType(PAYMENT_METHOD_TYPES[PAYMENT_METHODS_TYPES.dd]) && (
                    <RadioButton
                      className="payment-methods-available__radio"
                      value={paymentMethod.paymentMethodKey}
                      key={paymentMethod.paymentMethodKey}
                      data-testid="payment-methods-available__dd-radio"
                    >
                      <DirectDebitInfo directDebit={paymentMethod as DdDetails} />
                    </RadioButton>
                  ),
                  [PAYMENT_METHODS_TYPES.paypal]: hasPaymentType(
                    PAYMENT_METHOD_TYPES[PAYMENT_METHODS_TYPES.paypal],
                  ) && (
                    <RadioButton
                      className="payment-methods-available__radio"
                      value={paymentMethod.paymentMethodKey}
                      key={paymentMethod.paymentMethodKey}
                      data-testid="payment-methods-available__pp-radio"
                    >
                      <PaypalInfo paypal={paymentMethod as PaypalAgreements} />
                    </RadioButton>
                  ),
                })[paymentMethod.paymentMethodType],
            )}
          </RadioGroup>

          {selectedAddPaymentMethodIsSupported && (
            <Button
              className="payment-methods-available__add"
              size="large"
              variant="secondary"
              fullWidth
              disabled={selectedDeletePaymentMethodIsLoading || selectedSetDefaultPaymentIsLoading}
              onClick={handleAddPaymentMethod}
              data-testid="payment-methods-available__add-method"
            >
              {intl.formatMessage(st['add.new.payment.method'])}
            </Button>
          )}

          <BuyNow />
        </>
      )}
    </>
  );
};

export default PaymentMethodsAvailable;
