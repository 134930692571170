const cloneKeyboardEvent = (event, options = {}) => new KeyboardEvent(event.type, {
    key: event.key,
    code: event.code,
    location: event.location,
    repeat: event.repeat,
    isComposing: event.isComposing,
    ctrlKey: event.ctrlKey,
    shiftKey: event.shiftKey,
    altKey: event.altKey,
    metaKey: event.metaKey,
    ...options,
});
export const forwardKeyboardEvent = (event, toElement) => {
    const forwardedEvent = cloneKeyboardEvent(event, { bubbles: false });
    toElement.dispatchEvent(new KeyboardEvent(event.type, forwardedEvent));
};
