import { __decorate } from "tslib";
import { SVG_CLOSE_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, when } from '@microsoft/fast-element';
import { DEV } from 'esm-env';
import { FASTElementHeightOverflowObserver } from '../Observers/FastElementHeightOverflowObserver';
import { SvgComponent } from '../Svg';
import { IconButtonComponent } from '../IconButton';
const transitionDelay250 = 250;
export const dialogTemplate = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    return html `
    <template role="dialog">
      <div part="overlay" class="goto-dialog-wrapper">
        <div part="content" :className="goto-dialog goto-dialog--${(x) => x.size}">
          <div part="heading" role="heading" aria-level="1">
            <slot name="title"></slot>
            ${when((x) => x.closable, html `<${iconButtonTag}
                @click="${(x) => x.closeHandler()}"
                label="${(x) => x.closeButtonLabel}"
                size="small"
                variant="primary"
                class="close-button"
              >
                <${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}>
              </${iconButtonTag}>`)}
          </div>
          <div part="body" class="content">
            <slot></slot>
          </div>
          <div part="actions">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </template>
  `;
};
export class DialogComponent extends FASTElementHeightOverflowObserver {
    constructor() {
        super(...arguments);
        this.open = false;
        this.closable = false;
        this.size = 'medium';
        this.closeButtonLabel = undefined;
        this.preventClose = false;
        this.wrapperElement = null;
        this.handleBackgroundClick = (e) => {
            if (e.target === this.wrapperElement && this.closable) {
                this.closeHandler();
            }
        };
        this.handleEscapeKey = (e) => {
            if (e.key === 'Escape' && this.closable) {
                this.closeHandler();
            }
        };
    }
    openChanged(_oldValue, newValue) {
        this.refreshVisibleState(newValue);
    }
    connectedCallback() {
        super.connectedCallback();
        const title = this.querySelector('[slot="title"]');
        if (title) {
            this.setAttribute('aria-label', title.textContent ?? '');
        }
        this.wrapperElement = this.shadowRoot?.querySelector('.goto-dialog-wrapper');
        const content = this.wrapperElement?.querySelector('.content');
        this.observer.observe(content);
        this.refreshVisibleState(this.hasAttribute('open'));
        if (DEV && this.closable && !this.closeButtonLabel) {
            console.error('CHAMELEON-A11Y: Closable dialog component must have an accessible label for the X icon provided via the close-button-label attribute.');
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this.closeTimeout);
        this.unbindEvents();
    }
    show() {
        this.open = true;
        this.bindEvents();
    }
    closeHandler() {
        if (this.preventClose) {
            this.$emit('before-close');
            return;
        }
        this.close();
    }
    close() {
        this.open = false;
        this.$emit('closing', null, { bubbles: false });
        this.delayedClosedEvent();
        this.unbindEvents();
    }
    handleElementHeightOverflow(el) {
        if (el) {
            const isElementOverflow = el.clientHeight < el.scrollHeight;
            el.classList.toggle('content--overflow', isElementOverflow);
        }
    }
    bindEvents() {
        this.wrapperElement?.addEventListener('mousedown', this.handleBackgroundClick);
        document.addEventListener('keydown', this.handleEscapeKey);
    }
    unbindEvents() {
        this.wrapperElement?.removeEventListener('mousedown', this.handleBackgroundClick);
        document.removeEventListener('keydown', this.handleEscapeKey);
    }
    delayedClosedEvent() {
        this.closeTimeout = window.setTimeout(() => {
            this.$emit('closed', null, { bubbles: false });
        }, transitionDelay250);
    }
    refreshVisibleState(isOpen) {
        this.wrapperElement?.classList.toggle('visible', isOpen);
        if (isOpen) {
            this.bindEvents();
        }
        else {
            this.unbindEvents();
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], DialogComponent.prototype, "open", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DialogComponent.prototype, "closable", void 0);
__decorate([
    attr
], DialogComponent.prototype, "size", void 0);
__decorate([
    attr({ attribute: 'close-button-label' })
], DialogComponent.prototype, "closeButtonLabel", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'prevent-close' })
], DialogComponent.prototype, "preventClose", void 0);
