import { __decorate } from "tslib";
import { attr, html, observable, when } from '@microsoft/fast-element';
import { SVG_COPY_OUTLINED } from '@getgo/chameleon-icons';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
import { SvgComponent } from '../Svg';
import { IconButtonComponent } from '../IconButton';
import { TooltipV2Component } from '../TooltipV2';
const copyButton = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    return html `<${iconButtonTag}
  id="copy-button"
  size="large"
  slot="trigger"
  @click=${(x) => x.copyToClipboard()}
  label="Copy to clipboard"
>
  <${svgTag}>${SVG_COPY_OUTLINED}</${svgTag}>
</${iconButtonTag}>`;
};
const inlineTemplate = (context, _definition) => {
    const tooltipV2Tag = context.tagFor(TooltipV2Component);
    return html `
    <${tooltipV2Tag}
      style="${(x) => `--z-index: ${x.tooltipZIndex}`}"
      :hidden="${(x) => !x.showTooltip}"
      part="tooltip"
      position="top-center"
    >
      <pre @click=${(x) => x.copyToClipboard()} slot="trigger"><slot></slot></pre>
      <span slot="content">${(x) => (x.copyClicked ? x.copiedString : x.copyString)}</span>
    </${tooltipV2Tag}>
  `;
};
const defaultTemplate = (context, definition) => {
    const tooltipV2Tag = context.tagFor(TooltipV2Component);
    return html `
  <pre><slot></slot></pre>
  <${tooltipV2Tag} style="${(x) => `--z-index: ${x.tooltipZIndex}`}" part="tooltip" position="top-center">
    ${copyButton(context, definition)}
    <span slot="content"> ${(x) => (x.copyClicked ? x.copiedString : x.copyString)} </span>
  </${tooltipV2Tag}>
`;
};
export const codeSnippetTemplate = (context, _definition) => {
    return html `
    ${when((x) => x.line !== 'inline', () => defaultTemplate(context, _definition))}
    ${when((x) => x.line === 'inline', () => inlineTemplate(context, _definition))}
  `;
};
export class CodeSnippetComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.copyString = 'Copy to clipboard';
        this.copiedString = 'Copied to clipboard';
        this.showTooltip = false;
        this.line = 'singleline';
        this.copyClicked = false;
    }
    getContentToCopy() {
        const slot = this.shadowRoot?.querySelector('slot');
        const nodes = slot?.assignedNodes();
        return nodes
            ?.map((node) => node.textContent ?? '')
            .reverse()
            .reduce((accumulator, currentValue) => (currentValue = accumulator !== '' ? `${currentValue}${accumulator}` : currentValue), '');
    }
    copyToClipboard() {
        if (this.copyClicked) {
            clearTimeout(this.copiedTimer);
        }
        const content = this.getContentToCopy();
        if (content) {
            navigator.clipboard
                .writeText(content)
                .then(() => {
                this.$emit('copied', content, { bubbles: false });
            })
                .then(() => {
                this.copyClicked = true;
                this.copiedTimer = setTimeout(() => {
                    this.copyClicked = false;
                }, 3000);
            });
        }
    }
}
__decorate([
    attr({ attribute: 'copy-string' })
], CodeSnippetComponent.prototype, "copyString", void 0);
__decorate([
    attr({ attribute: 'copied-string' })
], CodeSnippetComponent.prototype, "copiedString", void 0);
__decorate([
    attr({ attribute: 'show-tooltip', mode: 'boolean' })
], CodeSnippetComponent.prototype, "showTooltip", void 0);
__decorate([
    attr({ attribute: 'tooltip-z-index' })
], CodeSnippetComponent.prototype, "tooltipZIndex", void 0);
__decorate([
    attr()
], CodeSnippetComponent.prototype, "line", void 0);
__decorate([
    observable
], CodeSnippetComponent.prototype, "copyClicked", void 0);
__decorate([
    observable
], CodeSnippetComponent.prototype, "copiedTimer", void 0);
