import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonNavigationDrawer } from './NavigationDrawer.definition';
import { chameleonNavigationDrawerHeader } from './NavigationDrawerHeader/NavigationDrawerHeader.definition';
import { chameleonNavigationDrawerItem } from './NavigationDrawerItem/NavigationDrawerItem.definition';
import { chameleonNavigationDrawerLink } from './NavigationDrawerLink/NavigationDrawerLink.definition';
import { chameleonNavigationDrawerSection } from './NavigationDrawerSection/NavigationDrawerSection.definition';
import { chameleonTypography } from '../Typography/Typography.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonTypography({ prefix }), chameleonNavigationDrawer({ prefix }), chameleonNavigationDrawerLink({ prefix }), chameleonNavigationDrawerItem({ prefix }), chameleonNavigationDrawerHeader({ prefix }), chameleonNavigationDrawerSection({ prefix }));
}
