import { __rest } from "tslib";
import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
import { ChangeSyntheticEvent } from '../common/SyntheticEvent';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const RadioGroup = forwardRef((_a, ref) => {
    var { children, onChange, label, labelId } = _a, otherProps = __rest(_a, ["children", "onChange", "label", "labelId"]);
    const radioButtonRef = useRef();
    const handledRef = handleForwardRef(radioButtonRef, ref);
    const handleChange = (event) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(new ChangeSyntheticEvent(event));
    };
    useEffect(() => {
        var _a;
        (_a = radioButtonRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('change', handleChange);
        return () => {
            var _a;
            (_a = radioButtonRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', handleChange);
        };
    }, [ref, onChange]);
    return createReactWrapper({
        tagName: 'chameleon-radio-group',
        props: Object.assign(Object.assign({}, otherProps), { ref: handledRef, 'label-id': labelId }),
        children: (_jsxs(_Fragment, { children: [label && addExtraProps(label, { slot: 'label' }), children] })),
    });
});
