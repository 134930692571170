import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, AccordionItem, MenuSeparator, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppSelector } from 'hooks';
import useViewPortSize from 'hooks/useViewport';
import { sessionCurrencyCode, sessionEmail, sessionQuoteId, sessionTotalAmount } from 'modules/session-details';
import { RECEIPIENT } from 'utils/constants';
import st from 'utils/shared-translations';
import QuoteInfo from './quote-info';

import './price-info.css';

const PriceInfo: FC = (): JSX.Element => {
  const intl = useIntl();

  const viewport = useViewPortSize();

  const selectedEmail = useAppSelector(sessionEmail);
  const selectedQuoteId = useAppSelector(sessionQuoteId);
  const selectedTotalAmount = useAppSelector(sessionTotalAmount);
  const selectedCurrency = useAppSelector(sessionCurrencyCode);

  const isMobile = viewport.width < 650;

  return (
    <section className="price-info">
      {isMobile ? (
        <Accordion>
          <AccordionItem
            className="price-info__reference"
            heading={<QuoteInfo headerString="reference.number" value={selectedQuoteId} />}
          >
            <div className="price-info__recipient">
              {<QuoteInfo headerString="price.info.recipient" value={RECEIPIENT} />}
            </div>
            <div className="price-info__contact">
              {<QuoteInfo headerString="your.contact.info" value={selectedEmail} />}
            </div>
          </AccordionItem>
        </Accordion>
      ) : (
        <ul className="price-info__list">
          <li className="price-info__reference">
            <QuoteInfo headerString="reference.number" value={selectedQuoteId} />
          </li>
          <li className="price-info__recipient">
            <QuoteInfo headerString="price.info.recipient" value={RECEIPIENT} />
          </li>
          <li className="price-info__contact">
            <QuoteInfo headerString="your.contact.info" value={selectedEmail} />
          </li>
        </ul>
      )}

      <MenuSeparator className="price-info__separater" />
      <section className="price-info__total">
        <Typography tag="h1" variant="body-small-strong">
          {intl.formatMessage(st['price.info.total'])}
        </Typography>
        <Typography tag="h1" variant="body-small-strong">
          {intl.formatNumber(selectedTotalAmount, {
            style: 'currency',
            currency: selectedCurrency,
          })}
        </Typography>
      </section>
    </section>
  );
};

export default PriceInfo;
