import { withStylePrefix } from '../common/withStylePrefix';
import { FileThumbnailComponent, fileThumbnailTemplate } from './FileThumbnail';
import styles from './FileThumbnail.styles.scss';
export const fileThumbnail = FileThumbnailComponent.compose({
    baseName: 'file-thumbnail',
    template: fileThumbnailTemplate,
    styles: withStylePrefix(styles),
    shadowOptions: {
        delegatesFocus: true,
    },
});
