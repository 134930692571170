/**
 * useViewPortSize hook
 * @description This hook is used to get the viewport size of the window.
 * @returns {Object} Returns an object with width and height of the viewport.
 * It's debounced value is set to 500ms to avoid performance issues.
 */

import { useEffect, useState } from 'react';

export default function useViewPortSize(): { width: number; height: number } {
  const [viewPort, setViewPort] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    let timeoutId: number;

    const setViewPortSize = () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        setViewPort({ width: window.innerWidth, height: window.innerHeight });
      }, 500);
    };

    window.addEventListener('resize', setViewPortSize, false);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', setViewPortSize);
    };
  }, []);

  return viewPort;
}
