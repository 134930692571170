/**
 * This helper function is to help trace the path of an event.
 * Some framework like lit retarget events from a shadowRoot element has
 * it was triggered by the host itself which is sometimes ok but maybe not
 * as a default behaviour...
 * https://lit-element.polymer-project.org/guide/events#event-retargeting
 */
export const getHostChildrenFromEvent = (event, host) => {
    const elements = event.composedPath();
    return elements.reduce((acc, el) => {
        return el.nodeName && host.contains(el) ? [...acc, el] : acc;
    }, []);
};
export const isPointOverRect = (bounds, point) => {
    if (point.x >= bounds.left &&
        point.x <= bounds.left + bounds.width &&
        point.y >= bounds.top &&
        point.y <= bounds.top + bounds.height) {
        return true;
    }
    return false;
};
/**
 * This function increases the dimensions of the given DOMRect in the given direction. Useful for the popover/tooltip
 * so we can let the mouse move over to the popup element without it triggering the global mousemove handler that closes the popover.
 *
 * Edge case: Regardless of the `direction` argument's value, we have to increase the dimensions in the `left` direction by 2 pixels, because when the mouse moves to the
 * trigger and changes from a normal cursor to a pointer, the pointer's top left corner is 2 pixels left compared to the normal cursor
 * and this can trigger the global mousemove handler which closes the popover.
 * */
export const inflateRectInDirection = (rect, value, direction) => {
    const rectObject = {
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        width: rect.width,
        x: rect.x,
        y: rect.y,
        top: rect.top,
        height: rect.height,
    };
    switch (direction) {
        case 'top':
            return {
                ...rectObject,
                left: rect.left - 2,
                width: rect.width + 2,
                top: rect.top - value,
                height: rect.height + value,
            };
        case 'bottom':
            return {
                ...rectObject,
                left: rect.left - 2,
                width: rect.width + 2,
                bottom: rect.bottom + value,
                height: rect.height + value,
            };
        case 'left':
            return {
                ...rectObject,
                left: rect.left - value,
                width: rect.width + value,
            };
        case 'right':
            return {
                ...rectObject,
                left: rect.left - 2,
                right: rect.right + value,
                width: rect.width + value + 2,
            };
        default:
            return {
                ...rectObject,
                top: rect.top - value,
                left: rect.left - value,
                width: rect.width + value * 2,
                height: rect.height + value * 2,
            };
    }
};
export const getTabbableChildren = (element) => {
    const elementToQuery = element.shadowRoot ?? element;
    return elementToQuery.querySelectorAll('[tabindex]:not([tabindex="-1"])');
};
/**
 * Finds the closest theme provider to the provided baseElement going up the DOM.
 * This is necessary because simply calling .closest() does not go through the shadow-root
 */
export const findClosestThemeProvider = (baseElement, prefix = 'chameleon') => {
    const themeProviderSelector = `chameleon-theme-provider, ${prefix}-theme-provider`;
    if (!baseElement) {
        const provider = document.querySelector(themeProviderSelector);
        return provider ?? undefined;
    }
    const provider = baseElement.closest(themeProviderSelector);
    if (provider) {
        return provider;
    }
    else {
        const rootNode = baseElement.getRootNode();
        if (rootNode instanceof ShadowRoot) {
            return findClosestThemeProvider(rootNode.host);
        }
        return findClosestThemeProvider(rootNode.parentElement);
    }
};
export function findParent(element, filter) {
    let currentElement = element;
    while (currentElement && currentElement !== document.body.parentElement) {
        currentElement = currentElement.parentElement;
        if (currentElement && filter(currentElement)) {
            return currentElement;
        }
    }
    return null;
}
export function findChild(element, filter) {
    for (let i = 0; i < (element?.children.length ?? 0); i++) {
        const currentElement = element?.children.item(i);
        if (currentElement) {
            if (filter(currentElement)) {
                return currentElement;
            }
            const foundElement = findChild(currentElement, filter);
            if (foundElement) {
                return foundElement;
            }
        }
    }
    return null;
}
/**
 * Gets all the chameleon elements that are the child of the element supplied as the first argument
 * */
export function getChameleonElements(root) {
    const prefixWithDash = root.tagName.toLowerCase().replace(root.baseName, '');
    const allElements = Array.from(root.getElementsByTagName('*'));
    return allElements.filter((elem) => elem.tagName.toLowerCase().startsWith(prefixWithDash));
}
/**
 * Given an array of elements and a property to search for, returns an array of elements
 * that have that property defined on their prototype
 *
 * */
export function getElementsWithProperty(elements, property) {
    return elements.filter((element) => {
        const prototype = Object.getPrototypeOf(element);
        return prototype && prototype.hasOwnProperty(property);
    });
}
export function removeAllChildren(element) {
    if (!element.hasChildNodes()) {
        return;
    }
    while (element.firstChild) {
        const child = element.firstChild;
        if (child instanceof HTMLElement) {
            removeAllChildren(child);
        }
        element.removeChild(child);
    }
}
/**
 * This function receives an id and element which will be the start point in the DOM to search for
 * that given id. It moves *up* the DOM tree, looking for the element with the matching id piercing through shadow roots.
 * */
export function traverseShadowRootsForElementWithId(id, element) {
    // Check if current element or its descendants contain the desired id
    const found = element.querySelector ? element.querySelector(`#${CSS.escape(id)}`) : null;
    if (found)
        return found;
    // If current element is ShadowRoot, go to the host element
    if (element instanceof ShadowRoot) {
        return traverseShadowRootsForElementWithId(id, element.host);
    }
    // If current element has a parent node, continue the search
    if (element.parentNode) {
        return traverseShadowRootsForElementWithId(id, element.parentNode);
    }
    // If we reach here, the element wasn't found
    return null;
}
