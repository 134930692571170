import { __decorate } from "tslib";
import { attr, observable, volatile } from '@microsoft/fast-element';
import { NavigationRailHeightOverflowObserver } from '../Observers/FastElementHeightOverflowObserver';
import { DEV } from 'esm-env';
import { SVG_MORE_MENU_HORIZONTAL_OUTLINED } from '@getgo/chameleon-icons';
// PREFIXING : This component does not support prefixing
const ITEM_SELECTOR = 'chameleon-navigation-rail-item:not([part=toggle-button]), chameleon-navigation-rail-link, chameleon-internal-navigation-rail-item, .chameleon-custom-navigation-rail-item, .more-items-popover';
const TOGGLE_SELECTOR = 'chameleon-navigation-rail-item[part=toggle-button]';
const MORE_ITEM_HEIGHT = 80;
const MIN_SEPARATOR_HEIGHT = 20;
const ITEMS_SECONDARY_ITEMS_MARGIN = 24;
const PADDING = 8;
// @ts-expect-error itemsChanged is private in FAST, but we need to hook into this to add custom logic when slotted items change
export class NavigationRailComponent extends NavigationRailHeightOverflowObserver {
    constructor() {
        super(...arguments);
        this.moreLabel = 'More';
        this.collapseLabel = 'Collapse';
        this.expandLabel = 'Expand';
        this.shouldDisplaySecondaryItems = false;
        this.secondaryItems = [];
        // TODO: change it to expanded after size is removed
        this.state = 'expanded';
        this.size = 'large';
        this.volatileItems = [];
        this.popoverElement = null;
        this.popoverTrigger = null;
        this.createPopover = () => {
            const popoverTrigger = document.createElement('chameleon-navigation-rail-item');
            popoverTrigger.size = this.size;
            popoverTrigger.state = this.state;
            popoverTrigger.role = 'menuitem';
            popoverTrigger.id = 'more-items';
            popoverTrigger.innerHTML = `<chameleon-svg slot="start">${SVG_MORE_MENU_HORIZONTAL_OUTLINED}</chameleon-svg>
      ${this.moreLabel}`;
            const popoverElement = document.createElement('chameleon-popover-v2');
            popoverElement.classList.add('more-items-popover');
            popoverElement.position = 'right-center';
            popoverElement.arrow = true;
            popoverElement.menu = true;
            popoverElement.setAttribute('z-index', '100');
            popoverElement.setAttribute('trigger-id', 'more-items');
            popoverElement.innerHTML = `
        <chameleon-menu label="${this.moreLabel}" style="border-radius: 8px" size=${this.size}></chameleon-menu>`;
            popoverElement.addEventListener('popoverchange', this.handlePopoverChange);
            return [popoverElement, popoverTrigger];
        };
        this.handlePopoverChange = (e) => {
            const navRailItem = this.popoverElement?.querySelector('chameleon-navigation-rail-item');
            const tooltip = navRailItem?.shadowRoot?.querySelector('chameleon-tooltip-v3');
            tooltip?.setAttribute('hidden', `${e.detail.isOpen}`);
        };
    }
    get toggleLabel() {
        if (this.state !== 'expanded') {
            return this.expandLabel;
        }
        return this.collapseLabel;
    }
    secondaryItemsChanged(_, changed) {
        this.shouldDisplaySecondaryItems = changed.length > 0;
        for (const element of changed) {
            // We get nodes that are not elements (text node, comment node), we need to filter them.
            if (!element || element.nodeType !== element.ELEMENT_NODE)
                continue;
            if (element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-ITEM' &&
                element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-LINK' &&
                !element.classList.contains('more-items-popover')) {
                element.classList.add('chameleon-custom-navigation-rail-item');
                element.setAttribute('role', 'menuitem');
            }
        }
    }
    labelChanged() {
        if (this.isConnected) {
            this.setAccessibleLabel();
        }
    }
    stateChanged(_, newValue) {
        const items = Array.from(this.querySelectorAll(ITEM_SELECTOR));
        if (newValue === 'collapsed') {
            this.size = 'small';
        }
        else if (newValue === 'expanded') {
            this.size = 'large';
        }
        items.forEach((item) => {
            if (newValue === 'collapsed') {
                item.setAttribute('state', 'collapsed');
            }
            else if (newValue === 'expanded') {
                item.setAttribute('state', 'expanded');
            }
            else {
                item.removeAttribute('state');
            }
        });
    }
    sizeChanged(_, changed) {
        const items = Array.from(this.querySelectorAll(ITEM_SELECTOR));
        if (changed === 'small') {
            this.state = 'collapsed';
        }
        else if (changed === 'large' || changed === 'medium') {
            this.state = 'expanded';
        }
        else {
            this.removeAttribute('size');
            this.removeAttribute('state');
        }
        items.forEach((item) => {
            if (changed === 'small') {
                item.setAttribute('size', 'small');
                item.setAttribute('state', 'collapsed');
            }
            else if (changed === 'large' || changed === 'medium') {
                item.setAttribute('size', 'large');
                item.setAttribute('state', 'expanded');
            }
            else {
                item.removeAttribute('size');
                item.removeAttribute('state');
            }
        });
    }
    itemsChanged(prev, next) {
        // @ts-expect-error itemsChanged is private in FAST, but we need to hook into this to add custom logic when slotted items change
        super.itemsChanged(prev, next);
        for (const element of next) {
            // We get nodes that are not elements (text node, comment node), we need to filter them.
            if (!element || element.nodeType !== element.ELEMENT_NODE)
                continue;
            if (element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-ITEM' &&
                element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-LINK' &&
                !element.classList.contains('more-items-popover')) {
                element.classList.add('chameleon-custom-navigation-rail-item');
                element.setAttribute('role', 'menuitem');
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        if (this.size === 'medium') {
            this.size = 'large';
        }
    }
    toggle() {
        if (this.size !== 'large' || this.state !== 'expanded') {
            this.size = 'large';
            this.state = 'expanded';
        }
        else {
            this.size = 'small';
            this.state = 'collapsed';
        }
        this.$emit('navrail-toggle', null, { bubbles: false });
    }
    validateOverflow(el) {
        this.resetVolatileItems();
        this.removePopover();
        this.popoverElement?.remove();
        this.popoverTrigger?.remove();
        const items = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') !== 'secondary-items');
        const secondaryItems = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') === 'secondary-items');
        const firstSecondaryItemRef = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') === 'secondary-items')[0];
        const toggleButton = this.shadowRoot?.querySelector(TOGGLE_SELECTOR);
        if (!el.clientHeight && !toggleButton)
            return;
        const itemsHeight = items.reduce((acc, item) => {
            return acc + item.offsetHeight;
        }, 0);
        const secondaryItemsHeight = secondaryItems.reduce((acc, item) => {
            return acc + item.offsetHeight;
        }, 0);
        const availableSpace = el.clientHeight -
            toggleButton.offsetHeight -
            secondaryItemsHeight -
            MIN_SEPARATOR_HEIGHT -
            ITEMS_SECONDARY_ITEMS_MARGIN * 2 -
            PADDING * 2;
        if (itemsHeight < availableSpace)
            return;
        const [popover, popoverTrigger] = this.createPopover();
        this.popoverTrigger = popoverTrigger;
        this.popoverElement = popover;
        const popoverMenu = popover.querySelector('chameleon-menu');
        if (!popoverMenu)
            return;
        let heightUsed = 0;
        this.volatileItems = items.reduce((acc, item) => {
            heightUsed += item.offsetHeight;
            if (heightUsed > availableSpace - MORE_ITEM_HEIGHT) {
                return [...acc, item];
            }
            return acc;
        }, []);
        this.volatileItems.forEach((item) => {
            item.setAttribute('appearance', 'menuitem');
            item.setAttribute('size', 'large');
            item.addEventListener('change', this.handleMenuItemClick.bind(this));
            popoverMenu.appendChild(item);
        });
        this.insertBefore(popoverTrigger, firstSecondaryItemRef);
        this.insertBefore(popover, firstSecondaryItemRef);
    }
    resetVolatileItems() {
        const firstSecondaryItemRef = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') === 'secondary-items')[0];
        if (this.volatileItems.length) {
            this.volatileItems.forEach((item) => {
                item.setAttribute('appearance', 'navrailitem');
                item.setAttribute('size', this.size);
                item.setAttribute('state', this.state);
                item.removeEventListener('menuitemclick', this.handleMenuItemClick);
                this.insertBefore(item, firstSecondaryItemRef);
            });
        }
    }
    removePopover() {
        this.popoverElement?.removeEventListener('popoverchange', this.handlePopoverChange);
        this.popoverElement?.blur();
        this.popoverElement?.remove();
        this.popoverTrigger?.remove();
    }
    handleMenuItemClick() {
        this.popoverElement?.close();
    }
    handlePopoverFocus(e) {
        e.target.firstChild.focus();
    }
    handleCollapseButtonKeyup(e) {
        if (e.key === 'Enter' || e.key === ' ') {
            this.toggle();
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    setAccessibleLabel() {
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-label')?.length) {
            console.error(`CHAMELEON-A11Y: NavigationRail component must have an accessible label provided by a non-empty label attribute to be used as aria-label.`);
        }
    }
}
__decorate([
    attr({ attribute: 'more-label' })
], NavigationRailComponent.prototype, "moreLabel", void 0);
__decorate([
    attr({ attribute: 'collapse-label' })
], NavigationRailComponent.prototype, "collapseLabel", void 0);
__decorate([
    attr({ attribute: 'expand-label' })
], NavigationRailComponent.prototype, "expandLabel", void 0);
__decorate([
    observable
], NavigationRailComponent.prototype, "shouldDisplaySecondaryItems", void 0);
__decorate([
    volatile
], NavigationRailComponent.prototype, "toggleLabel", null);
__decorate([
    observable
], NavigationRailComponent.prototype, "secondaryItems", void 0);
__decorate([
    attr
], NavigationRailComponent.prototype, "label", void 0);
__decorate([
    attr
], NavigationRailComponent.prototype, "state", void 0);
__decorate([
    attr
], NavigationRailComponent.prototype, "size", void 0);
