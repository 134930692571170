import { __rest } from "tslib";
import { createReactWrapper } from '../common/PrefixUtils';
import { forwardRef, useEffect, useRef } from 'react';
import { handleForwardRef } from '../common/RefUtils';
export const Accordion = forwardRef((_a, ref) => {
    var { chevronPosition, children, expandMode, onChange } = _a, props = __rest(_a, ["chevronPosition", "children", "expandMode", "onChange"]);
    const accordionRef = useRef();
    const handledRef = handleForwardRef(accordionRef, ref);
    const handleOnChangeEvent = (event) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
    };
    useEffect(() => {
        var _a;
        (_a = accordionRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('change', handleOnChangeEvent);
        return () => {
            var _a;
            (_a = accordionRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', handleOnChangeEvent);
        };
    }, [ref, onChange]);
    return createReactWrapper({
        tagName: 'chameleon-accordion',
        props: Object.assign(Object.assign({}, props), { 'expand-mode': expandMode, 'chevron-position': chevronPosition, ref: handledRef }),
        children,
    });
});
