import { Accordion, accordionTemplate as template } from '@microsoft/fast-foundation';
import { AccordionComponent } from './Accordion';
import styles from './Accordion.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const chameleonAccordion = AccordionComponent.compose({
    baseName: 'accordion',
    baseClass: Accordion,
    template: template,
    styles: withStylePrefix(styles),
});
