
import React from 'react';
const createIconComponent = ({ content, height, width }) => ({ size, color, prefix, style = {}, ...props}) => {
  const resolvedColor = style?.color || color || '';
  const resolvedSize = size ? size : '';
  const resolvedTag = prefix ? (prefix + '-svg') : 'chameleon-svg';

  const allProps = {
    ...props,
    style: {
      ...style,
      '--goto-icon-color': resolvedColor,
      '--goto-icon-size': resolvedSize,
    },
    width,
    height,
  };

  return React.createElement(resolvedTag, allProps, content);
};

export default createIconComponent;
