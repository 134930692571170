import { __decorate } from "tslib";
import { attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { SVG_CLOSE_OUTLINED, SVG_PERSON_OUTLINED } from '@getgo/chameleon-icons';
import { FoundationElement } from '@microsoft/fast-foundation';
import { SvgComponent } from '../Svg';
export const chipTemplate = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    return html `<template role="button" tabindex="0" @click=${(x) => x.action()}>
    ${when((x) => x.person, () => html `<${svgTag} class="person-icon">${SVG_PERSON_OUTLINED}</${svgTag}>`)}
    <slot ${slotted('slottedAvatar')} name="avatar"></slot>
    <slot></slot>
    ${when((x) => x.closable, () => html `<span class="close" @click=${(x, { event }) => x.close(event)}
          ><${svgTag}>${SVG_CLOSE_OUTLINED}</${svgTag}></span
        >`)}
  </template>`;
};
export class ChipComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.handleKeyDown = (event) => {
            const { key } = event;
            if (key === ' ' || key === 'enter') {
                this.action();
                event.preventDefault();
            }
        };
    }
    sizeChanged(_, newValue) {
        if (this.slottedAvatar && this.slottedAvatar?.length > 0) {
            this.slottedAvatar.forEach((avatar) => {
                avatar.setAttribute('size', `${newValue}`);
                if (newValue === null) {
                    avatar.setAttribute('size', `medium`);
                }
            });
        }
    }
    disabledChanged(_, newValue) {
        requestAnimationFrame(() => {
            if (this.slottedAvatar && this.slottedAvatar?.length > 0) {
                this.slottedAvatar.forEach((avatar) => {
                    avatar.setAttribute('disabled', `${newValue}`);
                    if (newValue === null) {
                        avatar.setAttribute('disabled', `medium`);
                    }
                });
            }
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
    }
    slottedAvatarChanged(_, newValue) {
        // TODO : [Backward compatibility] remove tagname check in next major version, should use icon slot
        if (newValue && newValue.length > 0 && newValue.some((element) => element.tagName.includes('AVATAR'))) {
            this.setAttribute('has-avatar', 'true');
            newValue.forEach((avatar) => {
                avatar.setAttribute('size', `${this.size}`);
                if (this.getAttribute('disabled')) {
                    avatar.setAttribute('disabled', '');
                }
            });
        }
        else {
            this.removeAttribute('has-avatar');
        }
    }
    action() {
        if (this.disabled)
            return;
        this.$emit('chip-action', null, { bubbles: true });
        // When the chips are used as links, we want to trigger the default click event so the redirect happens
        if (this.parentElement?.tagName === 'A') {
            return true;
        }
    }
    close(event) {
        if (this.disabled)
            return;
        event.stopPropagation();
        this.$emit('close', null, { bubbles: false });
    }
}
__decorate([
    attr({ mode: 'boolean' })
], ChipComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipComponent.prototype, "active", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipComponent.prototype, "closable", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipComponent.prototype, "person", void 0);
__decorate([
    attr
], ChipComponent.prototype, "size", void 0);
__decorate([
    observable
], ChipComponent.prototype, "slottedAvatar", void 0);
