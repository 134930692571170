import { __decorate } from "tslib";
import { ChameleonElementMixin } from '../../common/mixin';
import { attr } from '@microsoft/fast-element';
import { MenuItem } from '@microsoft/fast-foundation';
export class BaseNavigationRailItemComponent extends ChameleonElementMixin(MenuItem) {
    constructor() {
        super(...arguments);
        this.disabled = false;
        this.appearance = 'navrailitem';
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.querySelector('[slot="end"]')?.assignedNodes().length <= 0) {
            this.removeAttribute('data-has-end-slot');
        }
        else {
            this.setAttribute('data-has-end-slot', '');
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], BaseNavigationRailItemComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], BaseNavigationRailItemComponent.prototype, "sticky", void 0);
__decorate([
    attr({ mode: 'boolean' })
], BaseNavigationRailItemComponent.prototype, "collapse", void 0);
__decorate([
    attr({ mode: 'boolean' })
], BaseNavigationRailItemComponent.prototype, "disabled", void 0);
__decorate([
    attr
], BaseNavigationRailItemComponent.prototype, "appearance", void 0);
