import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { RadioGroup } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export class RadioGroupComponent extends ChameleonElementMixin(RadioGroup) {
    constructor() {
        super(...arguments);
        this.orientation = 'vertical';
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    setAccessibleLabel() {
        const labelSlot = this.querySelector('[slot="label"]');
        if (labelSlot?.textContent) {
            this.setAttribute('aria-label', labelSlot.textContent);
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-label')?.length && !this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: Radio Group component must have an accessible label provided by either 
  1) a non-empty label attribute to be used as aria-label 
  2) a visible label whose id is given as the attribute labelId.
`);
        }
    }
}
__decorate([
    attr
], RadioGroupComponent.prototype, "orientation", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], RadioGroupComponent.prototype, "labelId", void 0);
