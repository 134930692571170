import { withStylePrefix } from '../common/withStylePrefix';
import { AvatarGroupComponent, avatarGroupTemplate } from './AvatarGroup';
import styles from './AvatarGroup.styles.scss';
export const avatarGroupDefinition = {
    template: avatarGroupTemplate,
    styles: withStylePrefix(styles),
};
export const chameleonAvatarGroup = AvatarGroupComponent.compose({
    ...avatarGroupDefinition,
    baseName: 'avatar-group',
});
