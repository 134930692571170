import { __decorate } from "tslib";
import { SVG_SUBTRACT_OUTLINED, SVG_ADD_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonPrefixedElementMixin } from '../common/mixin';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { DEV } from 'esm-env';
import { SvgComponent } from '../Svg';
import { NumberFieldComponent } from '../NumberField';
import { IconButtonComponent } from '../IconButton';
export const inputStepperTemplate = (context, _definition) => {
    const svgTag = context.tagFor(SvgComponent);
    const numberFieldTag = context.tagFor(NumberFieldComponent);
    const iconButtonTag = context.tagFor(IconButtonComponent);
    return html `<template>
    <${numberFieldTag}
      disabled=${(x) => x.disabled}
      error=${(x) => x.error}
      :value=${(x) => x.value}
      @change=${(x, { event }) => x.handleChange(event)}
      fieldsize="${(x) => x.size}"
      :step="${(x) => x.step}"
      :max=${(x) => x.max}
      :min=${(x) => x.min}
      label-id=${(x) => x.labelId ?? 'CHAMELEON_BYPASS_LABEL_ID'}
    >
      <${iconButtonTag}
        size="${(x) => x.size}"
        slot="start"
        aria-label=${(x) => x.decrementLabel}
        @click=${(x) => x.decrement()}
        ?disabled=${(x) => x.cantGoLower()}
      >
        <${svgTag}>${SVG_SUBTRACT_OUTLINED}</${svgTag}>
      </${iconButtonTag}>
      <${iconButtonTag}
        size="${(x) => x.size}"
        slot="end"
        aria-label=${(x) => x.incrementLabel}
        ?disabled=${(x) => x.cantGoHigher()}
        @click=${(x) => x.increment()}
      >
        <${svgTag}>${SVG_ADD_OUTLINED}</${svgTag}>
      </${iconButtonTag}>
      <span class="helper-text" slot="helpertext">${(x) => x.helpertext}</span>
      <slot></slot>
    </${numberFieldTag}>
  </template>`;
};
export class InputStepperComponent extends ChameleonPrefixedElementMixin(FoundationElement, 'input-stepper') {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.value = 0;
        this.step = 1;
        this.min = 0;
        this.max = 999;
        this.helpertext = '';
        this.incrementLabel = 'Increment number';
        this.decrementLabel = 'Decrement number';
    }
    sanitizeValue() {
        this.value = Number(this.value);
    }
    addIdForLabel() {
        const label = this.shadowRoot?.querySelector(this.tagFor('number-field'))?.shadowRoot?.querySelector('label');
        if (label) {
            label.id = uniqueId('label-');
            this.shadowRoot?.querySelector(this.tagFor('number-field'))?.setAttribute('aria-labelledby', label.id);
        }
    }
    setAccessibleLabel() {
        if (this.textContent?.trim()) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: InputStepper component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.addIdForLabel();
        this.setAccessibleLabel();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        if (name === 'readonly' && newValue) {
            this.setAttribute('aria-readonly', 'true');
        }
        else if (name === 'readonly') {
            this.removeAttribute('aria-readonly');
        }
    }
    cantGoLower() {
        if (!this.disabled) {
            return this.value <= Number(this.min);
        }
        else {
            return true;
        }
    }
    cantGoHigher() {
        if (!this.disabled) {
            return this.value >= Number(this.max);
        }
        else {
            return true;
        }
    }
    increment() {
        if (this.cantGoHigher()) {
            return;
        }
        this.sanitizeValue();
        this.value += Number(this.step);
        this.$emit('change', null, { bubbles: false });
    }
    decrement() {
        if (this.cantGoLower()) {
            return;
        }
        this.sanitizeValue();
        this.value -= Number(this.step);
        this.$emit('change', null, { bubbles: false });
    }
    handleChange(event) {
        this.sanitizeValue();
        this.value = event.target.value;
        this.$emit('change', null, { bubbles: false });
    }
}
__decorate([
    attr
], InputStepperComponent.prototype, "size", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "value", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "step", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "min", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "max", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputStepperComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputStepperComponent.prototype, "error", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "helpertext", void 0);
__decorate([
    attr({ attribute: 'increment-aria-label' })
], InputStepperComponent.prototype, "incrementLabel", void 0);
__decorate([
    attr({ attribute: 'decrement-aria-label' })
], InputStepperComponent.prototype, "decrementLabel", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], InputStepperComponent.prototype, "labelId", void 0);
