import { __decorate } from "tslib";
import { TreeItem } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { SVG_CHEVRON_RIGHT_OUTLINED } from '@getgo/chameleon-icons';
import { ChameleonPrefixedElementMixin } from '../../common/mixin';
export class TreeItemComponent extends ChameleonPrefixedElementMixin(TreeItem, 'tree-item') {
    connectedCallback() {
        super.connectedCallback();
        const collapseIcon = this.querySelector('[slot="expand-collapse-glyph"]');
        if (!collapseIcon) {
            const icon = document.createElement(this.tagFor('svg'));
            icon.setAttribute('slot', 'expand-collapse-glyph');
            icon.innerHTML = SVG_CHEVRON_RIGHT_OUTLINED;
            this.appendChild(icon);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], TreeItemComponent.prototype, "unread", void 0);
