import { __decorate } from "tslib";
import { attr, html, nullableNumberConverter, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { LinearProgressComponent } from '../LinearProgress';
import { TypographyComponent } from '../Typography';
export const progressIndicatorTemplate = (context, _definition) => {
    const typographyTag = context.tagFor(TypographyComponent);
    const linearProgressTag = context.tagFor(LinearProgressComponent);
    return html `<template>
    <div class="container">
      <${typographyTag}
        class="title-wrapper"
        variant="${(x) => (x.size === 'small' ? 'body-small' : 'body-large')}"
      >
        <div class="title">
          <slot name="title"></slot>
        </div>
        ${when((x) => x.value !== null, html `<div class="percentage">${(x) => x.value}%</div>`)}
      </${typographyTag}>
      <${linearProgressTag} size="${(x) => x.size}" value="${(x) => x.value}"></${linearProgressTag}>
    </div>
    <div class="actions">
      <slot name="action"></slot>
    </div>
  </template>`;
};
export class ProgressIndicatorComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'small';
        this.value = null;
    }
}
__decorate([
    attr
], ProgressIndicatorComponent.prototype, "size", void 0);
__decorate([
    attr({ converter: nullableNumberConverter })
], ProgressIndicatorComponent.prototype, "value", void 0);
