import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
export const Dialog = forwardRef((_a, ref) => {
    var { actions, open, title, children, closeButtonLabel, preventClose, onClosing, onClosed, onBeforeClose } = _a, props = __rest(_a, ["actions", "open", "title", "children", "closeButtonLabel", "preventClose", "onClosing", "onClosed", "onBeforeClose"]);
    const dialogRef = useRef();
    const handledRef = handleForwardRef(dialogRef, ref);
    const handleClosingListener = () => {
        onClosing === null || onClosing === void 0 ? void 0 : onClosing();
    };
    const handleClosedListener = () => {
        onClosed === null || onClosed === void 0 ? void 0 : onClosed();
    };
    useEffect(() => {
        var _a, _b, _c;
        onBeforeClose && ((_a = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('before-close', onBeforeClose));
        (_b = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('closing', handleClosingListener);
        (_c = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _c === void 0 ? void 0 : _c.addEventListener('closed', handleClosedListener);
        return () => {
            var _a, _b, _c;
            onBeforeClose && ((_a = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('before-close', onBeforeClose));
            (_b = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('closing', handleClosingListener);
            (_c = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('closed', handleClosedListener);
        };
    }, [ref, onClosing, onClosed]);
    return createReactWrapper({
        tagName: 'chameleon-dialog',
        props: Object.assign(Object.assign({}, props), { ref: handledRef, open: !!open, 'close-button-label': closeButtonLabel, 'prevent-close': preventClose }),
        children: (_jsxs(_Fragment, { children: [_jsx("span", { slot: "title", children: title }), children, _jsx("div", { slot: "actions", children: actions })] })),
    });
});
