import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { daoProcessQuotePost } from 'dao/ucs-dao';
import { Thunk } from 'types/root';
import { ProcessQuotePayload, UCSState } from 'types/ucs';

export const postProcessQuote = createAsyncThunk<boolean, ProcessQuotePayload, Thunk>(
  'quote/process',
  async ({ quoteCode, payload }, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await daoProcessQuotePost(state.globalWrapper.accountKey, quoteCode, payload);
    return !!response;
  },
);

const initialState: UCSState = {
  isProcessQuoteLoading: false,
  isProcessQuoteSuccessful: false,
  isProcessQuoteFailed: false,
};

const ucsSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    resetProcessQuoteLoaders(state) {
      state.isProcessQuoteFailed = false;
      state.isProcessQuoteLoading = false;
      state.isProcessQuoteSuccessful = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postProcessQuote.pending, (state) => {
        state.isProcessQuoteLoading = true;
        state.isProcessQuoteFailed = false;
        state.isProcessQuoteSuccessful = false;
      })
      .addCase(postProcessQuote.fulfilled, (state) => {
        state.isProcessQuoteLoading = false;
        state.isProcessQuoteFailed = false;
        state.isProcessQuoteSuccessful = true;
      })
      .addCase(postProcessQuote.rejected, (state) => {
        state.isProcessQuoteFailed = true;
        state.isProcessQuoteLoading = false;
        state.isProcessQuoteSuccessful = false;
      });
  },
});

export const { resetProcessQuoteLoaders } = ucsSlice.actions;

export default ucsSlice.reducer;
