import { provideChameleonDesignSystem } from '../provideChameleonDesignSystem';
import { chameleonDataTableActionsV2 } from './DataTableActionsV2/definition';
import { chameleonDataTableBodyV2 } from './DataTableBodyV2/DataTableBodyV2.definition';
import { chameleonDataTableCellV2 } from './DataTableCellV2/DataTableCellV2.definition';
import { chameleonDataTableFilterBarV2 } from './DataTableFilterBarV2/definition';
import { chameleonDataTableGroupRowV2 } from './DataTableGroupRowV2/definition';
import { chameleonDataTableHeaderCellV2 } from './DataTableHeaderCellV2/DataTableHeaderCellV2.definition';
import { chameleonDataTableHeaderV2 } from './DataTableHeaderV2/DataTableHeaderV2.definition';
import { chameleonDataTablePaginationV2 } from './DataTablePaginationV2/definition';
import { chameleonDataTableRowV2 } from './DataTableRowV2/definition';
import { chameleonDataTableV2 } from './DataTableV2.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonDataTableV2({ prefix }), chameleonDataTableActionsV2({ prefix }), chameleonDataTableBodyV2({ prefix }), chameleonDataTableCellV2({ prefix }), chameleonDataTableFilterBarV2({ prefix }), chameleonDataTableGroupRowV2({ prefix }), chameleonDataTableHeaderCellV2({ prefix }), chameleonDataTableHeaderV2({ prefix }), chameleonDataTablePaginationV2({ prefix }), chameleonDataTableRowV2({ prefix }));
}
