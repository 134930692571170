import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { getChameleonElements, getElementsWithProperty } from '../../common/utils/DomUtil';
import { ChameleonPrefixedElementMixin } from '../../common/mixin';
import { DEV } from 'esm-env';
export const dataTableActionsV2Template = html `
  <div class="left">
    <slot name="search"></slot>
    <slot name="manage-selection"></slot>
    <slot name="selected-button"></slot>
  </div>
  <div class="right">
    <slot name="select"></slot>
    <div class="divider"></div>
    <slot name="actions"></slot>
  </div>
`;
export class DataTableActionsV2Component extends ChameleonPrefixedElementMixin(FoundationElement, 'data-table-actions-v2') {
    constructor() {
        super(...arguments);
        this.condensed = false;
        this.isValidSizeAttribute = (size) => size === null || size === 'small' || size === 'medium';
    }
    get buttonSize() {
        return this.elementSize;
    }
    get elementSize() {
        return this.condensed ? 'small' : 'medium';
    }
    connectedCallback() {
        super.connectedCallback();
        const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'size');
        for (const el of chameleonElements) {
            const attributeName = el.tagName.toLowerCase().includes('search') ? 'fieldsize' : 'size';
            el.setAttribute(attributeName, this.elementSize);
        }
    }
    condensedChanged(_, next) {
        const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'size');
        for (const el of chameleonElements) {
            if (DEV && this.isValidSizeAttribute(el.getAttribute('size'))) {
                console.warn(`${el.tagName.toLowerCase()}: Only 'small' or 'medium' values are allowed for the size attribute in the DataTableActions Component`);
            }
            el.setAttribute('size', next ? 'small' : 'medium');
            const attributeName = el.tagName.toLowerCase().includes('search') ? 'fieldsize' : 'size';
            if (DEV && this.isValidSizeAttribute(el.getAttribute(attributeName))) {
                console.warn(`${el.tagName.toLowerCase()}: Only 'small' or 'medium' values are allowed for the ${attributeName} attribute in the DataTableActions Component`);
            }
            el.setAttribute(attributeName, this.elementSize);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], DataTableActionsV2Component.prototype, "condensed", void 0);
