import { RadioGroup, radioGroupTemplate as template, } from '@microsoft/fast-foundation';
import { RadioGroupComponent } from './RadioGroup';
import styles from './RadioGroup.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const radioGroupDefinition = {
    baseName: 'radio-group',
    baseClass: RadioGroup,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonRadioGroup = RadioGroupComponent.compose(radioGroupDefinition);
