import { forwardKeyboardEvent } from './../helpers';
import { ListboxOption } from '@microsoft/fast-foundation';
import { ChameleonPrefixedElementMixin } from '../../common/mixin';
export class ListboxItemComponent extends ChameleonPrefixedElementMixin(ListboxOption, 'listbox-item') {
    constructor() {
        super(...arguments);
        this.interactiveItems = [];
        this.handleKeyDown = (event) => {
            const { key } = event;
            const selectedInteractiveItemIndex = this.interactiveItems.findIndex((subItem) => subItem?.getAttribute('aria-current'));
            const selectedInteractiveItem = this.interactiveItems[selectedInteractiveItemIndex];
            if (!selectedInteractiveItem && (key === 'Enter' || key === ' ')) {
                this.click();
            }
            if (!(this.interactiveItems.length > 0)) {
                return;
            }
            if (key === 'ArrowRight') {
                event.preventDefault();
                this.classList.remove('selected');
                if (selectedInteractiveItem) {
                    const nextInteractiveSubItem = this.interactiveItems[selectedInteractiveItemIndex + 1];
                    if (nextInteractiveSubItem) {
                        this.updateSelectedInteractiveItem(nextInteractiveSubItem, selectedInteractiveItem);
                    }
                    else {
                        this.removeSelectedInteractiveItem(selectedInteractiveItem);
                        this.classList.add('selected');
                    }
                }
                else {
                    const firstInteractiveSubItem = this.interactiveItems[0];
                    this.updateSelectedInteractiveItem(firstInteractiveSubItem);
                }
            }
            if (key === 'ArrowLeft') {
                event.preventDefault();
                this.classList.remove('selected');
                if (selectedInteractiveItem) {
                    const previousInteractiveSubItem = this.interactiveItems[selectedInteractiveItemIndex - 1];
                    if (previousInteractiveSubItem) {
                        this.updateSelectedInteractiveItem(previousInteractiveSubItem, selectedInteractiveItem);
                    }
                    else {
                        this.removeSelectedInteractiveItem(selectedInteractiveItem);
                        this.classList.add('selected');
                    }
                }
                else {
                    const lastInteractiveSubItem = this.interactiveItems[this.interactiveItems.length - 1];
                    this.updateSelectedInteractiveItem(lastInteractiveSubItem);
                }
            }
            if (selectedInteractiveItem && (key === 'ArrowUp' || key === 'ArrowDown')) {
                this.removeSelectedInteractiveItem(selectedInteractiveItem);
            }
            if (selectedInteractiveItem && (key === 'Enter' || key === ' ')) {
                forwardKeyboardEvent(event, selectedInteractiveItem);
            }
        };
    }
    updateSelectedInteractiveItem(newInteractiveItem, previousInteractiveItem) {
        this.addSelectedInteractiveItem(newInteractiveItem);
        if (previousInteractiveItem) {
            this.removeSelectedInteractiveItem(previousInteractiveItem);
        }
    }
    addSelectedInteractiveItem(interactiveItem) {
        // Interrogation : This assumes there is only a chameleon-listbox inside the DOM?
        // This might not scale well
        const listboxTag = this.tagFor('listbox');
        const tooltipV2Tag = this.tagFor('tooltip-v2');
        const listbox = document.querySelector(listboxTag);
        interactiveItem.setAttribute('aria-current', 'true');
        listbox?.setAttribute('aria-activedescendant', interactiveItem.id);
        interactiveItem.firstElementChild?.classList.add('selected-interactive-item');
        const newTooltip = interactiveItem.shadowRoot?.querySelector(tooltipV2Tag);
        newTooltip?.setAttribute('is-open', 'true');
    }
    removeSelectedInteractiveItem(interactiveItem) {
        const tooltipV2Tag = this.tagFor('tooltip-v2');
        const prevTooltip = interactiveItem.shadowRoot?.querySelector(tooltipV2Tag);
        prevTooltip?.removeAttribute('is-open');
        interactiveItem.removeAttribute('aria-current');
        interactiveItem.firstElementChild?.classList.remove('selected-interactive-item');
    }
    getAllInteractiveItems() {
        const interactiveItems = new Set();
        let currentDepth = 1;
        const addInteractiveItem = (itemsArray) => {
            for (const item of itemsArray) {
                interactiveItems.add(item);
            }
        };
        const recursiveSearch = (parent) => {
            const interactiveSubItemTag = this.tagFor('interactive-sub-item');
            addInteractiveItem(Array.from(parent.querySelectorAll(interactiveSubItemTag)));
            if (parent.shadowRoot) {
                currentDepth++;
                addInteractiveItem(Array.from(parent.shadowRoot.querySelectorAll(interactiveSubItemTag)));
                for (const child of [...parent.shadowRoot.children]) {
                    if (currentDepth < 20) {
                        recursiveSearch(child);
                    }
                }
            }
            for (const child of [...parent.children]) {
                if (currentDepth < 20) {
                    recursiveSearch(child);
                }
            }
        };
        recursiveSearch(this);
        return Array.from(interactiveItems);
    }
    setInteractiveItems() {
        this.interactiveItems = this.getAllInteractiveItems();
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
        this.addEventListener('interactive-item-added', this.setInteractiveItems);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
        this.removeEventListener('interactive-item-added', this.setInteractiveItems);
    }
}
