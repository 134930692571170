import { PopoverTriggerStrategy } from './PopoverTriggerStrategy';
import { inflateRectInDirection, isPointOverRect } from '../../common/utils/DomUtil';
const TRIGGER_MOUSE_MOVE_OFFSET = 20;
export class PopoverTriggerHoverStrategy extends PopoverTriggerStrategy {
    constructor() {
        super(...arguments);
        this.handleTriggerMouseMove = () => {
            if (this.popover.shouldOpen) {
                this.trigger.removeEventListener('mousemove', this.handleTriggerMouseMove);
                this.popover.open();
            }
        };
        this.handleTriggerFocus = () => {
            if (this.popover.shouldOpen) {
                this.trigger.removeEventListener('focus', this.handleTriggerFocus);
                if (!this.popover.isOpen) {
                    this.popover.open();
                    document.removeEventListener('mousemove', this.handleGlobalMouseMove);
                }
            }
        };
        this.handleTriggerBlur = () => {
            this.trigger.removeEventListener('blur', this.handleTriggerBlur);
            if (this.popover.isOpen) {
                this.popover.close();
            }
        };
        this.handleGlobalMouseMove = (e) => {
            this.isCursorHoverPopover(e);
        };
        this.isCursorHoverPopover = (e) => {
            const x = e.clientX;
            const y = e.clientY;
            const contentPosition = this.popoverContent.position;
            const position = contentPosition?.split('-')[0];
            if (this.popoverContent) {
                const triggerBounds = inflateRectInDirection(this.trigger.getBoundingClientRect(), TRIGGER_MOUSE_MOVE_OFFSET, position);
                if (!(isPointOverRect(triggerBounds, { x, y }) ||
                    isPointOverRect(this.popoverContent.getBoundingClientRect(), { x, y }))) {
                    this.popover.close();
                }
            }
        };
    }
    init() {
        super.init();
        this.trigger.addEventListener('mousemove', this.handleTriggerMouseMove);
        this.trigger.addEventListener('focus', this.handleTriggerFocus);
    }
    dispose() {
        this.unbindGlobalListener();
    }
    internalOnOpen() {
        document.addEventListener('mousemove', this.handleGlobalMouseMove);
        this.trigger.addEventListener('blur', this.handleTriggerBlur);
    }
    internalOnClose() {
        this.unbindGlobalListener();
        this.trigger.addEventListener('mousemove', this.handleTriggerMouseMove);
        this.trigger.addEventListener('focus', this.handleTriggerFocus);
    }
    unbindGlobalListener() {
        document.removeEventListener('mousemove', this.handleGlobalMouseMove);
    }
}
