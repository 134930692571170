import { SVG_CHECKMARK_OUTLINED } from '@getgo/chameleon-icons';
import { html } from '@microsoft/fast-element';
import { MenuItem, menuItemTemplate as template } from '@microsoft/fast-foundation';
import { MenuItemComponent } from './MenuItem';
import styles from './MenuItem.styles.scss';
import { withStylePrefix } from '../../common/withStylePrefix';
export const menuItemDefinition = {
    baseName: 'menu-item',
    baseClass: MenuItem,
    template: template,
    styles: withStylePrefix(styles),
    attributes: ['disabled', 'size'],
    radioIndicator: html `<chameleon-svg>${SVG_CHECKMARK_OUTLINED}</chameleon-svg>`,
};
export const chameleonMenuItem = MenuItemComponent.compose(menuItemDefinition);
