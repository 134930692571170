import { Divider, dividerTemplate as template } from '@microsoft/fast-foundation';
import { MenuSeparatorComponent } from './MenuSeparator';
import styles from './MenuSeparator.styles.scss';
import { withStylePrefix } from '../../common/withStylePrefix';
export const menuSeparatorDefinition = {
    baseName: 'menu-separator',
    baseClass: Divider,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonMenuSeparator = MenuSeparatorComponent.compose(menuSeparatorDefinition);
