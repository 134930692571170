import { __rest } from "tslib";
import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useRef } from 'react';
import { createReactWrapper } from '../../common/PrefixUtils';
import { handleForwardRef } from '../../common/RefUtils';
import { addExtraProps } from '../../common/utils/AddExtraProps';
import { useEventListener } from '../../common/useEventListener';
export const MenuItem = forwardRef((_a, ref) => {
    var { children, leadingIcon, trailingIcon, onClick, subMenu } = _a, props = __rest(_a, ["children", "leadingIcon", "trailingIcon", "onClick", "subMenu"]);
    const menuItemRef = useRef();
    const handledRef = handleForwardRef(menuItemRef, ref);
    useEventListener('menuitemclick', onClick, menuItemRef);
    return createReactWrapper({
        tagName: 'chameleon-menu-item',
        props: Object.assign(Object.assign({}, props), { ref: handledRef }),
        children: (_jsxs(_Fragment, { children: [leadingIcon && addExtraProps(leadingIcon, { slot: 'start' }), children, trailingIcon && addExtraProps(trailingIcon, { slot: 'end' }), subMenu && addExtraProps(subMenu, { slot: 'submenu' })] })),
    });
});
