import { Button, buttonTemplate as template } from '@microsoft/fast-foundation';
import { IconButtonComponent } from './IconButton';
import styles from './IconButton.styles.scss';
import { withStylePrefix } from '../common/withStylePrefix';
export const iconButtonDefinition = {
    baseName: 'icon-button',
    baseClass: Button,
    template: template,
    styles: withStylePrefix(styles),
};
export const chameleonIconButton = IconButtonComponent.compose(iconButtonDefinition);
