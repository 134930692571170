import { FC, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProtectedFilledIcon } from '@getgo/chameleon-icons/react';
import { Alert } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector, useInterval } from 'hooks';
import useViewPortSize from 'hooks/useViewport';
import { sessionValidUntil, setSessionExpired } from 'modules/session-details';
import st from 'utils/shared-translations';

import './session-timer.css';

const SessionTimer: FC = () => {
  const dispatch = useAppDispatch();

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const viewport = useViewPortSize();

  const validUntil = useAppSelector(sessionValidUntil);

  useInterval(() => {
    const validUntilDate = new Date(validUntil);
    const currentTime = new Date();
    const timeLeft = validUntilDate.getTime() - currentTime.getTime();
    if (timeLeft <= 1000) {
      dispatch(setSessionExpired(true));
      setMinutes(0);
      setSeconds(0);
      return;
    }
    const minutes = Math.floor(timeLeft / 60000);
    setMinutes(Number(minutes));
    const seconds = ((timeLeft % 60000) / 1000).toFixed(0);
    setSeconds(Number(seconds));
  }, 1000);

  const isMobile = viewport.width < 650;

  return (
    <Alert className="session-timer" variant="success" icon={<ProtectedFilledIcon />}>
      <FormattedMessage
        {...st['session.timer.info']}
        values={{
          br: isMobile ? <br /> : '',
          bold: (msg: ReactNode) => <strong>{msg}</strong>,
          minute: minutes,
          second: seconds,
        }}
      />
    </Alert>
  );
};

export default SessionTimer;
