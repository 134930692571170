import { FC, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChevronLeftOutlinedIcon, GotoAdminPartnerFaviconIcon } from '@getgo/chameleon-icons/react';
import { Button, GotoLogo, Skeleton, Typography } from '@getgo/chameleon-web-react-wrapper';

import PriceInfo from 'components/price-info';
import SessionExpiredModal from 'components/session-expired-modal';
import SessionTimer from 'components/session-timer';
import { useAppDispatch, useAppSelector } from 'hooks';
import loadFingerprint from 'lib/cybersource-fingerprint';
import { showErrorSnack } from 'modules/error';
import { accountKey } from 'modules/global-wrapper';
import {
  paymentTypesIsLoading,
  payNowIntent,
  postPaymentMethodTypes,
  setPayNowIntent,
  sortedPaymentMethodList,
} from 'modules/payment-methods';
import { isSessionExpired, sesssionCancelRedirectUrl } from 'modules/session-details';
import Track, { PaynowBackToCheckout, PaynowLanding } from 'modules/tracking';
import { postTransactionPrepare } from 'modules/transactions-cc';
import { PrepareResp } from 'types/transactions-cc';
import { ONE_PAY_INTENT, PAYMENT_METHODS_TYPES, SEARCH_PARAMS } from 'utils/constants';
import st from 'utils/shared-translations';
import ACHPay from './ach-pay';
import CreditCardPay from './credit-card-pay';
import DirectDebitPay from './direct-debit-pay';
import PaymentMethodsAvailable from './payment-method-available';
import PaymentMethodOptions from './payment-method-options';
import PaypalPay from './paypal-pay';

import './pay-now.css';

const loadExternalScripts = async (prepareResp: PrepareResp) => {
  const { fingerprintingJavascriptSource } = prepareResp;

  loadFingerprint(fingerprintingJavascriptSource);
};

const PayNow: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);

  const searchParams = new URLSearchParams(window.location.search);

  const selectedAccountKey = useAppSelector(accountKey);

  const selectedSessionIsExpired = useAppSelector(isSessionExpired);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  const selectedPaymentTypeIsLoading = useAppSelector(paymentTypesIsLoading);

  const selectedPayNowIntent = useAppSelector(payNowIntent);

  const selectedSortedPaymentMethodList = useAppSelector(sortedPaymentMethodList);

  const isPaypalRedirect = searchParams.get(SEARCH_PARAMS.redirect) === PAYMENT_METHODS_TYPES.paypal;

  useEffect(() => {
    if (selectedAccountKey) {
      Track(PaynowLanding, {});
      dispatch(postPaymentMethodTypes());
      setLoading(false);
    }
  }, [dispatch, selectedAccountKey]);

  // Call prepare transaction to add the finger print script
  useEffect(() => {
    if (selectedAccountKey) {
      dispatch(postTransactionPrepare({ paymentMethodKey: '' }))
        .unwrap()
        .then((resp: any) => {
          loadExternalScripts(resp);
        })
        .catch(() => {
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        });
    }
  }, [dispatch, selectedAccountKey]);

  useEffect(() => {
    /*
    1. First check if the user is redirected from PayPal, then set the intent to PayPal to call step2 API.
    2. Else if the user has not selected any payment method, then set the intent to add a payment method
    */
    if (isPaypalRedirect) {
      dispatch(setPayNowIntent(ONE_PAY_INTENT.paypal));
    } else if (!selectedSortedPaymentMethodList.length) {
      dispatch(setPayNowIntent(ONE_PAY_INTENT.addPayment));
    }
  }, [dispatch, isPaypalRedirect, selectedSortedPaymentMethodList.length]);

  const backToHome = () => {
    Track(PaynowBackToCheckout, {});
    localStorage.clear();
    window.location.assign(selectedCancelRedirectUrl);
  };

  /**
   * Back button functionality:
   * i) If Payment type is selected then get the user back to:
   *      a. Available Payments page - If paymment methods are already present
   *      b. Payment Method Options page - If payment methods are not present
   * ii) If the user is already on Available Payments page then get the user back to Checkout page.
   */
  const redirectToCheckout = () => {
    if (selectedPayNowIntent) {
      // If current page is PaymentMethodOptions, then check for the available payment methods. If not available then redirect to home, else show the available payments.
      if (selectedPayNowIntent === ONE_PAY_INTENT.addPayment) {
        if (selectedSortedPaymentMethodList.length) dispatch(setPayNowIntent(''));
        else backToHome();
      } else {
        // For any other current pages, redirect to PaymentMethodOptions page if no payment methods are available, else redirect to Available Payments page.
        dispatch(setPayNowIntent(selectedSortedPaymentMethodList.length ? '' : ONE_PAY_INTENT.addPayment));
      }
    } else {
      backToHome();
    }
  };

  return (
    <div className="pay-now" data-testid="pay-now">
      {selectedSessionIsExpired && <SessionExpiredModal redirectType="Checkout" />}
      <nav className="pay-now__nav" data-testid="pay-now__nav">
        <Button
          size="medium"
          variant="neutral"
          leadingIcon={<ChevronLeftOutlinedIcon />}
          onClick={redirectToCheckout}
          data-testid="pay-now__back"
        >
          {intl.formatMessage(st['back.to.previous.page'])}
        </Button>
      </nav>
      <header className="pay-now__header" data-testid="pay-now__header">
        <SessionTimer />
      </header>
      <GotoLogo className="pay-now__logo" data-testid="pay-now__goto-logo">
        <GotoAdminPartnerFaviconIcon />
      </GotoLogo>
      <aside className="pay-now__aside" data-testid="pay-now__aside">
        <PriceInfo />
      </aside>
      <main className="pay-now__main" data-testid="pay-now__main">
        {selectedPaymentTypeIsLoading || isLoading ? (
          <Skeleton size="xxxlarge" className="pay-now__skeleton" data-testid="pay-now__skeleton" />
        ) : (
          {
            '': <PaymentMethodsAvailable />,
            [ONE_PAY_INTENT.addPayment]: <PaymentMethodOptions />,
            [ONE_PAY_INTENT.cc]: <CreditCardPay />,
            [ONE_PAY_INTENT.ach]: <ACHPay />,
            [ONE_PAY_INTENT.dd]: <DirectDebitPay />,
            [ONE_PAY_INTENT.paypal]: <PaypalPay />,
          }[selectedPayNowIntent]
        )}
      </main>
      <footer className="pay-now__footer" data-testid="pay-now__footer">
        <Typography variant="caption-medium" color="type-color-secondary">
          <FormattedMessage
            {...st['payment.tnc']}
            values={{
              'tos-link': (msg: ReactNode) => (
                <a
                  href="https://www.goto.com/company/legal/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              'privacy-policy': (msg: ReactNode) => (
                <a href="https://www.goto.com/company/legal/privacy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              'anti-spam': (msg: ReactNode) => (
                <a href="https://www.goto.com/company/legal/anti-spam-policy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
            }}
          />
        </Typography>
      </footer>
    </div>
  );
};

export default PayNow;
