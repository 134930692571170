import { withStylePrefix } from '../common/withStylePrefix';
import { AvatarComponent, avatarTemplate } from './AvatarV1';
import avatarV1Styles from './AvatarV1.styles.scss';
import { AvatarV2Component, avatarV2Template } from './AvatarV2';
import avatarV2Styles from './AvatarV2.styles.scss';
export const avatarDefinition = {
    template: avatarTemplate,
    styles: withStylePrefix(avatarV1Styles),
};
export const chameleonAvatar = AvatarComponent.compose({
    ...avatarDefinition,
    baseName: 'avatar',
});
export const avatarV2Definition = {
    template: avatarV2Template,
    styles: withStylePrefix(avatarV2Styles),
};
export const chameleonAvatarV2 = AvatarV2Component.compose({
    ...avatarV2Definition,
    baseName: 'avatar-v2',
});
