import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { MenuItem } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { DEV } from 'esm-env';
export class NavigationDrawerItemComponent extends ChameleonElementMixin(MenuItem) {
    constructor() {
        super(...arguments);
        this.selected = false;
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        this.addEventListener('keydown', this.handleKeyDown);
        this.addEventListener('click', this.handleClick);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
        this.removeEventListener('click', this.handleClick);
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    handleKeyDown(event) {
        const { key } = event;
        if (key === ' ' || key === 'Enter') {
            this.action(event);
            event.preventDefault();
        }
    }
    handleClick(event) {
        this.action(event);
        event.preventDefault();
    }
    action(event) {
        if (this.disabled) {
            event.stopImmediatePropagation();
            return;
        }
        this.$emit('nav-drawer-action', null, { bubbles: true });
    }
    setAccessibleLabel() {
        if (this.textContent) {
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getAttribute('aria-label')?.length) {
            console.error(`CHAMELEON-A11Y: Navigation Drawer Item component must have an accessible label provided by either 
  1) text content 
  2) a non-empty label attribute to be used as aria-label.
`);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], NavigationDrawerItemComponent.prototype, "selected", void 0);
__decorate([
    attr
], NavigationDrawerItemComponent.prototype, "label", void 0);
