import { __decorate } from "tslib";
import { SVG_ALERT_OUTLINED, SVG_CHECKBOX_CHECK } from '@getgo/chameleon-icons';
import { attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { TooltipV2Component } from '../../TooltipV2';
import { LinkComponent } from '../../Link';
import { SvgComponent } from '../../Svg';
import { TypographyComponent } from '../../Typography';
export const progressStepTemplate = (context, _definition) => {
    const tooltipV2Tag = context.tagFor(TooltipV2Component);
    const typographyTag = context.tagFor(TypographyComponent);
    const linkTag = context.tagFor(LinkComponent);
    const svgTag = context.tagFor(SvgComponent);
    return html `<template slot="step">
    <span class="badge">
      ${when((x) => x.shouldShowDangerState(), html `<${svgTag}>${SVG_ALERT_OUTLINED}</${svgTag}>`)}
      ${when((x) => x.shouldShowSuccessState(), html `<${svgTag}>${SVG_CHECKBOX_CHECK}</${svgTag}>`)}
      ${when((x) => x.shouldShowBadgeValue(), html `<span>${(x) => x.badgeValue}</span>`)}
    </span>
    <${tooltipV2Tag} position="bottom-center" hidden="${(x) => !x.showTooltip}">
      <div class="step-text" slot="trigger">
        <${typographyTag} class="step-title" part="title" nowrap color="inherit">
          ${when((x) => !x.editable, html `<slot ${slotted('defaultSlot')}></slot>`)}
          ${when((x) => x.editable && !x.disabled, html `
              <${linkTag} @click="${(x) => x.onStepClick()}">
                <slot ${slotted('defaultSlot')}></slot>
              </${linkTag}>
            `)}
        </${typographyTag}>
        <div class="step-description">
          <slot name="description" ${slotted('descriptionSlot')}></slot>
        </div>
      </div>
      <span slot="content">
        <${typographyTag} tag="b" nowrap color="inherit">${(x) => x.titlePopoverContent}</${typographyTag}>
        ${(x) => x.descriptionPopoverContent}
      </span>
    </${tooltipV2Tag}>
  </template>`;
};
export class ProgressStepComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.disabled = false;
        this.badgeValue = '';
        this.editable = false;
        this.optional = false;
        this.visited = false;
        this.showTooltip = true;
        this.titlePopoverContent = '';
        this.descriptionPopoverContent = '';
        this.selected = false;
        this.defaultSlot = [];
        this.descriptionSlot = [];
    }
    selectedChanged(_oldValue, newValue) {
        if (newValue) {
            this.$emit('change', null, { bubbles: false });
        }
    }
    defaultSlotChanged(_, changed) {
        this.titlePopoverContent = changed[0]?.textContent ?? '';
    }
    descriptionSlotChanged(_, changed) {
        this.descriptionPopoverContent = changed[0]?.textContent ?? '';
    }
    shouldShowDangerState() {
        return this.variant === 'danger';
    }
    shouldShowSuccessState() {
        return !this.selected && this.variant === 'success';
    }
    shouldShowBadgeValue() {
        return (this.selected || this.variant !== 'success') && !this.shouldShowDangerState();
    }
    onStepClick() {
        this.$emit('step-clicked', null, { bubbles: false });
    }
}
__decorate([
    attr
], ProgressStepComponent.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'badgevalue' })
], ProgressStepComponent.prototype, "badgeValue", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "editable", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "optional", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "visited", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'show-tooltip' })
], ProgressStepComponent.prototype, "showTooltip", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "titlePopoverContent", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "descriptionPopoverContent", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "selected", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "defaultSlot", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "descriptionSlot", void 0);
