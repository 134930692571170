import { __decorate } from "tslib";
import { attr, DOM, html, nullableNumberConverter, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { assertSlotNonNull } from '../common/asserts';
import { ChameleonElementMixin } from '../common/mixin';
import { getTabbableChildren } from '../common/utils/DomUtil';
import { PopoverTriggerOn } from './model';
import { calculatePopoverPosition, updatePopoverContentStyles } from './PopoverContent/PopoverContentUtil';
import { createPopoverTriggerStrategy } from './strategies/PopoverTriggerStrategyFactory';
const POPOVER_TRIGGER_ID_PREFIX = 'cham-popover-trigger-';
const DEFAULT_POSITION = `bottom-center`;
const DEFAULT_Z_INDEX = 1;
export const popoverTemplate = html `
  <slot name="trigger"></slot>
  <span aria-hidden="true" style="position: absolute; opacity: 0; display: none; visibility: hidden;">
    <slot ${slotted('slottedContentElements')} name="content"></slot>
  </span>
  <chameleon-popover-content
    inactive
    data-test="${(x) => x.dataTest}"
    :position="${(x) => x.position}"
    :trigger="${(x) => x.triggerOn}"
  ></chameleon-popover-content>
`;
export class PopoverComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.compact = false;
        this.inverted = false;
        this.hidden = false;
        this.arrow = false;
        this.menu = false;
        this.position = DEFAULT_POSITION;
        this.triggerOn = PopoverTriggerOn.click;
        this.isOpen = false;
        this.popoverContentActive = false;
        this.triggerId = uniqueId(POPOVER_TRIGGER_ID_PREFIX);
        this.handleMenuItemClick = (e) => {
            if (!e.defaultPrevented) {
                this.close();
            }
        };
    }
    isOpenChanged(oldValue, newValue) {
        if (this.$fastController.isConnected) {
            if (newValue !== oldValue) {
                // is opened
                if (newValue) {
                    this.open();
                }
                else {
                    this.close();
                }
            }
        }
    }
    slottedContentElementsChanged(_prev, next) {
        if (next.length > 0) {
            this.cloneContent();
        }
    }
    get shouldOpen() {
        return !this.isOpen && !this.popoverContentActive && !this.hidden;
    }
    connectedCallback() {
        super.connectedCallback();
        this.assignContentElement();
        this.assignPopoverElement();
        this.assignTriggerId();
        this.strategy = createPopoverTriggerStrategy(this, this.popoverContentElement);
        this.strategy.init();
        this.bindEvents();
        if (this.isOpen) {
            DOM.queueUpdate(() => {
                this.open();
            });
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.close();
        this.strategy.dispose();
        this.popoverContentElement.removeEventListener('menuitemclick', this.handleMenuItemClick);
        this.removeEventListener('keydown', this.handleKeydownEvent);
    }
    cloneContent() {
        const content = this.popoverContentElement;
        if (!content.querySelector('[slot="content"]')) {
            const clone = this.contentElement.cloneNode(true);
            // We append the original slotted content to the chameleon-popover-content element to
            // so that event listeners attached to them (like a click on a menu-item) still work as expected
            // and append the clone to the popover element itself so that next time the open() function runs
            // it still finds the popover element in a sctructurally correct state (ie: it has a `content` slot).
            content.appendChild(this.contentElement);
            this.appendChild(clone);
        }
    }
    attachPopoverContentToThemeProvider() {
        this.cloneContent();
        const themeWrapper = document.querySelector('chameleon-theme-provider');
        if (!themeWrapper) {
            return;
        }
        const component = this.popoverContentElement;
        updatePopoverContentStyles(this, component);
        component.inactive = false;
        themeWrapper.appendChild(this.popoverContentElement);
        this.popoverContentActive = true;
        this.calculatePosition();
        this.$emit('popovercontentattached', { isAttachedAndOpen: true }, { bubbles: false });
    }
    open() {
        this.isOpen = true;
        const tabbableChildren = getTabbableChildren(this.popoverContentElement);
        if (tabbableChildren.length) {
            tabbableChildren[0].focus();
        }
        this.strategy.onOpen();
        this.addEventListener('keydown', this.handleKeydownEvent);
        if (!this.contentElement) {
            return;
        }
        this.updateContentZIndex();
        this.attachPopoverContentToThemeProvider();
        this.$emit('popoverchange', { isOpen: true }, { bubbles: false });
    }
    close() {
        if (this.popoverContentActive) {
            this.isOpen = false;
            this.strategy.onClose();
            this.removeEventListener('keydown', this.handleKeydownEvent);
            this.cleanupFn?.();
            // Only forward back focus to trigger if popover has been opened by
            // keyboard navigation
            if (this.strategy.hasBeenTriggeredBy === 'keyboard') {
                this.strategy.trigger.focus();
            }
            this.strategy.hasBeenTriggeredBy = null;
            this.disconnectPopoverContentFromThemeProvider();
            this.$emit('popoverchange', { isOpen: false }, { bubbles: false });
        }
    }
    disconnectPopoverContentFromThemeProvider() {
        const themeWrapper = document.querySelector('chameleon-theme-provider');
        if (themeWrapper) {
            this.popoverContentElement.remove();
            this.shadowRoot?.appendChild(this.popoverContentElement);
            this.popoverContentElement.inactive = true;
            this.popoverContentActive = false;
        }
    }
    bindEvents() {
        this.popoverContentElement.addEventListener('menuitemclick', this.handleMenuItemClick);
    }
    getTriggerBounds() {
        return this.strategy.trigger.getBoundingClientRect();
    }
    calculatePosition() {
        calculatePopoverPosition(this, this.popoverContentElement, this.getTriggerBounds()).then((cleanup) => {
            this.cleanupFn = cleanup;
        });
    }
    handleKeydownEvent(e) {
        if (e.key === 'Escape') {
            this.close();
        }
    }
    assignPopoverElement() {
        this.popoverContentElement = this.shadowRoot?.querySelector('chameleon-popover-content');
    }
    updateContentZIndex() {
        const zIndexAssignedFromCss = +getComputedStyle(this).getPropertyValue('--z-index').trim();
        const zIndexToUse = !!zIndexAssignedFromCss ? zIndexAssignedFromCss : !!this.zIndex ? this.zIndex : DEFAULT_Z_INDEX;
        this.popoverContentElement.setAttribute('z-index', zIndexToUse.toString());
    }
    assignContentElement() {
        const contentElementList = this.querySelectorAll(':scope > [slot="content"]');
        if (contentElementList.length !== 1) {
            throw new Error('Chameleon error: PopoverComponent must have exactly one child element for slot "content" in order to work properly');
        }
        this.contentElement = contentElementList[0];
        assertSlotNonNull(this.contentElement, 'PopoverComponent', 'content');
        // This component does not support prefixing, so that check is okay
        if (this.contentElement.tagName.toLowerCase() === 'chameleon-menu') {
            throw new Error(`Chameleon error: chameleon-menu cannot be a direct child of PopoverComponent. Please wrap it with another tag, such as div`);
        }
    }
    assignTriggerId() {
        const trigger = this.querySelector("[slot='trigger']");
        if (!trigger) {
            return;
        }
        if (trigger.id) {
            this.triggerId = trigger.id;
            return;
        }
        trigger.id = this.triggerId;
    }
}
__decorate([
    attr
], PopoverComponent.prototype, "width", void 0);
__decorate([
    attr({ attribute: 'min-width' })
], PopoverComponent.prototype, "minWidth", void 0);
__decorate([
    attr({ attribute: 'max-width' })
], PopoverComponent.prototype, "maxWidth", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverComponent.prototype, "compact", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverComponent.prototype, "inverted", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverComponent.prototype, "hidden", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverComponent.prototype, "arrow", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverComponent.prototype, "menu", void 0);
__decorate([
    attr()
], PopoverComponent.prototype, "position", void 0);
__decorate([
    attr({ attribute: 'data-test' })
], PopoverComponent.prototype, "dataTest", void 0);
__decorate([
    attr({ attribute: 'trigger-on' })
], PopoverComponent.prototype, "triggerOn", void 0);
__decorate([
    attr({ attribute: 'z-index', converter: nullableNumberConverter })
], PopoverComponent.prototype, "zIndex", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'is-open' })
], PopoverComponent.prototype, "isOpen", void 0);
