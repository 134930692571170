import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { LoadingButton } from '../common/LoadingButton';
import { DEV } from 'esm-env';
export class RoundButtonComponent extends LoadingButton {
    constructor() {
        super(...arguments);
        this.baseName = 'round-button';
        this.variant = 'secondary';
        this.size = 'medium';
        this.inactive = false;
        this.displayLabel = false;
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        if (this.label)
            this.createLabelElement();
    }
    labelChanged() {
        this.setAccessibleLabel();
        if (this.isConnected && this.label) {
            this.createLabelElement();
        }
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    disabledChanged(oldValue, newValue) {
        super.disabledChanged(oldValue, newValue);
        requestAnimationFrame(() => {
            this.getInternalButtonElement()?.removeAttribute('disabled');
            this.getInternalButtonElement()?.setAttribute('aria-disabled', `${newValue}`);
        });
    }
    _keypressHandler(event) {
        if (this.getInternalButtonElement()?.getAttribute('aria-disabled') === 'true') {
            event.preventDefault();
        }
    }
    getInternalButtonElement() {
        return this.shadowRoot?.querySelector('button');
    }
    createLabelElement() {
        const typographyTag = this.tagFor('typography');
        const label = this.shadowRoot?.querySelector(typographyTag);
        if (!label) {
            const isCaptionMedium = this.size === 'large' || this.size === 'xlarge' || this.size === 'xxlarge';
            const labelElement = document.createElement(typographyTag);
            labelElement.classList.add('label');
            labelElement.setAttribute('variant', isCaptionMedium ? 'caption-medium' : 'caption-small');
            labelElement.setAttribute('color', 'type-color-secondary');
            if (this.label)
                labelElement.innerText = this.label;
            this.shadowRoot?.appendChild(labelElement);
        }
        else {
            if (this.label) {
                label.innerText = this.label;
            }
        }
    }
    setAccessibleLabel() {
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        const buttonElement = this.getInternalButtonElement();
        if (this.label) {
            buttonElement?.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        if (!this.getInternalButtonElement()?.getAttribute('aria-label')?.length &&
            !this.getAttribute('aria-labelledby')?.length) {
            console.error(`CHAMELEON-A11Y: Round Button component must have an accessible label provided by either 
  1) a visible label whose id is given as the attribute labelId 
  2) a non-empty label attribute to be used as aria-label.
`);
        }
    }
}
__decorate([
    attr
], RoundButtonComponent.prototype, "variant", void 0);
__decorate([
    attr
], RoundButtonComponent.prototype, "size", void 0);
__decorate([
    attr({ mode: 'boolean' })
], RoundButtonComponent.prototype, "inactive", void 0);
__decorate([
    attr
], RoundButtonComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'display-label' })
], RoundButtonComponent.prototype, "displayLabel", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], RoundButtonComponent.prototype, "labelId", void 0);
